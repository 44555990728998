import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Ranking', url: '/ranking/overview' },
    { label: 'New Ranking ', active: true }
]

const NewRanking = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        name: null,
        term: null,
        ranking: null
    }
    const [permissionList, setPermissionList] = useState(null);
    const [allPermission, setAllPermission] = useState(false);
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        term: '',
        ranking: null,
        permission: null
    })

    useEffect(() => {
        getPermission();
    }, [props.auth.auth.token])

    const getPermission = () => {
        fetch(api.get_permissions, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setPermissionList(responseJson);
                let newObj = {};
                responseJson.map((item) => {
                    item.permission.map((permission) => {
                        newObj[permission] = 0;
                    })
                })
                let newFormData = { ...formData };
                newFormData.permission = newObj;
                setFormData(newFormData);
            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        if (fieldName === 'ranking') {
            if (Number(fieldValue) > 0) {
                newFormData[fieldName] = Number(fieldValue);
            } else {
                newFormData[fieldName] = '';
            }
        } else {
            newFormData[fieldName] = fieldValue;
        }

        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.term) {
            errorMsg.term = "This field is required.";
            check = false;
        }
        if (!formData.ranking) {
            errorMsg.ranking = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.add_worker_role, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Ranking has been created successfully."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        resetForm();
                        navigate('/ranking/overview');
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setFormData({
            name: '',
            term: '',
            ranking: '',
            permission: permissionList
        });
        setAllPermission(false)
        setErrors(errorObj);
    }

    const togglePermission = (item) => {
        let newPermission = { ...formData };
        newPermission.permission[item] = newPermission.permission[item] ? 0 : 1;
        setFormData(newPermission);
    }

    const selectAllPermission = () => {
        if (formData.permission && Object.keys(formData.permission).length > 0) {
            let newFormData = { ...formData };
            Object.keys(newFormData.permission).map((item) => {
                newFormData.permission[item] = !allPermission;
            })
            setFormData(newFormData)
        }
        setAllPermission(!allPermission);
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "New Ranking")}</h2>
            <Row className='align-items-center justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Rank Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter rank name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.term ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Rank Code")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="term"
                                placeholder={translate(props.lang, "Enter rank code")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.term}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.term)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.ranking ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Rank Level")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type='number'
                                name="ranking"
                                placeholder={translate(props.lang, "Enter rank level")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.ranking}
                                onWheel={event => event.currentTarget.blur()}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.ranking)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className="text-start">
                        <div className={`py-1`}>
                            <span className={`fw-semibold fs-9 text-grey-1 d-block mb-1`}>{translate(props.lang, "Permission")} <span className='text-danger'>*</span></span>

                            <table className="table table-bordered table-list">
                                <tbody>
                                    {formData.permission && Object.keys(formData.permission).length > 0 ? (
                                        <>
                                            <tr onClick={() => selectAllPermission()}>
                                                <td className="fs-9 lh-base px-2">Select All</td>
                                                <td>
                                                    {allPermission ? (<img src={require('../../assets/img/checkedBox.svg').default} alt="checked" />) : (<img src={require('../../assets/img/uncheckedBox.svg').default} alt="checked" />)}
                                                </td>
                                            </tr>
                                            {permissionList.map((item, i) => (
                                                <>
                                                    <tr key={i}>
                                                        <td colSpan={2} className="text-uppercase fs-9 fw-bold px-2">{item.name}</td>
                                                    </tr>
                                                    {item.permission.map((singleItem, x) => (
                                                        <tr onClick={() => togglePermission(singleItem)} key={x}>
                                                            <td className="fs-9 lh-base px-2">{singleItem}</td>
                                                            <td>{formData.permission[singleItem] ? (<img src={require('../../assets/img/checkedBox.svg').default} alt="checked" />) : (<img src={require('../../assets/img/uncheckedBox.svg').default} alt="checked" />)}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                        </>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/ranking/overview')}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewRanking);
