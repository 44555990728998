const url = "https://pms-api.ithouz.com"; //testnet
// const url = "https://"; //production
const attachmentUrl = "https://pms-api.ithouz.com/images/";
const origin = "https://pmstest.dothouz.com"
const version = "v1.0.0";
const api = {
    login: url + '/login/company',
    add_organization: url + '/company/add_organization',
    get_organizations: url + '/company/get_organizations',
    edit_organization: url + '/company/edit_organization',
    get_organization_details: url + '/company/get_organization',
    edit_organization_status: url + '/company/edit_organization_status',
    add_organization_address: url + '/company/add_organization_address',
    edit_organization_address: url + '/company/edit_organization_address',
    add_organization_bank: url + '/company/add_organization_bank',
    edit_organization_bank: url + '/company/edit_organization_bank',
    remove_organization_bank: url + '/company/remove_organization_bank',
    remove_organization_address: url + '/company/remove_organization_address',
    remove_organization_attachment: url + '/company/remove_organization_attachment',
    check_organization_code: url + '/company/check_organization_code',

    add_department: url + '/company/add_department',
    get_departments: url + '/company/get_departments',
    edit_department: url + '/company/edit_department',
    get_department_details: url + '/company/get_department',
    edit_department_status: url + '/company/edit_department_status',

    add_customer: url + '/company/add_customer',
    get_customers: url + '/company/get_customers',
    edit_customer: url + '/company/edit_customer',
    get_customer_details: url + '/company/get_customer',
    add_customer_address: url + '/company/add_customer_address',
    edit_customer_address: url + '/company/edit_customer_address',
    remove_customer_address: url + '/company/remove_customer_address',
    add_customer_bank_account: url + '/company/add_customer_bank_account',
    edit_customer_bank_account: url + '/company/edit_customer_bank_account',
    remove_customer_bank_account: url + '/company/remove_customer_bank_account',

    add_worker_position: url + '/company/add_worker_position',
    get_worker_positions: url + '/company/get_worker_positions',
    edit_worker_position: url + '/company/edit_worker_position',
    get_worker_position_details: url + '/company/get_worker_position',
    edit_worker_position_status: url + '/company/edit_worker_position_status',

    add_worker: url + '/company/add_worker',
    get_workers: url + '/company/get_workers',
    edit_worker: url + '/company/edit_worker',
    get_worker_details: url + '/company/get_worker',
    edit_worker_status: url + '/company/edit_worker_status',
    edit_worker_password: url + '/company/edit_worker_password',
    edit_worker_permission: url + '/company/edit_worker_permission',

    add_worker_role: url + '/company/add_worker_role',
    get_worker_roles: url + '/company/get_worker_roles',
    edit_worker_role: url + '/company/edit_worker_role',
    get_worker_role_details: url + '/company/get_worker_role',
    edit_worker_role_status: url + '/company/edit_worker_role_status',

    add_project_category: url + '/project/add_project_category',
    get_project_categories: url + '/project/get_project_categories',
    edit_project_category: url + '/project/edit_project_category',
    get_project_category_details: url + '/project/get_project_category',

    add_project: url + '/project/add_project',
    get_project: url + '/project/get_projects',
    edit_project: url + '/project/edit_project',
    get_project_details: url + '/project/get_project',
    // edit_project_progress_status: url + '/project/edit_project_progress_status',
    edit_project_table: url + '/project/edit_project_table',
    get_project_summary: url + '/project/get_project_summary',
    get_project_log: url + '/project/get_project_log',
    get_project_labels: url + '/project/get_project_labels',

    add_project_task: url + '/project/add_project_task',
    get_project_tasks: url + '/project/get_project_tasks',
    edit_project_task: url + '/project/edit_project_task',
    get_project_tasks_details: url + '/project/get_project_task',
    project_task_timer: url + '/project/project_task_timer',
    remove_project_task_attachment: url + '/project/remove_project_task_attachment',
    edit_project_task_table: url + '/project/edit_project_task_table',
    get_project_task_summary: url + '/project/get_project_task_summary',
    get_project_task_log: url + '/project/get_project_task_log',

    get_my_timesheet: url + '/project/get_my_timesheet',
    get_project_timesheet: url + '/project/get_project_timesheet',
    add_project_timesheet: url + '/project/add_project_timesheet',
    remove_project_timesheet: url + '/project/remove_project_timesheet',
    edit_project_timesheet: url + '/project/edit_project_timesheet',
    
    get_project_financials: url + '/project/get_project_financials',
    add_project_financial: url + '/project/add_project_financial',
    get_project_financial_details: url + '/project/get_project_financial',
    convert_quotation: url + '/project/convert_quotation',

    get_settings: url + '/company/get_settings',
    edit_settings: url + '/company/edit_settings',

    get_my_dashboard: url + '/company/get_my_dashboard',
    get_my_details: url + '/company/get_my_details',
    change_my_password: url + '/company/change_my_password',

    add_announcement_group: url + '/announcement/add_announcement_group',
    get_announcement_groups: url + '/announcement/get_announcement_groups',
    get_announcement_group_details: url + '/announcement/get_announcement_group',
    edit_announcement_group: url + '/announcement/edit_announcement_group',
    
    add_announcement: url + '/announcement/add_announcement',
    get_announcements: url + '/announcement/get_announcements',
    edit_announcement: url + '/announcement/edit_announcement',
    read_announcement: url + '/announcement/read_announcement',
    get_announcement_details: url + '/announcement/get_announcement',

    upload_image: url + '/images',
    report: url + '/report/log',

    get_permissions: url + '/company/get_permissions',
    get_my_permission: url + '/company/get_my_permission',

    get_forums: url + '/forum/get_forums',
    add_forum: url + '/forum/add_forum',
    get_forum_details: url + '/forum/get_forum',
    edit_forum: url + '/forum/edit_forum',
    remove_forum: url + '/forum/remove_forum',
    react_forum: url + '/forum/react_forum',
    
    get_claims: url + '/claim/get_claims',
    get_claim_requests: url + '/claim/get_claim_requests',
    add_claim_setting: url + '/claim/add_claim_setting',
    add_claim: url + '/claim/add_claim',
    get_claim_settings: url + '/claim/get_claim_settings',
    get_claim_setting_details: url + '/claim/get_claim_setting',
    upload_claim_image: url + '/claim',


    get_settings: url + '/company/get_settings',
    edit_settings: url + '/company/edit_settings',
    
}

export { url, api, attachmentUrl, origin, version };