import { faChess, faDove, faDungeon, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Timeline from 'components/base/Timeline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { api } from 'utils/api';

const data = [
    {
        id: 1,
        oppositeContent: {
            date: '01 DEC, 2023',
            time: '10:30 AM'
        },
        content: {
            title: 'Phoenix Template: Unleashing Creative Possibilities',
            user: 'Shantinon Mekalan',
            details:
                'Discover limitless creativity with the Phoenix template! Our latest update offers an array of innovative features and design options.'
        },
        separator: {
            icon: faChess
        }
    },
    {
        id: 2,
        oppositeContent: {
            date: '05 DEC, 2023',
            time: '12:30 AM'
        },
        content: {
            title: 'Empower Your Digital Presence: The Phoenix Template Unveiled',
            user: 'Bookworm22',
            details:
                'Unveiling the Phoenix template, a game-changer for your digital presence. With its powerful features and sleek design,'
        },
        separator: {
            icon: faDove
        }
    },
    {
        id: 3,
        oppositeContent: {
            date: '15 DEC, 2023',
            time: '2:30 PM'
        },
        content: {
            title: 'Phoenix Template: Simplified Design, Maximum Impact',
            user: 'Sharuka Nijibum',
            details:
                'Introducing the Phoenix template, where simplified design meets maximum impact. Elevate your digital presence with its sleek and intuitive features.'
        },
        separator: {
            icon: faDungeon
        }
    }
]

const ActivityTimeline = (props) => {
    const navigate = useNavigate();
    const [projectLog, setProjectLog] = useState([])

    useEffect(() => {
        if (props.projectInfo && props.projectInfo.project_id) {
            fetch(api.get_project_log + '/' + props.projectInfo.project_id, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        setProjectLog(responseJson.data.log)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }

                }).catch(error => {
                    console.error("error", error);
                });
        }
    }, [props.projectInfo])
    return (
        <Timeline variant="vertical">
            {
                projectLog.map((item, index) => (
                    <Timeline.Item className="position-relative" key={item.id}>
                        <Row className="g-md-3">
                            <Col xs={12} md="auto" className="d-flex">
                                <Timeline.OppositeContent>
                                    <p className="fs-10 fw-semibold text-body-tertiary text-opacity-85 text-end">
                                        {moment(item.created_at).format('DD MMM, YYYY')}
                                        <br className="d-none d-md-block" />{' '}
                                        {moment(item.created_at).format('HH:mm a')}
                                    </p>
                                </Timeline.OppositeContent>
                                <Timeline.Separator className="position-md-relative">
                                    <Timeline.Dot className="bg-primary-subtle">
                                        <FontAwesomeIcon
                                            icon={item.type === 'add' ? faPlus : item.type === 'update' ? faEdit : item.type === 'delete' ? faTrash : faChess}
                                            className="text-primary-dark fs-10"
                                        />
                                    </Timeline.Dot>
                                    {index !== projectLog.length - 1 && (
                                        <Timeline.Bar className="h-100 border-dashed" />
                                    )}
                                </Timeline.Separator>
                            </Col>
                            <Col>
                                <Timeline.Content>
                                    <h5 className="fs-9 lh-sm">{item.description}</h5>
                                    <p className="fs-9">
                                        by{' '}
                                        <Link className="fw-semibold" to="#!">
                                            {item.created_by}
                                        </Link>
                                    </p>
                                    {/* <p
                                        className={classNames('fs-9 text-body-secondary', {
                                            'mb-5': index !== data.length - 1,
                                            'mb-0': index === data.length - 1
                                        })}
                                    >
                                        {item.content.details}
                                    </p> */}
                                </Timeline.Content>
                            </Col>
                        </Row>
                    </Timeline.Item>
                ))
            }
        </Timeline>
    );
};

export default ActivityTimeline;
