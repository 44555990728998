import moment from "moment";
import * as XLSX from 'xlsx';

function isValidUrl(string) {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}

const downloadExcel = (data, filename) => {
    let date = moment(new Date()).format('YYYYMMDDHHmmss');
    let excelFileName = `${filename}_${date.toString()}.xlsx`;
    const wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(data && data.length > 0 ? data : []);
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, excelFileName)
}

function deepCopyArray(arr) {
    return arr.map(item => ({ ...item }));
}

const getSweelAlertBg = () => {
    let darkTheme = JSON.parse(localStorage.getItem('isDark'));
    if(darkTheme){
        return "#1e1e1e";
    } else {
        return "#ffffff";
    }
}

const getSweelAlertTextColor = () => {
    let darkTheme = JSON.parse(localStorage.getItem('isDark'));
    if(darkTheme){
        return "#ffffff";
    } else {
        return "#000000";
    }
}

export { isValidUrl, downloadExcel, deepCopyArray, getSweelAlertTextColor, getSweelAlertBg }