import { faEye, faFileExport, faPlus, faSpinner, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { translate } from 'utils/translate';
import * as XLSX from 'xlsx';

const InvoiceList = (props) => {
  let navigate = useNavigate();
  const { config } = useAppContext()
  const [productPerpage, setProductPerpage] = useState(10)
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [result, setResult] = useState([]);
  const [financialNumber, setFinancialNumber] = useState('');
  const [customer, setCustomer] = useState('');
  const [statistic, setStatistic] = useState(0);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const timerRef = useRef(null);
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortOrder: '',
    subSort: '',
    subOrder: '',
  });

  let breadcrumbData = [
    { label: translate(props.lang, 'Payment'), url: '#' },
    { label: translate(props.lang, 'Invoice'), active: true }
  ]

  const changePage = (event) => {
    setPageNumber(event.selected + 1);
    getList(event.selected + 1)
  }

  useEffect(() => {
    if (firstLoad) {
      getList();
      setFirstLoad(false)
    }
  }, [props.auth.auth.token]);

  useEffect(() => {
    if (!firstLoad) {
      setPageNumber(1)
      getList(1);
    }
  }, [sortBy.sortField, sortBy.sortOrder, props.lang, name, code, productPerpage]);

  const getList = (page = pageNumber) => {
    setLoading(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      let url = api.get_project_financials + "?page=" + page + "&limit=" + productPerpage + '?details=1&type=invoice';
      let search = 0;
      if (financialNumber) {
        search = 1;
        url = url + `&financialNumber=${financialNumber}`
      }
      if (customer) {
        search = 1;
        url = url + `&customer=${customer}`
      }
      if (sortBy.sortField && sortBy.sortOrder) {
        url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
      }
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          if (responseJson.status === "success") {
            setResult(responseJson.data.data);
            setTotalResultCount(responseJson.data.subs.totalOrganization)
            let totalPage = Math.ceil(responseJson.data.subs.totalOrganization / productPerpage);
            setPageCount(totalPage);
            setStatistic(responseJson.data.subs.totalOrganization)
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout(props.history, 'login'));
            } else if (responseJson.tokenExpired) {
              dispatch(logout(props.history, 'login'));
            } else {
              console.log('error', responseJson.message)
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }, 1000);
  }

  const formatData = (data) => {
    let newData = [];
    data.forEach(entry => {
      let formattedData = {
        "Name": entry.name ? entry.name : '-',
        "Code": entry.company_prefix ? entry.company_prefix : '-',
        "SSM": entry.ssm ? entry.ssm : '-',
        "Tax ID": entry.tax_id ? entry.tax_id : '-',
        "Industry": entry.industry ? entry.industry : '-',
        "Business Type": entry.customer_company_name ? entry.customer_company_name : '-',
        "Currency": entry.currency ? entry.currency : '-',
        "Website": entry.website_url ? entry.website_url : '-',

      };
      newData.push(formattedData)
    })
    return newData;
  }

  const exportExcel = () => {
    setLoadingExcel(true);
    const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
    let processData = [];
    let totaldata = 0;
    for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
      let url = api.get_project_financials + "?page=" + pageCount + "&limit=" + exportPageSize + '&type=invoice';
      let search = 0;
      if (financialNumber) {
        search = 1;
        url = url + `&financialNumber=${financialNumber}`
      }
      if (customer) {
        search = 1;
        url = url + `&customer=${customer}`
      }
      if (sortBy.sortField && sortBy.sortOrder) {
        url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
      }

      fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang,
          "Authorization": "Bearer " + props.auth.auth.token
        }),
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.status === "success") {
            totaldata = responseJson.data.subs.totalOrganization;
            processData = processData.concat(formatData(responseJson.data.data));
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout(props.history, 'login'));
            } else if (responseJson.tokenExpired) {
              dispatch(logout(props.history, 'login'));
            } else {
              console.log('error', responseJson.message)
            }
          }
          if (processData.length === totaldata) {
            downloadExcel(processData, 'invoice')
            setLoadingExcel(false)
          }
        }).catch(error => {
          console.error("error", error);
        });

      setTimeout(() => { }, 500) // add some delay here to let server take rest.
    }
  }

  return (
    <div>
      <PageBreadcrumb items={breadcrumbData} />
      <div className="mb-9">
        <h2 className="mb-5">Invoice</h2>
        <div className="mb-4">
          <Row className="g-3">
            <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
              <div className="tableBgContainer py-4 px-4 total">
                <span className="font-18 font-weight-500">{translate(props.lang, "Total Invoice")}</span>
                <div className="dataBg px-2 py-1 mt-2">
                  <span className="font-16 font-weight-500 d-block mt-1">{statistic ? statistic : 0}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="g-3">
            <Col lg={4} md={4} xs={12} sm={12}>
              <Form.Control
                type="text"
                placeholder={translate(props.lang, "Search by Invoice No.")}
                onChange={e => setFinancialNumber(e.target.value)}
                value={name}
              />
            </Col>
            <Col lg={4} md={4} xs={12} sm={12}>
              <Form.Control
                type="text"
                placeholder={translate(props.lang, "Search by Customer")}
                onChange={e => setCustomer(e.target.value)}
                value={customer}
              />
            </Col>
            <Col lg={4} md={4} xs={12} sm={12}>
              <Button variant="info" className="text-center w-100" onClick={() => exportExcel()}>
                {loadingExcel ? (
                  <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                    {translate(props.lang, "Export")}
                  </>
                )}
              </Button>
            </Col>
            <Col lg={4} md={4} xs={12} sm={12}>
              <Button variant="primary" className='w-100 text-center' onClick={() => { navigate('/invoice/new') }}>
                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                {translate(props.lang, "Add Invoice")}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
          <Table responsive="sm" >
            <thead>
              <tr>
                <th className='py-2 text-center'>No.</th>
                <th className='py-2 text-center'>Invoice No.</th>
                <th className='py-2'>Subject</th>
                <th className='py-2'>Customer</th>
                <th className='py-2'>Amount</th>
                <th className='py-2'>Status</th>
                <th className='py-2'>Created Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={7}>
                    <DataLoading theme='light' />
                  </td>
                </tr>
              ) : (
                result.length > 0 ? (
                  result.map((item, i) => (
                    <tr role="row" key={i}>
                      <td className='fs-9 py-2 text-center'>
                        {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                        {i + 1 === 10 ? '0' : i + 1}.
                      </td>
                      <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '300px' }}>{item.financial_number}</td>
                      <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{item.subject}</td>
                      <td className='td-text py-2 fs-9'>{item.customer.name}</td>
                      <td className='td-text py-2 fs-9'>{Number(item.total_amount).toLocaleString('en-US')}</td>
                      <td className='td-text py-2 fs-9'>{item.financial_status}</td>
                      <td className='py-2' style={{ minWidth: '100px' }}>
                        <Link to={`/quotation/edit/${item.project_financial_id}`}>
                          <FontAwesomeIcon icon={faEye} color="#839AB4" fontSize={20} />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr role="row">
                    <td colSpan={7}>
                      <NoResultFound />
                    </td>
                  </tr>
                )
              )}

            </tbody>
          </Table>
          <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
            <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
              <div className='d-flex w-100 align-items-center justify-content-end'>
                <span className='fs-9'>Items Per Page</span>
                <div className="input-group-select-paginate ms-2 px-2">
                  <Select
                    options={
                      shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                    }
                    className="input-transparent w-100 text-capitalize px-0"
                    styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                    value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                    name="productPerpage"
                    isSearchable={false}
                    onChange={(e) => setProductPerpage(e ? e.value : '')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center my-3'>
            <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
              {!loading && result.length > 0 ? (
                <div className="my-3">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    forcePage={pageNumber - 1}
                    onPageChange={changePage}
                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                    disabledClassName={"pagination__link--disabled text-muted"}
                    activeClassName={"pagination__link--active text-white"}
                    marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(InvoiceList);
