import { faEye, faFileExport, faPlus, faSpinner, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { exportPageSize, shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'User', active: true }
]

const UserList = (props) => {
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [statistic, setStatistic] = useState(0);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [employeeId, setEmployeeId] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [positionName, setPositionName] = useState('');
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });
    const timerRef = useRef(null);
    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getList();
            setFirstLoad(false)
        }
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, name, username, employeeId, departmentName, organizationName, positionName]);

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_workers + "?page=" + page + "&limit=" + productPerpage + '&details=1';
            let search = 0;
            if (username) {
                search = 1;
                url = url + `&username=${username}`
            }
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (employeeId) {
                search = 1;
                url = url + `&employeeNumber=${employeeId}`
            }
            if (departmentName) {
                search = 1;
                url = url + `&departmentName=${departmentName}`
            }
            if (organizationName) {
                search = 1;
                url = url + `&organizationName=${organizationName}`
            }
            if (positionName) {
                search = 1;
                url = url + `&positionName=${positionName}`
            }
            if (search) {
                url = url + `&isSearch=1`
            }
            if (sortBy.subSort && sortBy.subOrder) {
                url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.totalWorkers)
                        setTotalResultCount(responseJson.data.subs.totalWorkers)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const exportExcel = () => {
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        if (totalPagesCount > 0) {
            setLoadingExcel(true);
            for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
                let url = api.get_workers + "?page=" + pageCount + "&limit=" + exportPageSize + '&details=1';
                let search = 0;
                if (username) {
                    search = 1;
                    url = url + `&username=${username}`
                }
                if (name) {
                    search = 1;
                    url = url + `&name=${name}`
                }
                if (employeeId) {
                    search = 1;
                    url = url + `&employeeNumber=${employeeId}`
                }
                if (departmentName) {
                    search = 1;
                    url = url + `&departmentName=${departmentName}`
                }
                if (organizationName) {
                    search = 1;
                    url = url + `&organizationName=${organizationName}`
                }
                if (positionName) {
                    search = 1;
                    url = url + `&positionName=${positionName}`
                }
                if (search) {
                    url = url + `&isSearch=1`
                }
                if (sortBy.subSort && sortBy.subOrder) {
                    url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
                }

                fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang,
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            totaldata = responseJson.data.subs.totalWorkers;
                            processData = processData.concat(formatData(responseJson.data.data));
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }
                        if (processData.length === totaldata) {
                            downloadExcel(processData, 'users')
                            setLoadingExcel(false)
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });

                setTimeout(() => { }, 500) // add some delay here to let server take rest.
            }
        } else {
            Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: translate(props.lang, "No data to export"),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
            });
        }
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(entry => {
            let formattedData = {
                "Username": entry.username ? entry.username : '-',
                "Name": entry.name ? entry.name : '-',
                "Employee ID": entry.employee_number ? entry.employee_number : '-',
                "Organization": entry.department.organization ? entry.department.organization.name : '-',
                "Department": entry.department ? entry.department.name : '-',
                "Position": entry.worker_position ? entry.worker_position.name : '-',
            };
            newData.push(formattedData);
        });
        return newData;
    }

    const toggleStatus = (id, status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.edit_worker_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "User update successful"),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getList();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, "User")}</h2>
                <div className="mb-4">
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                            <div className="tableBgContainer py-4 px-4">
                                <span className="font-18 font-weight-500">{translate(props.lang, "Total User")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="font-16 font-weight-500 d-block mt-1">{statistic ? statistic : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Username")}
                                onChange={e => setUsername(e.target.value)}
                                value={username}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Name")}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Employee ID")}
                                onChange={e => setEmployeeId(e.target.value)}
                                value={employeeId}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Organization")}
                                onChange={e => setOrganizationName(e.target.value)}
                                value={organizationName}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Department")}
                                onChange={e => setDepartmentName(e.target.value)}
                                value={departmentName}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Position")}
                                onChange={e => setPositionName(e.target.value)}
                                value={positionName}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Button variant="info" className="text-center w-100" onClick={() => exportExcel()}>
                                {loadingExcel ? (
                                    <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                        {translate(props.lang, "Export")}
                                    </>
                                )}
                            </Button>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Button variant="primary" className='w-100 text-center' onClick={() => { navigate('/user/new') }}>
                                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                {translate(props.lang, "Add User")}
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <Table responsive="sm" >
                        <thead>
                            <tr>
                                <th className='py-2 text-center'>No.</th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('username', false)}>
                                        <span className="pe-2">{translate(props.lang, "Username")}</span>
                                        {sortBy.sortField === 'username' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('name', false)}>
                                        <span className="pe-2">{translate(props.lang, "Full Name")}</span>
                                        {sortBy.sortField === 'name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('employee_number', false)}>
                                        <span className="pe-2">{translate(props.lang, "Employee ID")}</span>
                                        {sortBy.sortField === 'employee_number' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className={`py-2`}>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('worker_position.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Position")}</span>
                                        {sortBy.subSort === 'worker_position.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className={`py-2`}>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('department.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Department")}</span>
                                        {sortBy.subSort === 'department.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className={`py-2`}>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('organization.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Organization")}</span>
                                        {sortBy.subSort === 'organization.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan={8}>
                                        <DataLoading theme='light' />
                                    </td>
                                </tr>
                            ) : (
                                result.length > 0 ? (
                                    result.map((item, i) => (
                                        <tr role="row" key={i}>
                                            <td className='fs-9 py-2 text-center'>
                                                {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                                                {i + 1 === 10 ? '0' : i + 1}.
                                            </td>
                                            <td className='td-text py-2 fs-9'>{item.username}</td>
                                            <td className='td-text py-2 fs-9'>{item.name}</td>
                                            <td className='td-text py-2 fs-9'>{item.employee_number}</td>
                                            <td className='td-text py-2 fs-9 text-capitalize'>{item.worker_position.name.toLowerCase()}</td>
                                            <td className='td-text py-2 fs-9 text-capitalize'>{item.department.name.toLowerCase()}</td>
                                            <td className='td-text py-2 fs-9 text-capitalize'>{item.department.organization.name.toLowerCase()}</td>
                                            <td className='py-2' style={{ minWidth: '100px' }}>
                                                <Link to={`/user/edit/${item.worker_id}`} className='me-3'>
                                                    <FontAwesomeIcon icon={faEye} color="#839AB4" fontSize={20} />
                                                </Link>
                                                {item.status === 'active' ? (
                                                    <FontAwesomeIcon icon={faToggleOff} color="#01CF91" fontSize={20} onClick={() => toggleStatus(item.worker_id, 'active')} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faToggleOn} color="#839AB4" fontSize={20} onClick={() => toggleStatus(item.worker_id, 'inactive')} />
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr role="row">
                                        <td colSpan={8}>
                                            <NoResultFound />
                                        </td>
                                    </tr>
                                )
                            )}

                        </tbody>
                    </Table>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>Items Per Page</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(UserList);
