import { faEye, faFileExport, faPlus, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { api } from 'utils/api';
import { shownItem } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'Dashboard', url: '#' },
    { label: 'Overview', active: true }
]

const DashboardOverview = (props) => {
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [productPerpage, setProductPerpage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loadingGraph, setLoadingGraph] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [statistic, setStatistic] = useState(0);
    const [result, setResult] = useState([]);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [organization, setOrganization] = useState('');
    const [dashboardInfo, setDashboardInfo] = useState({
        ongoingTaskList: [],
        totalCompletedProject: 0,
        totalCompletedTask: 0,
        totalOngoingProject: 0,
        totalOngoingTask: 0
    })
    const [formData, setFormData] = useState({
        filterType: 'name',
        filterValue: '',
    })
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });
    const timerRef = useRef(null);
    const searchDataRef = useRef(formData);
    useEffect(() => {
        searchDataRef.current = formData;
    }, [formData]);

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getList();
            setFirstLoad(false)
        }
    }, []);

    useEffect(() => {
        getDashboard();
    }, [props.auth.auth.token, pageNumber])

    const getDashboard = () => {
        let url = api.get_my_dashboard + `?page=${pageNumber}&limit=${productPerpage}&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`;

        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setDashboardInfo(responseJson.data);
                    let totalPage = Math.ceil(responseJson.data.ongoingTaskListCount / productPerpage);
                    setPageCount(totalPage);
                    // let newConfig = { ...projectChartConfig };
                    // newConfig.series = [
                    //     responseJson.data.totalDraftProject,
                    //     responseJson.data.totalOngoingProject,
                    //     responseJson.data.totalCompletedProject,
                    //     responseJson.data.totalOnQueueProject,
                    //     responseJson.data.totalMaintenanceProject,
                    //     responseJson.data.totalQCProject,
                    //     responseJson.data.totalCancelledProject,
                    //     responseJson.data.totalPendingFeedbackProject,
                    // ]
                    // setProjectChartConfig(newConfig);

                    // let newTaskConfig = { ...taskChartConfig };
                    // newTaskConfig.series = [
                    //     responseJson.data.totalDraftTask,
                    //     responseJson.data.totalOngoingTask,
                    //     responseJson.data.totalCompletedTask,
                    //     responseJson.data.totalOnQueueTask,
                    //     responseJson.data.totalMaintenanceTask,
                    //     responseJson.data.totalQCTask,
                    //     responseJson.data.totalCancelledTask,
                    //     responseJson.data.totalPendingFeedbackTask,
                    // ]
                    // setTaskChartConfig(newTaskConfig);

                    // if (responseJson.data.ongoingTaskList.length > 0) {
                    //     responseJson.data.ongoingTaskList.map((item, i) => {
                    //         if (item.task_timesheet.length > 0) {
                    //             setCurrentRunningTask({ project_task_id: item.project_task_id, start_time: item.task_timesheet[0].start })
                    //         }
                    //     })
                    // }

                    setTimeout(() => {
                        setLoadingGraph(false)
                    }, 2000);

                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/');
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/');
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, productPerpage, name, code, organization]);

    const setSorting = (field, isSub) => {
        let newSortBy = { ...sortBy };
        if (isSub) {
            if (newSortBy.subSort === field) {
                if (newSortBy.subOrder === 'ASC') {
                    newSortBy.subOrder = "DESC";
                } else if (newSortBy.subOrder === 'DESC') {
                    newSortBy.subOrder = "";
                    newSortBy.subSort = "";
                }
            } else {
                newSortBy.subSort = field;
                newSortBy.subOrder = "ASC";
            }
            newSortBy.sortField = '';
            newSortBy.sortOrder = ''
        } else {
            if (newSortBy.sortField === field) {
                if (newSortBy.sortOrder === 'ASC') {
                    newSortBy.sortOrder = "DESC";
                } else if (newSortBy.sortOrder === 'DESC') {
                    newSortBy.sortOrder = "";
                    newSortBy.sortField = "";
                }
            } else {
                newSortBy.sortField = field;
                newSortBy.sortOrder = "ASC";
            }
            newSortBy.subSort = '';
            newSortBy.subOrder = "";
        }

        setSortBy(newSortBy)
    }

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_departments + "?page=" + page + "&limit=" + productPerpage + '&details=1';
            let search = 0;
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (code) {
                search = 1;
                url = url + `&code=${code}`
            }
            if (organization) {
                search = 1;
                url = url + `&organization=${organization}`
            }
            if (search) {
                url = url + `&isSearch=1`
            }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            if (sortBy.subSort && sortBy.subOrder) {
                url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalDepartment / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.totalDepartment)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const exportExcel = () => {
        setLoadingExcel(true);
        let url = api.get_departments + "?page=1&limit=999&details=1";
        let search = 0;
        if (name) {
            search = 1;
            url = url + `&name=${name}`
        }
        if (code) {
            search = 1;
            url = url + `&code=${code}`
        }
        if (organization) {
            search = 1;
            url = url + `&organization=${organization}`
        }
        if (search) {
            url = url + `&isSearch=1`
        }
        if (sortBy.sortField && sortBy.sortOrder) {
            url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
        }
        if (sortBy.subSort && sortBy.subOrder) {
            url = url + `&subSort=${sortBy.subSort}&subOrder=${sortBy.subOrder}`
        }
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingExcel(false);
                }, 1000);
                if (responseJson.status === "success") {
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/');
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/');
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const toggleStatus = (id, status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        swal({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            buttons: [translate(props.lang, "No"), translate(props.lang, "Confirm")],
            dangerMode: false,
        }).then((willDelete) => {
            if (willDelete) {
                let url = api.edit_department_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            swal(translate(props.lang, "Success"), translate(props.lang, "Department update successful"), "success");
                            getList();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/');
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/');
                            } else {
                                swal(translate(props.lang, "Error"), responseJson.message, "error");
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <div className="mb-4">
                    <Row className="g-3 mb-3">
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 ongoing">
                                <span className="font-18 font-weight-500">{translate(props.lang, "Ongoing Projects")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="font-16 font-weight-500 d-block mt-1">{dashboardInfo.totalOngoingProject ? dashboardInfo.totalOngoingProject : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 completed">
                                <span className="font-18 font-weight-500">{translate(props.lang, "Completed Projects")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="font-16 font-weight-500 d-block mt-1">{dashboardInfo.totalCompletedProject ? dashboardInfo.totalCompletedProject : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 ongoing">
                                <span className="font-18 font-weight-500">{translate(props.lang, "Ongoing Tasks")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="font-16 font-weight-500 d-block mt-1">{dashboardInfo.totalOngoingTask ? dashboardInfo.totalOngoingTask : 0}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12} sm={12}>
                            <div className="tableBgContainer py-4 px-4 completed">
                                <span className="font-18 font-weight-500">{translate(props.lang, "Completed Tasks")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="font-16 font-weight-500 d-block mt-1">{dashboardInfo.totalCompletedTask ? dashboardInfo.totalCompletedTask : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Name")}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Code")}
                                onChange={e => setCode(e.target.value)}
                                value={code}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Organization")}
                                onChange={e => setOrganization(e.target.value)}
                                value={organization}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <Table responsive="sm" >
                        <thead>
                            <tr>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('name', false)}>
                                        <span className="pe-2">{translate(props.lang, "Task Name")}</span>
                                        {sortBy.sortField === 'name' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('code', false)}>
                                        <span className="pe-2">{translate(props.lang, "Project")}</span>
                                        {sortBy.sortField === 'code' ? sortBy.sortOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('organization.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Time Log")}</span>
                                        {sortBy.subSort === 'organization.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('organization.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Status")}</span>
                                        {sortBy.subSort === 'organization.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('organization.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Stage")}</span>
                                        {sortBy.subSort === 'organization.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th className='py-2'>
                                    <div className="d-flex cursor-pointer" onClick={() => setSorting('organization.name', true)}>
                                        <span className="pe-2">{translate(props.lang, "Priority")}</span>
                                        {sortBy.subSort === 'organization.name' ? sortBy.subOrder === 'ASC' ? <img src={require('../../assets/img/asc.svg').default} /> : <img src={require('../../assets/img/desc.svg').default} /> : <img src={require('../../assets/img/defaultSort.svg').default} />}
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan={7}>
                                        <DataLoading theme='light' />
                                    </td>
                                </tr>
                            ) : (
                                result.length > 0 ? (
                                    result.map((item, i) => (
                                        <tr role="row" key={i}>
                                            <td className='fs-9 py-2 text-center'>
                                                {pageNumber - 1 ? i === 9 ? pageNumber : pageNumber - 1 : (i === 9 ? pageNumber : '')}
                                                {i + 1 === 10 ? '0' : i + 1}.
                                            </td>
                                            <td className='td-text py-2 fs-9 text-capitalize' style={{ minWidth: '300px' }}>{item.name.toLowerCase()}</td>
                                            <td className='td-text py-2 fs-9' style={{ minWidth: '200px' }}>{item.code}</td>
                                            <td className='td-text py-2 fs-9 text-capitalize'>{item.organization.name.toLowerCase()}</td>
                                            <td className='td-text py-2 fs-9 text-capitalize'></td>
                                            <td className='td-text py-2 fs-9 text-capitalize'></td>
                                            <td className='py-2' style={{ minWidth: '100px' }}>
                                                
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr role="row">
                                        <td colSpan={7}>
                                            <NoResultFound />
                                        </td>
                                    </tr>
                                )
                            )}

                        </tbody>
                    </Table>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={2} md={2} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>Items Per Page</span>
                                <div className="input-group-select-paginate ms-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(DashboardOverview);
