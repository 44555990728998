import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import { useAppContext } from 'providers/AppProvider';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

let breadcrumbData = [
  { label: 'Setting', url: '#' },
  { label: 'User Group', url: '/user-group/overview' },
  { label: 'Edit User Group ', active: true }
]

const typeList = [
  { label: 'Organization', value: 'organization' },
  { label: 'Department', value: 'department' },
  { label: 'User', value: 'user' },
]

const EditUserGroup = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  let errorObj = {
    name: null,
    type: null,
    audience: null,
    description: null,
  }
  const { config } = useAppContext();
  const [errors, setErrors] = useState(errorObj);
  const [loading, setLoading] = useState(false);
  const [targetAudience, setTargetAudience] = useState([]);
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    description: '',
    audience: [],
  })
  const infoRef = useRef(null);

  useEffect(() => {
    getInfo();
  }, [])

  const getInfo = () => {
    clearTimeout(infoRef.current);
    infoRef.current = setTimeout(() => {
      if (id) {
        fetch(api.get_announcement_group_details + '/' + id, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accept-Language": props.lang.toLowerCase(),
            "Authorization": "Bearer " + props.auth.auth.token
          }),
        })
          .then(response => response.json())
          .then(responseJson => {
            if (responseJson.status === "success") {
              let newFormData = { ...formData };
              newFormData.name = responseJson.data.name;
              newFormData.description = responseJson.data.description;
              newFormData.type = responseJson.data.type;
              let arr = [];
              responseJson.data.audience.map((item) => {
                arr.push(item.worker.worker_id)
              })
              newFormData.audience = arr;
              setTargetAudience(responseJson.data.audience)

              setFormData(newFormData);
            } else {
              if (responseJson.message === 'token expired') {
                dispatch(logout());
                navigate('/')
              } else if (responseJson.tokenExpired) {
                dispatch(logout());
                navigate('/')
              } else {
                console.log('error', responseJson.message)
              }
            }

          }).catch(error => {
            console.error("error", error);
          });
      }
    }, 1000);
  }

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...formData };
    newFormData[fieldName] = fieldValue;
    setFormData(newFormData);
  }

  const handleOptionSelected = async (e, type) => {
    const { value } = e;
    const newFormData = { ...formData };
    newFormData[type] = value;
    setFormData(newFormData);
  }

  const submitForm = () => {
    let check = true;
    let errorMsg = { ...errorObj };
    let newFormData = { ...formData };
    if (!formData.name) {
      errorMsg.name = "This field is required.";
      check = false;
    }
    if (!formData.description) {
      errorMsg.description = "This field is required.";
      check = false;
    }

    setErrors(errorMsg);
    if (check) {
      setLoading(true);
      fetch(api.edit_announcement_group + '/' + id, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }),
        body: JSON.stringify(newFormData)
      })
        .then(response => response.json())
        .then(responseJson => {
          setLoading(false)
          if (responseJson.status === 'success') {
            Swal.fire({
              icon: "success",
              title: translate(props.lang, 'Success'),
              text: translate(props.lang, "User group has been updated successful"),
              background: getSweelAlertBg(), // Dark background color
              color: getSweelAlertTextColor(), // Text color
            });
            resetForm();
          } else {
            if (responseJson.message === 'token expired') {
              dispatch(logout());
              navigate('/')
            } else if (responseJson.tokenExpired) {
              dispatch(logout());
              navigate('/')
            } else {
              if (Array.isArray(responseJson.message)) {
                Swal.fire({
                  icon: "error",
                  title: translate(props.lang, 'Error'),
                  text: responseJson.message.join('\n'),
                  background: getSweelAlertBg(), // Dark background color
                  color: getSweelAlertTextColor(), // Text color
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: translate(props.lang, 'Error'),
                  text: responseJson.message,
                  background: getSweelAlertBg(), // Dark background color
                  color: getSweelAlertTextColor(), // Text color
                });
              }
            }
          }
        }).catch(error => {
          console.error("error", error);
        });
    }
  }

  const resetForm = () => {
    getInfo();
    setEditable(false);
    setErrors(errorObj);
  }

  return (
    <div>
      <PageBreadcrumb items={breadcrumbData} />
      <h2 className="mb-4">{translate(props.lang, "Edit User Group")}</h2>
      <Row className='align-items-center justify-content-end'>
        <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
          {!editable ? (
            <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
              <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row className='align-items-center justify-content-center'>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "User Group Name")} <span className="text-danger">*</span></span>
            <div className={`input-group`}>
              <input
                type={'text'}
                name="name"
                placeholder={translate(props.lang, "Enter User Group Name")}
                className="form-control input-transparent py-1 px-3"
                value={formData.name}
                autoComplete="off"
                readOnly={!editable}
                onChange={e => handleAddFormChange(e)}
              />
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
            <div className={`input-group`}>
              <Form.Control
                as="textarea"
                name="description"
                className="form-control input-transparent py-1 px-3"
                rows={3}
                value={formData.description}
                readOnly={!editable}
                placeholder={translate(props.lang, "Enter description")}
                onChange={e => handleAddFormChange(e)}
              />
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.type ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Target Audience Type")}</span>
            <div className="input-group-select px-3">
              <Select
                options={
                  typeList.map((item, i) => ({ key: i, value: item.value, label: item.label }))
                }
                placeholder={translate(props.lang, "Select Type")}
                className="input-transparent w-100 text-capitalize"
                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                isSearchable={true}
                isDisabled={true}
                value={typeList.filter(option => option.value === formData.type).length > 0 ? typeList.filter(option => option.value === formData.type).map((item, i) => ({ key: i, value: item.value, label: item.label })) : null}
                name="type"
                onChange={(e) => handleOptionSelected(e, 'type')}
              />
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.type)}</span>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
          <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Target Audience")}</span>
            <div className='px-3'>
              <Table>
                <tbody>
                  {targetAudience.map((item, i) => (
                    <tr key={i}>
                      <td className="fs-9 font-weight-400">{item.worker.name} ({item.worker.employee_number})</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        {editable ? (
          <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
            <div className="d-flex justify-content-start gap-3">
              <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
              </Button>
              <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/user-group/overview')}>
                {translate(props.lang, "Cancel")}
              </Button>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(EditUserGroup);
