import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from '@tinymce/tinymce-react';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from 'react-select/creatable';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { priorityList, statusList } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'Project' },
    { label: 'Edit Project ', active: true }
]

const EditProject = (props) => {
    const infoRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useAppContext();
    let errorObj = {
        name: '',
        customerId: '',
        start_date: null,
        due_date: null,
        description: '',
        budget: '',
        progressStatus: '',
        personInCharge: null,
        salesOwner: null,
        salesSubs: null,
        priorityLevel: '',
        labels: '',
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [projectDetails, setProjectDetails] = useState(null);
    const [loadingSalesOwner, setLoadingSalesOwner] = useState(false);
    const [loadingPIC, setLoadingPIC] = useState(false);
    const [loadingSalesSubs, setLoadingSalesSubs] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [personInCharge, setPersonInCharge] = useState([]);
    const [salesOwner, setSalesOwner] = useState([]);
    const [salesSubs, setSalesSubs] = useState([]);
    const [workerList, setWorkerList] = useState([]);
    const [labelOption, setLabelOption] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        customerId: '',
        start_date: null,
        due_date: null,
        description: '',
        budget: 0,
        progressStatus: 'draft',
        personInCharge: [],
        salesOwner: [],
        salesSubs: [],
        priorityLevel: 0,
        labels: '',
    })

    useEffect(() => {
        getInfo();
        getCustomer()
        get_project_labels();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_project_details + '/' + props.id + '?details=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    // setProjectInfo(responseJson.data)
                    setContent(responseJson.data.description);
                    let newFormData = { ...formData };
                    newFormData.budget = responseJson.data.budget;
                    newFormData.progressStatus = responseJson.data.progress_status;
                    newFormData.priorityLevel = responseJson.data.priority_level;
                    newFormData.name = responseJson.data.name;
                    newFormData.description = responseJson.data.description;
                    newFormData.start_date = responseJson.data.start_date ? responseJson.data.start_date : '';
                    newFormData.due_date = responseJson.data.due_date ? responseJson.data.due_date : '';
                    newFormData.customerId = responseJson.data.customer.customer_id;
                    if (responseJson.data.label) {
                        let arr = [];
                        responseJson.data.label.split(',').map((item) => {
                            arr.push({ label: item, value: item });
                        })
                        setSelectedLabel(arr);
                    }

                    // let salesOwnerArr = [];
                    let displaySalesOwnerArr = [];
                    responseJson.data.owner.filter(item => item.type === 'sales owner').map((item, i) => {
                        // salesOwnerArr.push({ worker_id: item.oWorker.worker_id, name: item.oWorker.name, employee_number: item.oWorker.employee_number })
                        displaySalesOwnerArr.push({ value: item?.oWorker?.worker_id, label: item?.oWorker?.name + ' (' + item?.oWorker?.employee_number + ')' });
                    })
                    setSalesOwner(displaySalesOwnerArr);
                    // setSalesOwner(salesOwnerArr);

                    let salesSubArr = [];
                    responseJson.data.owner.filter(item => item.type === 'sales subs').map((item, i) => {
                        // salesSubArr.push({ worker_id: item.oWorker.worker_id, name: item.oWorker.name, employee_number: item.oWorker.employee_number })
                        salesSubArr.push({ value: item?.oWorker?.worker_id, label: item?.oWorker?.name + ' (' + item?.oWorker?.employee_number + ')' });
                    })
                    setSalesSubs(salesSubArr);

                    let picArr = [];
                    responseJson.data.owner.filter(item => item.type === 'person in charge').map((item, i) => {
                        // picArr.push({ worker_id: item.oWorker.worker_id, name: item.oWorker.name, employee_number: item.oWorker.employee_number })
                        picArr.push({ value: item?.oWorker?.worker_id, label: item?.oWorker?.name + ' (' + item?.oWorker?.employee_number + ')' });
                    })
                    setPersonInCharge(picArr);
                    setFormData(newFormData);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const get_project_labels = () => {
        fetch(api.get_project_labels, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    let arr = [];
                    if (responseJson.data.label) {
                        responseJson.data.label.map((item) => {
                            arr.push({ label: item, value: item })
                        })
                        setLabelOption(arr)
                    }
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });

    }

    const getCustomer = () => {
        fetch(api.get_customers, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setCustomerList(responseJson.data.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getWorkers = (e) => {
        if (!e) {
            setWorkerList([])
            return;
        }
        setLoadingSearch(true);
        fetch(api.get_workers + `?isSearch=true&name=${e}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoadingSearch(false);
                if (responseJson.status === "success") {
                    setWorkerList(responseJson.data.data);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });

    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!content) {
            errorMsg.description = "This field is required.";
            check = false;
        } else {
            newFormData.description = content;
        }

        if (!formData.customerId) {
            errorMsg.customerId = "This field is required.";
            check = false;
        }
        if (!formData.budget) {
            errorMsg.budget = "This field is required.";
        } else {
            newFormData.budget = Number(formData.budget);
        }

        if (!formData.priorityLevel) {
            errorMsg.priorityLevel = "This field is required.";
        } else {
            newFormData.priorityLevel = Number(formData.priorityLevel);
        }

        if (personInCharge.length === 0) {
            errorMsg.personInCharge = "This field is required.";
            check = false;
        } else {
            let arr = []
            personInCharge.map((item, i) => {
                arr.push(item.value)
            })
            newFormData.personInCharge = arr;
        }
        if (salesOwner.length === 0) {
            errorMsg.salesOwner = "This field is required.";
            check = false;
        } else {
            let arr = []
            salesOwner.map((item, i) => {
                arr.push(item.value)
            })
            newFormData.salesOwner = arr;
        }
        if (salesSubs.length > 0) {
            let arr = []
            salesSubs.map((item, i) => {
                arr.push(item.value)
            })
            newFormData.salesSubs = arr;
        } else {
            newFormData.salesSubs = [];
        }
        if (formData.start_date && formData.due_date) {
            if (formData.start_date > formData.due_date) {
                errorMsg.start_date = "Start date cannot bigger than due date";
                check = false;
            } else {
                newFormData.start_date = moment(formData.start_date).format('YYYY-MM-DD')
                newFormData.due_date = moment(formData.due_date).format('YYYY-MM-DD')
            }
        }

        for (const key in newFormData) {
            if (newFormData[key] === '' || newFormData[key] === null) {
                if (key === 'budget') {
                    newFormData[key] = 0;
                } else {
                    delete newFormData[key];
                }
            }
        }
        let string = "";
        if (selectedLabel.length > 0) {
            selectedLabel.map((item, i) => {
                if (i !== 0) {
                    string = string + ',' + item.value
                } else {
                    string = item.value
                }
            })
            newFormData.labels = string
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true)
            fetch(api.edit_project + '/' + props.id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Project has been update successful"),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        props.getList();
                        props.closePanel();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const editData = () => {

    }

    const removeData = () => {

    }

    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingSalesOwner) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);
                const hasMore = page < totalPage;
                setLoadingSalesOwner(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                setLoadingSalesOwner(false)
                console.error('Error fetching data:', error);
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    const loadOptionsPIC = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingPIC) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);

                const hasMore = page < totalPage;
                setLoadingPIC(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                setLoadingPIC(false)
                console.error('Error fetching data:', error);
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    const loadOptionsSalesSubs = async (searchQuery, loadedOptions, { page }) => {
        let productPerpage = 10
        if (!loadingSalesSubs) {
            try {
                const response = await fetch(`${api.get_workers + "?page=" + page + '&limit=' + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
                    method: 'GET',
                    headers: {
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }
                });

                const responseJson = await response.json();
                if (responseJson.status !== "success") {
                    throw new Error('Failed to fetch: ' + responseJson);
                }

                const options = responseJson.data.data.map(item => ({
                    value: item.worker_id,
                    label: item.name + ' (' + item.employee_number + ')'
                }));
                let totalPage = Math.ceil(responseJson.data.subs.totalWorkers / productPerpage);

                const hasMore = page < totalPage;
                setLoadingSalesSubs(false)
                return {
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                };
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoadingSalesSubs(false)
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                    },
                };
            }
        }
    }

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            newFormData[type] = moment(e[0]).format('YYYY-MM-DD');
        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const handleChangeWorker = (e, type) => {
        if (type === 'salesOwner') {
            setSalesOwner(e);
        } else if (type === 'personInCharge') {
            setPersonInCharge(e)
        } else if (type === 'salesSubs') {
            setSalesSubs(e)
        }
    }

    const handleChangeLabel = (e) => {
        if (e) {
            setSelectedLabel(e);
        } else {
            setSelectedLabel([]);
        }
    }

    const selectStatus = (val) => {
        let newFormData = { ...formData };
        newFormData.progressStatus = val;
        setFormData(newFormData);
    }

    const selectPriority = (val) => {
        let newFormData = { ...formData };
        newFormData.priorityLevel = val;
        setFormData(newFormData);
    }

    const getColor = (val, type) => {
        let color = '#fff';
        if (type === 'status') {
            let filterList = statusList.filter(item => item.value === val);
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#f2f2f2';
            }
        } else if (type === 'priority') {
            let filterList = priorityList.filter(item => (item.value).toString() === val.toString());
            if (filterList.length > 0) {
                color = filterList[0].color;
            } else {
                color = '#f2f2f2';
            }
        }
        return color;
    }

    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Project")}</h2>
            <Row className='align-items-stretch justify-content-start'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter Project Name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.customerId ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Client")} <span className="text-danger">*</span></span>
                        <div className="input-group-select px-3">
                            <Select
                                options={
                                    customerList.map((item, i) => ({ value: item.customer_id, label: item.name + `${item.customer_company_name && item.customer_company_name !== item.name ? ' (' + item.customer_company_name + ')' : ''}`, key: i }))
                                }
                                placeholder={translate(props.lang, "Select Client")}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                isSearchable={true}
                                name="customerId"
                                value={
                                    formData.customerId ? (
                                        customerList.filter(item => item.customer_id == formData.customerId).length > 0 ?
                                            (customerList.filter(item => item.customer_id == formData.customerId).map((item, i) => ({ value: item.customer_id, label: item.name + `${item.customer_company_name && item.customer_company_name !== item.name ? ' (' + item.customer_company_name + ')' : ''}`, key: i })))
                                            : null
                                    ) : null
                                }
                                onChange={(e) => handleOptionSelected(e, 'customerId')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.customerId)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.start_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Date")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    readOnly={false}
                                    format="dd/MM/yyyy"
                                    views={['year', 'month', 'date']}
                                    placeholder={translate(props.lang, "Select Start Date")}
                                    className={`form-control date input-transparent py-1 px-3`}
                                    value={formData.start_date ? new Date(formData.start_date) : null}
                                    onChange={(e) => onChangeCalendar(e, 'start_date')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.start_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.due_date ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Due Date")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label=""
                                    clearable
                                    readOnly={false}
                                    format="dd/MM/yyyy"
                                    views={['year', 'month', 'date']}
                                    placeholder={translate(props.lang, "Select Due Date")}
                                    className={`form-control date input-transparent py-1 px-3`}
                                    value={formData.due_date ? new Date(formData.due_date) : null}
                                    onChange={(e) => onChangeCalendar(e, 'due_date')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.due_date)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 h-100 ${errors.budget ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project Cost")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="budget"
                                placeholder={translate(props.lang, "Enter Project Cost")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.budget}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.budget)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input h-100 py-1 ${errors.labels ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Label")} <span className="text-danger">*</span></span>
                        <div className={`input-group-select px-3`}>
                            <CreatableSelect
                                placeholder="Select Label"
                                className="input-transparent w-100 text-capitalize"
                                options={labelOption}
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                value={selectedLabel}
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable
                                onChange={(e) => handleChangeLabel(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.labels)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.progressStatus ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Status")} <span className="text-danger">*</span></span>
                        <div className={`input-group px-3`}>
                            <Dropdown className="btn-group w-100 mb-1 ">
                                <Dropdown.Toggle
                                    className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                    style={{ background: getColor(formData.progressStatus, 'status'), color: '#fff' }}
                                    data-toggle="dropdown"
                                >
                                    {translate(props.lang, getLabel(formData.progressStatus, "status"))}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='w-100'>
                                    {statusList.map((status, x) => (
                                        <Dropdown.Item key={x} as={status.value} onClick={() => selectStatus(status.value)}><span className='cursor-pointer' style={{ color: status.color }}>{translate(props.lang, status.label)}</span></Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.progressStatus)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.priorityLevel ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Priority")} <span className="text-danger">*</span></span>
                        <div className={`input-group px-3`}>
                            <Dropdown className="btn-group w-100 mb-1 ">
                                <Dropdown.Toggle
                                    className="btn light dropdown-toggle px-3 text-capitalize status-dropdown d-flex align-items-center justify-content-between"
                                    style={{ background: getColor(formData.priorityLevel, 'priority'), color: '#fff' }}
                                    data-toggle="dropdown"
                                >
                                    {translate(props.lang, getLabel(formData.priorityLevel, "priority"))}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {priorityList.map((priority, x) => (
                                        <Dropdown.Item key={x} onClick={() => selectPriority((priority.value).toString())}><span className='cursor-pointer' style={{ color: priority.color }}>{translate(props.lang, priority.label)}</span></Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.priorityLevel)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.salesOwner ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Sales Owner")} <span className="text-danger">*</span></span>
                        <div className={`input-group-select px-3`}>
                            <AsyncPaginate
                                placeholder={translate(props.lang, "Select option")}
                                debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                loadOptions={loadOptions} // Function to load options asynchronously
                                additional={{
                                    page: 1, // Initial page
                                }}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                value={salesOwner}
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={(e) => handleChangeWorker(e, 'salesOwner')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.salesOwner)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.salesSubs ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Sub Sales")} <span className="text-danger">*</span></span>
                        <div className={`input-group-select px-3`}>
                            <AsyncPaginate
                                placeholder={translate(props.lang, "Select option")}
                                debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                loadOptions={loadOptionsSalesSubs} // Function to load options asynchronously
                                additional={{
                                    page: 1, // Initial page
                                }}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                value={salesSubs}
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={(e) => handleChangeWorker(e, 'salesSubs')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.salesSubs)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.personInCharge ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Person In Charge")} <span className="text-danger">*</span></span>
                        <div className={`input-group-select px-3`}>
                            <AsyncPaginate
                                placeholder={translate(props.lang, "Select option")}
                                debounceTimeout={300} // Optional: wait 300ms after typing stops to make API request
                                loadOptions={loadOptionsPIC} // Function to load options asynchronously
                                additional={{
                                    page: 1, // Initial page
                                }}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                value={personInCharge}
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={(e) => handleChangeWorker(e, 'personInCharge')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.personInCharge)}</span>
                    </div>
                </Col>
                
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                    <div className={`groupLabel-input w-100 px-3 ${errors.description ? 'errorBorder' : ''}`}>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                            init={{
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            }}
                            initialValue={formData.description}
                            className="w-100"
                            onEditorChange={(content, editor) => {
                                setContent(content);
                            }}
                        />
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/project/overview')}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditProject);
