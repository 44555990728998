import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import {
    faChevronRight,
    faEarthAmericas,
    faListCheck,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { priorityList, stageList, statusList } from 'utils/config';
import { translate } from 'utils/translate';

const ProjectDetailsSummary = (props) => {
    const getLabel = (val, type) => {
        let text = val;
        if (type === 'priority') {
            let filterList = priorityList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'status') {
            let filterList = statusList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        } else if (type === 'stage') {
            let filterList = stageList.filter(item => item.value.toString() === val.toString());
            if (filterList.length > 0) {
                text = filterList[0].label;
            }
        }
        return text;
    }
    return (
        <>
            <div className="mb-4 mb-xl-7">
                <table className="lh-sm mb-4 mb-sm-0 mb-xl-4">
                    <tbody>
                        <tr>
                            <td className="py-1" colSpan={2}>
                                <div className="d-flex">
                                    <FontAwesomeIcon
                                        icon={faEarthAmericas}
                                        className="me-2 text-body-tertiary fs-9"
                                    />
                                    <h5 className="text-body">{props.projectInfo ? props.projectInfo.project_number : ''}</h5>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="align-top py-1">
                                <div className="d-flex">
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="me-2 text-body-tertiary fs-9"
                                    />
                                    <h5 className="text-body mb-0 text-nowrap">Client :</h5>
                                </div>
                            </td>
                            <td className="ps-1 py-1">
                                <p className="fw-semibold d-block lh-sm">
                                {props.projectInfo ? props.projectInfo.customer ? props.projectInfo.customer.name : '-' : '-'}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="align-top py-1">
                                <div className="d-flex">
                                    <FontAwesomeIcon
                                        icon={faCreditCard}
                                        className="me-2 text-body-tertiary fs-9"
                                    />
                                    <h5 className="text-body mb-0 text-nowrap">Budget : </h5>
                                </div>
                            </td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight">{props.projectInfo ? props.projectInfo.budget : '-'}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="lh-sm">
                    <tbody>
                        <tr>
                            <td className="align-top py-1 text-body text-nowrap fw-bold">
                                Started :{' '}
                            </td>
                            <td className="text-body-tertiary text-opacity-85 fw-semibold ps-3">
                            {props.projectInfo ? props.projectInfo.start_date : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td className="align-top py-1 text-body text-nowrap fw-bold">
                                Deadline :
                            </td>
                            <td className="text-body-tertiary text-opacity-85 fw-semibold ps-3">
                            {props.projectInfo ? props.projectInfo.due_date : '-'}
                            </td>
                        </tr>
                        <tr>
                            <td className="align-top py-1 text-body text-nowrap fw-bold">
                                Progress :
                            </td>
                            <td className="text-warning fw-semibold ps-3">{props.projectInfo ? props.projectInfo.progress_percentage : 0}%</td>
                        </tr>
                        <tr>
                            <td className="align-top py-1 text-body text-nowrap fw-bold">
                                Status :
                            </td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight" >{props.projectInfo && props.projectInfo.progress_status ? translate(props.lang, props.projectInfo.progress_status) : ''}</td>
                        </tr>
                        <tr>
                            <td className="align-top py-1 text-body text-nowrap fw-bold">
                                Priority :
                            </td>
                            <td className="fw-bold ps-1 py-1 text-body-highlight" >{props.projectInfo && props.projectInfo.priority_level.toString() ? translate(props.lang, getLabel(props.projectInfo.priority_level, "priority")) : ''}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                        icon={faListCheck}
                        className="me-2 text-body-tertiary fs-9"
                    />
                    <h5 className="text-body-emphasis mb-0 me-2">
                        {props.projectInfo ? props.projectInfo.task.length : 0}<span className="text-body fw-normal ms-2">tasks</span>
                    </h5>
                    <Link className="fw-bold fs-9" to="#!">
                        See tasks{' '}
                        <FontAwesomeIcon icon={faChevronRight} className="me-2 fs-10" />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ProjectDetailsSummary;
