import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { Editor } from '@tinymce/tinymce-react';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import { useAppContext } from 'providers/AppProvider';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Task Type', url: '/task-type/overview' },
    { label: 'Edit Task Type ', active: true }
]

const EditTaskType = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { config } = useAppContext();
    const navigate = useNavigate();
    const [content, setContent] = useState('');
    const [taskTypeInfo, setTaskTypeInfo] = useState('');
    const [editable, setEditable] = useState(false);
    let errorObj = {
        name: null,
        description: null
    }
    const [organizationList, setOrganizationList] = useState([]);
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        organization_id: '',
    })

    useEffect(() => {
        getOrganization();
    }, [props.auth.auth.token])

    const getOrganization = () => {
        fetch(api.get_organizations, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data);
                } else if (responseJson.status === 401) {
                    dispatch(logout());
                    navigate('/')
                } else {
                    console.log('error company', responseJson.message)
                    // swal(translate(props.lang, "Error"), responseJson.message, "error");
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_project_category_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setTaskTypeInfo(responseJson.data)
                    let newFormData = { ...formData };
                    newFormData.name = responseJson.data.name;
                    newFormData.description = responseJson.data.description;
                    if (responseJson.data.organization) {
                        newFormData.organization_id = responseJson.data.organization.organization_id;
                    }
                    setFormData(newFormData);
                    setContent(responseJson.data.description)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    useState(() => {
        getInfo();
    }, [props.id]);

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.organization_id) {
            errorMsg.organization_id = "This field is required.";
            check = false;
        }
        if (!content) {
            errorMsg.description = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            let newFormData = { ...formData };
            newFormData.description = content;
            setLoading(true);
            fetch(api.edit_project_category + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Task Type has been updated successfully."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        setEditable(false);
                        getInfo();
                        resetForm();
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setErrors(errorObj);
        setEditable(false);
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Task Type")}</h2>
            <Row className='align-items-center justify-content-end'>
                <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                    {!editable ? (
                        <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
                            <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                        </Button>
                    ) : null}
                </Col>
            </Row>
            <Row className='align-items-center justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task Type Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter task type name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                                readOnly={!editable}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.organization_id ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                        <div className="input-group-select px-3">
                            <Select
                                options={
                                    organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                }
                                placeholder={translate(props.lang, "Select Organization")}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                isSearchable={true}
                                value={organizationList.filter(option => option.organization_id === formData.organization_id).length > 0 ? organizationList.filter(option => option.organization_id === formData.organization_id).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                name="organization_id"
                                onChange={(e) => handleOptionSelected(e, 'organization_id')}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization_id)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Requirement")} <span className="text-danger">*</span></span>
                    <div className={`groupLabel-input w-100 px-3 ${errors.description ? 'errorBorder' : ''}`}>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                            init={{
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            }}
                            initialValue={formData.description}
                            className="w-100"
                            onEditorChange={(content, editor) => {
                                setContent(content);
                            }}
                            disabled={!editable}
                        />
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                    </div>
                </Col>
                {editable ? (
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/task-type/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditTaskType);
