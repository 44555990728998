import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import { Editor } from '@tinymce/tinymce-react';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import Swal from 'sweetalert2';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Job Position', url: '/job-position/overview' },
    { label: 'New Job Position ', active: true }
]

const NewJobPosition = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        name: null,
        scope: null,
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        scope: ''
    })

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!content) {
            errorMsg.scope = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            newFormData.scope = content;
            setLoading(true);
            fetch(api.add_worker_position, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text:  translate(props.lang, "Job Position has been created successfully"),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                       navigate('/job-position/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if(responseJson.tokenExpired){
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if(Array.isArray(responseJson.message)){
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text:  responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text:  responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "New Job Position")}</h2>
            <Row className='align-items-center justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Position Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter job position name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <span className={`fw-semibold fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Scope")} <span className="text-danger">*</span></span>
                    <div className={`groupLabel-input w-100 px-3 ${errors.scope ? 'errorBorder' : ''}`}>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                            init={{
                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                            }}
                            initialValue={formData.scope}
                            className="w-100"
                            onEditorChange={(content, editor) => {
                                setContent(content);
                            }}
                        />
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.scope)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/job-position/overview')}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewJobPosition);
