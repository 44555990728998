import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import PostsReducer from './reducers/PostsReducer';
import { AuthReducer } from './reducers/AuthReducer';
import i18nReducer from './reducers/i18nReducer';
import themeReducer from './reducers/ThemeReducer';
import todoReducers from './reducers/Reducers';
import { thunk } from 'redux-thunk';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
		todoReducers,
    i18n: i18nReducer,
    theme: themeReducer
	
});

export const store = createStore(reducers,  composeEnhancers(middleware));
