import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { translate } from 'utils/translate';
import { api, attachmentUrl } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "providers/AppProvider";
import customSelectDarkStyles from "utils/customSelectDarkStyles";
import Swal from "sweetalert2";
import { getSweelAlertBg, getSweelAlertTextColor } from "utils/function";

const localeMap = {
  en: enLocale,
  cn: zhCNLocale,
};

const buttonTextMap = {
  en: { clear: 'Clear', cancel: 'Cancel', ok: 'OK' },
  cn: { clear: '清除', cancel: '取消', ok: '确定' },
};

let breadcrumbData = [
  { label: 'Setting', url: '#' },
  { label: 'Announcement', url: '/announcement/overview' },
  { label: 'Edit Announcement ', active: true }
]

const typeList = [
  { label: 'all', value: 'all' },
  { label: 'user', value: 'user' },
]

const EditAnnouncement = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { config } = useAppContext();
  const [editable, setEditable] = useState(true);
  const { id } = useParams();
  let errorObj = {
    title: '',
    description: '',
    type: '',
    audience: '',
    release_date: '',
    expired_date: '',
    image: '',
    attachment: ''
  }
  const productPerpage = 10;
  const [locale, setLocale] = useState('en');
  const [errors, setErrors] = useState(errorObj);
  const [loading, setLoading] = useState(false);
  const [announcementInfo, setAnnouncementInfo] = useState(null);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [targetAudience, setTargetAudience] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    type: '',
    audience: [],
    release_date: '',
    expired_date: '',
    image: [],
    attachment: []
  })
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedImage(uploadedImage.concat(acceptedFiles));
      // Call your backend API endpoint to upload files
    },
    accept: {
      'image/*': []
    },
    multiple: true
  })

  const { getRootProps: getAttachmentRootProps, getInputProps: getAttachmentInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(uploadedFiles.concat(acceptedFiles));
      // Call your backend API endpoint to upload files
    },
    multiple: true
  })

  const getInfo = () => {
    setLoading(false);
    fetch(api.get_announcement_details + '/' + id, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.token
      }),
    })
      .then(response => response.json())
      .then(responseJson => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        if (responseJson.status === "success") {
          setAnnouncementInfo(responseJson.data)
          let newFormData = { ...formData };
          newFormData.title = responseJson.data.title;
          newFormData.description = responseJson.data.description;
          newFormData.type = responseJson.data.type;
          newFormData.release_date = responseJson.data.release_date;
          newFormData.attachment = responseJson.data.attachment;
          newFormData.image = responseJson.data.image;
          setUploadedFiles(responseJson.data.attachment)
          setUploadedImage(responseJson.data.image)
          setTargetAudience(responseJson.data?.assigned[0]?.announcement_group?.audience)
          setFormData(newFormData);
        } else {
          if (responseJson.message === 'token expired') {
            dispatch(logout());
            navigate('/');
          } else if (responseJson.tokenExpired) {
            dispatch(logout());
            navigate('/');
          } else {
            console.log('error', responseJson.message)
          }
        }

      }).catch(error => {
        console.error("error", error);
      });
  }

  useState(() => {
    getInfo();
  }, [id]);

  const handleOptionSelected = async (e, type) => {
    const { value } = e;
    const newFormData = { ...formData };
    newFormData[type] = value;
    setFormData(newFormData);
  }

  useEffect(() => {
    setLocale(props.lang.toLowerCase())
  }, [props.lang])

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...formData };
    newFormData[fieldName] = fieldValue;
    setFormData(newFormData);
  }

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const response = await fetch(`${api.get_announcement_groups + "?page=" + page + "&limit=" + productPerpage + '&details=1&isSearch=true&name=' + searchQuery}`, {
        method: 'GET',
        headers: {
          "Accept-Language": props.lang.toLowerCase(),
          "Authorization": "Bearer " + props.auth.auth.token
        }
      });

      const responseJson = await response.json();
      if (responseJson.status !== "success") {
        throw new Error('Failed to fetch: ' + responseJson);
      }

      const options = responseJson.data.data.map(item => ({
        value: item.announcement_group_id,
        label: item.name
      }));
      let totalPage = Math.ceil(responseJson.data.subs.totalAudienceGroup / productPerpage);

      const hasMore = page < totalPage;

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      };
    }
  }

  const submitForm = () => {
    let check = true;
    let errorMsg = { ...errorObj };
    let newFormData = { ...formData };
    if (!formData.title) {
      errorMsg.title = "This field is required.";
      check = false;
    }
    if (!formData.description) {
      errorMsg.description = "This field is required.";
      check = false;
    }
    if (!formData.type) {
      errorMsg.type = "This field is required.";
      check = false;
    }
    if (!formData.expired_date) {
      delete newFormData.expired_date;
    }

    setErrors(errorMsg);
    if (check) {
      setLoading(true);
      if (uploadedImage.length === 0 && uploadedFiles.length === 0) {
        newFormData['image'] = [];
        newFormData['attachment'] = [];
        proceedUpdate(newFormData)
      } else {
        if (uploadedImage === newFormData.image && uploadedFiles === newFormData.attachment) {
          proceedUpdate(newFormData)
        } else {
          //check whether got new upload item
          let differentItemImage = uploadedImage.filter(f => !formData.image.includes(f));
          let differentItemFile = uploadedFiles.filter(f => !formData.attachment.includes(f));
          if (differentItemImage.length > 0 || differentItemFile.length > 0) {
            let uploadPromises = [];
            let popUpMsg = [];
            let imageArr = formData.image;
            let fileArr = formData.attachment;

            if (differentItemImage.length > 0) {
              differentItemImage.map((item) => {
                let uploadPromise = new Promise((resolve, reject) => {

                  let form = new FormData();
                  form.append("image", item);
                  fetch(api.upload_image + '/announcement', {
                    method: 'POST',
                    headers: {
                      "Accept-Language": props.lang.toLowerCase(),
                      "Authorization": "Bearer " + props.auth.auth.token
                    },
                    body: form,
                  }).then(response => response.json())
                    .then(responseJson => {
                      if (responseJson.status === 'success') {
                        resolve('done')
                        imageArr.push(responseJson.data[0])
                      } else {
                        reject('reject');
                        popUpMsg.push(item + ' upload fail.');
                      }
                    })
                    .catch(error => {
                      console.error(error);
                    });

                })
                uploadPromises.push(uploadPromise);
              })
            }
            if (differentItemFile.length > 0) {
              differentItemFile.map((item) => {
                let uploadPromise = new Promise((resolve, reject) => {

                  let form = new FormData();
                  form.append("image", item);
                  fetch(api.upload_image + '/announcement', {
                    method: 'POST',
                    headers: {
                      "Accept-Language": props.lang.toLowerCase(),
                      "Authorization": "Bearer " + props.auth.auth.token
                    },
                    body: form,
                  }).then(response => response.json())
                    .then(responseJson => {
                      if (responseJson.status === 'success') {
                        resolve('done')
                        fileArr.push(responseJson.data[0])
                      } else {
                        reject('reject');
                        popUpMsg.push(item + ' upload fail.');
                      }
                    })
                    .catch(error => {
                      console.error(error);
                    });

                })
                uploadPromises.push(uploadPromise);
              })
            }

            Promise.all(uploadPromises).then(() => {
              newFormData.attachment = fileArr;
              newFormData.image = imageArr;
              proceedUpdate(newFormData)
            }, (error) => {
              Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: popUpMsg.join('\n'),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
            })
          } else {
            newFormData.attachment = uploadedFiles;
            newFormData.image = uploadedImage;
            proceedUpdate(newFormData)
          }
        }
      }

    }
  }

  const proceedUpdate = (data) => {
    fetch(api.edit_announcement + '/' + id, {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        "Accept-Language": props.lang.toLowerCase(),
        "Authorization": "Bearer " + props.auth.auth.token
      }),
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(responseJson => {
        setLoading(false)
        if (responseJson.status === 'success') {
          Swal.fire({
            icon: "success",
            title: translate(props.lang, 'Success'),
            text: translate(props.lang, "Announcement updated successful"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
          });
          getInfo();
          // setEditable(false)
        } else {
          if (responseJson.message === 'token expired') {
            dispatch(logout());
            navigate('/');
          } else if (responseJson.tokenExpired) {
            dispatch(logout());
            navigate('/');
          } else {
            if (Array.isArray(responseJson.message)) {
              Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: responseJson.message.join('\n'),
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
            } else {
              Swal.fire({
                icon: "error",
                title: translate(props.lang, 'Error'),
                text: responseJson.message,
                background: getSweelAlertBg(), // Dark background color
                color: getSweelAlertTextColor(), // Text color
              });
            }
          }
        }
      }).catch(error => {
        console.error("error", error);
      });
  }

  const resetForm = () => {
    getInfo();
    setErrors(errorObj);
    // setEditable(false)
  }


  function handleChange(value) {
    let newFormData = { ...formData };
    newFormData.audience = [value];
    setFormData(newFormData);
  }

  const onChangeCalendar = (e, type) => {
    let newFormData = { ...formData };
    if (e) {
      newFormData[type] = moment(e).format('YYYY-MM-DD');
    } else {
      newFormData[type] = ""
    }
    setFormData(newFormData);
  }

  const removePreviewFile = (index) => {
    let newUploadedAttachment = [...uploadedFiles];
    newUploadedAttachment.splice(index, 1);
    setUploadedFiles(newUploadedAttachment);
  }

  const removePreviewImg = (index) => {
    let newUploadedAttachment = [...uploadedImage];
    newUploadedAttachment.splice(index, 1);
    setUploadedImage(newUploadedAttachment);
  }

  return (
    <div>
      <PageBreadcrumb items={breadcrumbData} />
      <h2 className="mb-4">{translate(props.lang, "Edit Announcement")}</h2>
      {/* <Row className='align-items-center justify-content-end'>
        <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
          {!editable ? (
            <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
              <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
            </Button>
          ) : null}
        </Col>
      </Row> */}
      <Row className='align-items-start justify-content-center'>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.title ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Title")} <span className="text-danger">*</span></span>
            <div className={`input-group`}>
              <input
                type={'text'}
                name="title"
                placeholder={translate(props.lang, "Enter Title")}
                className="form-control input-transparent py-1 px-3"
                value={formData.title}
                autoComplete="off"
                readOnly={!editable}
                onChange={e => handleAddFormChange(e)}
              />
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.title)}</span>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
            <div className={`input-group`}>
              <Form.Control
                as="textarea"
                name="description"
                className="form-control input-transparent py-1 px-3"
                rows={3}
                value={formData.description}
                placeholder={translate(props.lang, "Enter Description")}
                readOnly={!editable}
                onChange={e => handleAddFormChange(e)}
              />
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.type ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Audience Type")} <span className="text-danger">*</span></span>
            <div className="input-group-select px-3">
              <Select
                options={
                  typeList.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i }))
                }
                placeholder={translate(props.lang, "Select Audience Type")}
                className="input-transparent w-100 text-capitalize"
                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                isSearchable={true}
                isDisabled
                name="type"
                value={
                  formData.type ? (
                    typeList.filter(item => item.value == formData.type).length > 0 ?
                      (typeList.filter(item => item.value == formData.type).map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })))
                      : null
                  ) : null
                }
                onChange={(e) => handleOptionSelected(e, 'type')}
              />
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.type)}</span>
          </div>
        </Col>
        {formData.type === 'user' ? (
          <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
            <div className={`groupLabel-input py-1 ${errors.audience ? 'errorBorder' : ''}`}>
              <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Audience Group")} <span className="text-danger">*</span></span>
              <div className='px-3'>
                <Table>
                  <tbody>
                    {targetAudience.map((item, i) => (
                      <tr key={i}>
                        <td className="fs-9 font-weight-400">{item?.worker?.name} ({item?.worker?.worker_id})</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div>
              <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.audience)}</span>
            </div>
          </Col>
        ) : null}
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.release_date ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Publish Date")} <span className="text-danger">*</span></span>
            <div className={`input-group`}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                <DatePicker
                  autoOk
                  label=""
                  clearable
                  format="yyyy-MM-dd"
                  className="form-control input-transparent py-1 px-3"
                  placeholder={translate(props.lang, "Enter Publish Date")}
                  value={formData.release_date ? formData.release_date : null}
                  onChange={(date) => onChangeCalendar(date, 'release_date')}
                  clearLabel={buttonTextMap[locale].clear}
                  cancelLabel={buttonTextMap[locale].cancel}
                  okLabel={buttonTextMap[locale].ok}
                  views={['year', 'month', 'date']}
                  disabled={!editable}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div>
            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.release_date)}</span>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.image ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Image")}</span>
            <div className="px-3">
              <div {...getRootProps()} className={`dropzone ${uploadedImage.length === 0 ? 'mb-3' : ''}`}>
                <input {...getInputProps()} />
                <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                  {translate(props.lang, "Drag your files here")}{' '}
                  <span className="text-body-secondary">or </span>
                  <Button variant="link" className="p-0">
                    {translate(props.lang, "Browse from device")}
                  </Button>
                </div>
              </div>
              {uploadedImage.map((file, index) => (
                <div key={index} className={'border-bottom d-flex align-items-center justify-content-between py-3'}>
                  <div className='btn-icon btn-icon-lg rounded-3 flex-column me-2 position-relative text-body-quaternary'>
                    {
                      <img
                        src={file?.webp ? attachmentUrl + file?.webp : URL.createObjectURL(file)}
                        alt={'logo'}
                        className="w-100 rounded"
                      />
                    }
                  </div>
                  <button className="btn p-0" onClick={() => removePreviewImg(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                  </button>
                </div>
              ))}
              <div>
                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.image)}</span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
          <div className={`groupLabel-input py-1 ${errors.attachment ? 'errorBorder' : ''}`}>
            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "File")}</span>
            <div className="px-3">
              <div {...getAttachmentRootProps()} className={`dropzone ${uploadedFiles.length === 0 ? 'mb-3' : ''}`}>
                <input {...getAttachmentInputProps()} />
                <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                  {translate(props.lang, "Drag your files here")}{' '}
                  <span className="text-body-secondary">or </span>
                  <Button variant="link" className="p-0">
                    {translate(props.lang, "Browse from device")}
                  </Button>
                </div>
              </div>
              {uploadedFiles.map((file, index) => (
                <div key={index} className={`${index !== uploadedFiles.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                  <span className="fs-9 font-weight-400">{index + 1}. {attachmentUrl + file.path}</span>
                  <button className="btn p-0" onClick={() => removePreviewFile(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                  </button>
                </div>
              ))}
              <div>
                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.attachment)}</span>
              </div>
            </div>
          </div>
        </Col>
        {editable ? (
          <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
            <div className="d-flex justify-content-start gap-3">
              <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Update")}
              </Button>
              <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => resetForm()}>
                {translate(props.lang, "Cancel")}
              </Button>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(EditAnnouncement);
