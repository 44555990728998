import Avatar from "components/base/Avatar";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import DataLoading from "page/Components/DataLoading";
import { useEffect, useState, useRef } from "react";
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { logout } from "store/actions/AuthActions";
import { api } from "utils/api";
import { translate } from "utils/translate";

let breadcrumbData = [
    { label: 'Announcement', url: '#' },
    { label: 'List', active: true }
]

const UserAnnouncementList = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [productPerpage, setProductPerpage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const timerRef = useRef(null);

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getList();
            setFirstLoad(false)
        }
    }, []);

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_announcements + "?page=" + page + "&limit=" + productPerpage + '&details=1&sort_by=release_date&order_by=DESC&beforeReleased=true';

            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setFirstLoad(false);
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        let totalPage = Math.ceil(responseJson.data.subs.totalAnnouncement / productPerpage);
                        setPageCount(totalPage);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const viewAnnouncement = (item) => {
        if (item.viewer.filter(item => item.worker.worker_id === props.auth.auth.worker_id).length > 0) {
            navigate('/user-announcement/' + item.announcement_id);
        } else {
            //read api
            fetch(api.read_announcement + `/${item.announcement_id}`, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        getUnreadNewsCount();
                        navigate('/user-announcement/' + item.announcement_id);
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            navigate('/user-announcement/' + item.announcement_id);
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const getUnreadNewsCount = () => {
        fetch(api.get_announcements + '?counterOnly=1&details=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    dispatch({
                        type: 'UPDATE_UNREAD_NEWS_COUNT',
                        unreadNewsCount: responseJson.data.totalUnread
                    });
                } else if (responseJson.status === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    console.log('error announcement', responseJson.message)
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div>
                <PageBreadcrumb items={breadcrumbData} />
                <div className="mb-9">
                    <h2 className="mb-5">{translate(props.lang, "Announcement")}</h2>
                    <Row className="align-items-center px-0 mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                            {
                                loading ? (
                                    <DataLoading theme='light' />
                                ) : (
                                    result.length > 0 ?
                                        (result.map((item, i) => (
                                            <div key={i} className="news-container px-3 py-3 mb-3 cursor-pointer" onClick={() => viewAnnouncement(item)}>
                                                <Row className="px-0 mx-0 align-items-center">
                                                    <Col lg={8} md={8} sm={6} xs={6}>
                                                        <div className="d-flex align-items-center">
                                                            <span className="fs-7 fw-bold">{item.title} </span>{item.viewer.filter(item => item.worker.worker_id === props.auth.auth.worker_id).length > 0 ? null : <div className="unread-dot ms-2"></div>}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={4} sm={6} xs={6} className="text-end">
                                                        <span className="fs-8 fw-bold">{item.release_date}</span>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <hr />
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                        <div className="news_content_summary py-1">
                                                            <div className="fs-9 fw-normal" dangerouslySetInnerHTML={{ __html: item.description.substring(0, 500) }}></div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={8} md={8} sm={6} xs={6}>
                                                        <div className="d-flex align-items-center justify-content-start w-100">
                                                            {item.viewer.length > 0 ? (
                                                                <div className="avatar-group avatar-group-dense text-center">
                                                                    <div className="d-flex flex-column gap-4 me-2">
                                                                        <Avatar.Group>
                                                                            {item.viewer.slice(0, 3).map((person, j) => (
                                                                                <div className="assignee">
                                                                                    <Avatar size="m" variant="name">
                                                                                        <span>{person.worker.name.substring(0, 1)}</span>
                                                                                    </Avatar>
                                                                                </div>
                                                                            ))}
                                                                        </Avatar.Group>
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                            <span className="fs-9 fw-normal">{item.viewer.length} {translate(props.lang, "view this announcement")}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={4} sm={6} xs={6} className="text-end">
                                                        <div className="d-flex align-items-center justify-content-end w-100">
                                                            <span className="fs-9 fw-normal me-3">{translate(props.lang, "Post by")}</span>
                                                            <div className="assignee">
                                                                <Avatar size="m" variant="name">
                                                                    <span>{item.speaker.name.substring(0, 1)}</span>
                                                                </Avatar>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))) : (
                                            <div className="news-container px-3 py-3 mb-3 cursor-pointer">
                                                <div className="d-flex align-items-center text-center w-100">
                                                    <span className="fs-8 fw-normal">{translate(props.lang, "No announcement")}</span>
                                                </div>
                                            </div>
                                        )
                                )
                            }

                            {result.length > 0 ? (
                                <div className="my-4">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"pagination fw-normal fs-9 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink text-white"}
                                        nextLinkClassName={"pagination__nextlink text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(UserAnnouncementList);