import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { useAppContext } from 'providers/AppProvider';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Department', url: '/department/overview' },
    { label: 'Edit Department', active: true }
]

const EditDepartment = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    let errorObj = {
        name: null,
        organizationId: null,
        code: null,
        description: null,
    }
    const { config } = useAppContext();
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [departmentInfo, setDepartmentInfo] = useState(null)
    const [editable, setEditable] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        organizationId: '',
        code: '',
        description: '',
    })
    const organizationRef = useRef(null);
    const infoRef = useRef(null);

    useEffect(() => {
        getOrganization();
        getInfo();
    }, [])

    const getOrganization = () => {
        clearTimeout(organizationRef.current);
        organizationRef.current = setTimeout(() => {
            fetch(api.get_organizations, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        setOrganizationList(responseJson.data.data);
                    } else if (responseJson.status === 401) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error company', responseJson.message)
                    }

                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const getInfo = () => {
        clearTimeout(infoRef.current);
        infoRef.current = setTimeout(() => {
            if (id) {
                fetch(api.get_department_details + '/' + id, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        if (responseJson.status === "success") {
                            setDepartmentInfo(responseJson.data)
                            let newFormData = { ...formData };
                            newFormData.name = responseJson.data.name;
                            newFormData.organizationId = responseJson.data.organization.organization_id;
                            newFormData.code = responseJson.data.code;
                            newFormData.description = responseJson.data.description;
                            setFormData(newFormData);
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                console.log('error', responseJson.message)
                            }
                        }

                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        }, 1000);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.organizationId) {
            errorMsg.organizationId = "This field is required.";
            check = false;
        }
        if (!formData.code) {
            errorMsg.code = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.edit_department + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Department updated successful"),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        getInfo();
                        setEditable(false)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        getInfo();
        setEditable(false);
        setErrors(errorObj);
    }

    const toggleStatus = (status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.edit_department_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Department update successful"),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Department")}</h2>
            <Row className='align-items-center justify-content-end'>
                <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                    {!editable ? (
                        <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
                            <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                        </Button>
                    ) : null}
                    {departmentInfo ? (
                        departmentInfo.status === 'active' ? (
                            <Button variant="outline-danger" onClick={() => toggleStatus('active')}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Disable")}</span>
                            </Button>
                        ) : (
                            <Button variant="outline-success" onClick={() => toggleStatus('inactive')}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Enable")}</span>
                            </Button>
                        )
                    ) : null}

                </Col>
            </Row>
            <Row className='align-items-center justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                        <div className="input-group-select px-3">
                            <Select
                                options={
                                    organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                }
                                placeholder={translate(props.lang, "Select Organization")}
                                className="input-transparent w-100 text-capitalize"
                                styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                isSearchable={true}
                                isDisabled={!editable}
                                value={organizationList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                name="organizationId"
                                onChange={(e) => handleOptionSelected(e, 'organizationId')}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Department Name")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="name"
                                placeholder={translate(props.lang, "Enter Department Name")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.name}
                                autoComplete="off"
                                readOnly={!editable}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.code ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Department Code")} <span className="text-danger">*</span></span>
                        <div className={`input-group`}>
                            <input
                                type={'text'}
                                name="code"
                                placeholder={translate(props.lang, "Enter Department Code")}
                                className="form-control input-transparent py-1 px-3"
                                value={formData.code}
                                autoComplete="off"
                                readOnly={!editable}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.code)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                        <div className={`input-group`}>
                            <Form.Control
                                as="textarea"
                                name="description"
                                className="form-control input-transparent py-1 px-3"
                                rows={3}
                                value={formData.description}
                                readOnly={!editable}
                                placeholder={translate(props.lang, "Enter Description")}
                                onChange={e => handleAddFormChange(e)}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                    </div>
                </Col>
                {editable ? (
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => resetForm()}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditDepartment);
