const EN = {
    "Sign In": "Sign In",
    "Get access to your account": "Get access to your account",
    "Email address": "Email address",
    "Password": "Password",
    "Remember me": "Remember me",
    "Forgot Password?": "Forgot Password?",
    "Create an account": "Create an account",
    "inprogress": "In Progress",

    "This field is required.": "This field is required.",
    "Dashbard": "Dashbard",
    "Project": "Project",
    "Task": "Task",
    "Timesheet": "Timesheet",
    "Customer": "Customer",
    "Payment": "Payment",
    "Quotaion": "Quotaion",
    "Invoice": "Invoice",
    "Setting": "Setting",
    "Organization": "Organization",
    "Department": "Department",
    "Job Position": "Job Position",
    "Ranking": "Ranking",
    "User": "User",
    "Task Type": "Task Type",
    "User Group": "User Group",
    "Announcement": "Announcement",
    "No.": "No.",
    "Department Name": "Department Name",
    "Department Code": "Department Code",
    "Items Per Page": "Items Per Page",
    "Edit Department": "Edit Department",
     "Department updated successful":  "Department updated successful",
    "Are you sure you want to deactivate this data?": "Are you sure you want to deactivate this data?",
    "Are you sure you want to activate this data?": "Are you sure you want to activate this data?",
    "Confirmation": "Confirmation",
    "Confirm": "Confirm",
    "Department update successful": "Department update successful",
    "Edit": "Edit",
    "Disable": "Disable",
    "Enable": "Enable",
    "Select Organization": 'Select Organization',
    "Enter Department Name": "Enter Department Name",
    "Enter Department Code": "Enter Department Code",
    "Description": 'Description',
    "Enter Description": "Enter Description",
    "Submit": "Submit",
    "Cancel": "Cancel",




}

export default EN;