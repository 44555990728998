import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import DateFnsUtils from "@date-io/date-fns";
import { Col, Row, Spinner } from 'react-bootstrap';
import { translate } from 'utils/translate';
import { useNavigate } from 'react-router-dom';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Select from "react-select";
import countries from 'utils/countries';
import customSelectStyles from 'utils/customSelectStyles';
import moment from 'moment';
import { connect } from 'react-redux';
import { api } from 'utils/api';
import phoneCodeList from "../../utils/phoneCodeList";
import { useAppContext } from 'providers/AppProvider';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import Swal from 'sweetalert2';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'User', url: '/user/overview' },
    { label: 'New User ', active: true }
]

const genderList = [
    { value: "Female", label: 'Female' },
    { value: 'Male', label: 'Male' },
]

const jobTypesList = [
    { value: 'Full-time', label: 'Full-time' },
    { value: 'Part-time', label: 'Part-time' },
    { value: 'Contract', label: 'Contract' },
    { value: 'Internship', label: 'Internship' },
    { value: 'Temporary', label: 'Temporary' },
]

const NewUser = (props) => {
    let errorObj = {
        "name": null,
        "role": null,
        "position": null,
        "organizationId": null,
        "departmentId": null,
        "username": null,
        "password": null,
        "employeeNumber": null,
        "email": null,
        "identity_card": null,
        "country_code": null,
        "phone": null,
        "epf_number": null,
        "socso_number": null,
        "payment_frequency": null,
        "address": {
            "address1": null,
            "address2": null,
            "town": null,
            "postcode": null,
            "state": null,
            "country": null
        },
        "residency": null,
        "residency_type": null,
        "bank": {
            "bank_name": null,
            "account_name": null,
            "account_number": null
        },
        "job_type": null,
        "contract_start": null,
        "contract_period": 0,
        "contract_end": null,
        "date_of_birth": null,
        "salary": null,
        "working_day": null,
        "working_hour": null,
        "gender": null,
        "nationality": null,
        "working_day": null,
        "working_hour": null,
    }
    const mandatoryField = {
        name: '',
        role: '',
        organizationId: '',
        departmentId: '',
        username: '',
        password: '',
        position: '',
        job_type: '',
        identity_card: '',
        employeeNumber: '',
        salary: '',
        working_day: '',
        working_hour: '',
    }
    const navigate = useNavigate();
    const { config } = useAppContext();
    const timerRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [workerRoleList, setWorkerRoleList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [filteredDepartmentList, setFilteredDepartmentList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [errorAddress, setErrorAddress] = useState({ name: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '' });
    const [address, setAddress] = useState([{ name: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '' }]);
    const [formData, setFormData] = useState({
        "name": "",
        "role": "",
        "position": "",
        "organizationId": "",
        "departmentId": "",
        "username": "",
        "password": "",
        "employeeNumber": "",
        "email": "",
        "identity_card": "",
        "country_code": "",
        "phone": "",
        "epf_number": "",
        "socso_number": "",
        "payment_frequency": 0,
        "address": {
            "address1": "",
            "address2": "",
            "town": "",
            "postcode": "",
            "state": "",
            "country": ""
        },
        "residency": "",
        "residency_type": "",
        "bank": {
            "bank_name": "",
            "account_name": "",
            "account_number": ""
        },
        "job_type": "",
        "contract_start": "",
        "contract_period": 0,
        "contract_end": "",
        "date_of_birth": "",
        "salary": 0,
        "working_day": 22,
        "working_hour": 8,
        "gender": "",
        "nationality": ""
    })

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        if(fieldName === 'username'){
            newFormData[fieldName] = fieldValue.trim();
        } else {
            newFormData[fieldName] = fieldValue;
        }
        
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        if (type === 'organizationId') {
            if (!value) {
                newFormData.departmentId = ''
            } else {
                let filterList = departmentList.filter(item => item.organization.organization_id === value);
                setFilteredDepartmentList(filterList);
            }
        }
        setFormData(newFormData);
    }

    const handleAddFormChangeAddress = (e) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        let newFormData = { ...formData };
        newFormData.address[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const onChangeCalendar = (e, type) => {
        let newFormData = { ...formData };
        if (e) {
            newFormData[type] = moment(e[0]).format('YYYY-MM-DD');
        } else {
            newFormData[type] = ""
        }
        setFormData(newFormData);
    }

    const handleOptionSelectedAddress = async (e) => {
        const { value } = e;
        let newFormData = { ...formData };
        newFormData.address['country'] = value;
        setFormData(newFormData);
    }

    const handleAddFormChangeBank = (e, type) => {
        let newFormData = { ...formData };
        newFormData.bank[type] = e.target.value
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        let regIc = /^[a-zA-Z0-9]+$/;
        let regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        Object.keys(mandatoryField).map((item, i) => {
            if (!formData[item]) {
                errorMsg[item] = "This field is required.";
                check = false;
            }
        })
        if (formData.username) {
            if (formData.username.length < 3) {
                errorMsg.password = "Username must at least 3 characters";
                check = false;
            }
        }
        if (formData.password) {
            if (formData.password.length < 6) {
                errorMsg.password = "Password must at least 6 characters";
                check = false;
            }
        }
        if (formData.email) {
            if (!regEmail.test(formData.email)) {
                errorMsg.email = "Invalid email address";
                check = false;
            }
        }
        if (formData.identity_card) {
            if (!regIc.test(formData.identity_card)) {
                errorMsg.identity_card = "Only allow alphanumeric";
                check = false;
            }
        }
        newFormData.salary = Number(newFormData.salary);
        for (const key in newFormData) {
            if (newFormData[key] === '') {
                delete newFormData[key];
            }
        }

        newFormData['working_day'] = parseInt(formData.working_day);
        newFormData['working_hour'] = parseInt(formData.working_hour);

        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.add_worker, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "User has been created successfully."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        navigate('/user/overview');
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (responseJson.data && typeof responseJson.data.error === 'object') {
                                let apiErrorMsg = { ...errorObj };
                                let popupMessage = [];
                                Object.keys(responseJson.data.error).map((key) => {
                                    apiErrorMsg[key] = responseJson.data.error[key];
                                    popupMessage.push(responseJson.data.error[key])
                                })
                                setErrors(apiErrorMsg);
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: popupMessage.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                            // swal(translate(props.lang, "Error"), translate(props.lang, "Something went wrong. Please try again."), "error");
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    useEffect(() => {
        getOrganization();
        getUserRole();
        getDepartment();
        getJobPosition();
    }, [props.auth.auth.token])

    const getOrganization = () => {
        fetch(api.get_organizations + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getUserRole = () => {
        fetch(api.get_worker_roles + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setWorkerRoleList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getDepartment = () => {
        fetch(api.get_departments + '?details=1&isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setDepartmentList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getJobPosition = () => {
        fetch(api.get_worker_positions + '?isOptions=1', {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setPositionList(responseJson.data.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "New User")}</h2>
            <Row className='align-items-start justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                    <Row className="mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                            <span className="fs-7 fw-semibold">{translate(props.lang, "Login Account Details")}</span>
                            <hr />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.username ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Username")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="username"
                                        placeholder={translate(props.lang, "Enter Username")}
                                        className={`form-control input-transparent py-1 px-3`}
                                        value={formData.username}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.username)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.password ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Password")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type={`${showPassword ? 'text' : 'password'}`}
                                        name="password"
                                        placeholder={translate(props.lang, "Enter Password")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.password}
                                        autoComplete="new-password"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                    <div className='mx-2 d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowPassword(!showPassword)}>
                                        <faEye />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.password)}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                            <span className="fs-7 fw-semibold">Personal Information</span>
                            <hr />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Full Name as per IC")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="name"
                                        placeholder={translate(props.lang, "Enter Full Name")}
                                        className={`form-control input-transparent py-1 px-3`}
                                        value={formData.name}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "IC Number")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="identity_card"
                                        placeholder={translate(props.lang, "Enter IC Number")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.identity_card}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.identity_card)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <Row className="px-0 mx-0">
                                <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                    <div className={`groupLabel-input py-1 ${errors.country_code ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country Code")}</span>
                                        <div className="input-group-select px-3">
                                            <Select
                                                options={
                                                    phoneCodeList.length > 0 ? phoneCodeList.map((item, i) => ({ key: i, value: item.dial_code, label: item.name + " (" + item.dial_code + ")" })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                }
                                                placeholder={translate(props.lang, "Select Phone Code")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                value={
                                                    formData.country_code ? (
                                                        phoneCodeList.filter(singleItem => singleItem.dial_code == formData.country_code).length > 0 ?
                                                            (phoneCodeList.filter(singleItem => singleItem.dial_code == formData.country_code).map((item, i) => ({ value: item.dial_code, label: item.name + " (" + item.dial_code + ")", key: i })))
                                                            : null
                                                    ) : null
                                                }
                                                name="country_code"
                                                onChange={(e) => handleOptionSelected(e, 'country_code')}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                    <div className={`groupLabel-input py-1 ${errors.phone ? 'errorBorder' : ''}`}>
                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Contact Number")}</span>
                                        <div className={`input-group`}>
                                            <input
                                                type='text'
                                                name="phone"
                                                placeholder={translate(props.lang, "Enter Contact Number")}
                                                className={`form-control input-transparent py-1 px-3`}
                                                value={formData.phone}
                                                autoComplete="off"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.phone)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.phone)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.email ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="email"
                                        placeholder={translate(props.lang, "Enter Email Address")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.email}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.email)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.email ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Date Of Birth")}</span>
                                <div className={`input-group`}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className="w-100">
                                        <DatePicker
                                            autoOk
                                            label=""
                                            clearable
                                            views={['year', 'month', 'date']}
                                            readOnly={false}
                                            placeholder={translate(props.lang, "Select Date of Birth")}
                                            format="dd/MM/yyyy"
                                            className={`form-control date input-transparent py-1 px-3 w-100`}
                                            value={formData.date_of_birth ? new Date(formData.date_of_birth) : null}
                                            onChange={(e) => onChangeCalendar(e, 'date_of_birth')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.date_of_birth)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.gender ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Gender")}</span>
                                <div className="input-group-select px-3">
                                    <Select
                                        options={
                                            genderList.length > 0 ? genderList.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                        }
                                        placeholder={translate(props.lang, "Select Gender")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={
                                            formData.gender ? (
                                                genderList.filter(singleItem => singleItem.value == formData.gender).length > 0 ?
                                                    (genderList.filter(singleItem => singleItem.value == formData.gender).map((item, i) => ({ value: item.value, label: item.label, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        name="gender"
                                        onChange={(e) => handleOptionSelected(e, 'gender')}
                                    />
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.gender)}</span>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.nationality ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Nationality")}</span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: item.name.toLowerCase() })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                        }
                                        placeholder={translate(props.lang, "Select Country")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={
                                            formData.nationality ? (
                                                countries.filter(singleItem => singleItem.name == formData.nationality.toUpperCase()).length > 0 ?
                                                    (countries.filter(singleItem => singleItem.name == formData.nationality.toUpperCase()).map((item, i) => ({ value: item.name, label: item.name.toLowerCase(), key: i })))
                                                    : null
                                            ) : null
                                        }
                                        name="nationality"
                                        onChange={(e) => handleOptionSelected(e, 'nationality')}
                                    />
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.nationality)}</span>
                                </div>
                            </div>
                        </Col>
                        <hr />
                        {/* address */}
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
                            <div className={`groupLabel-input py-1 ${errors.address1 ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="address1"
                                        placeholder={translate(props.lang, "Enter Street Address Line 1")}
                                        className={`form-control input-transparent py-1 px-3 `}
                                        value={formData.address.address1}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeAddress(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.address1)}</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.address2 ? 'errorBorder' : ''}`}>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="address2"
                                        placeholder={translate(props.lang, "Enter Street Address Line 2")}
                                        className={`form-control input-transparent py-3 px-3 `}
                                        value={formData.address.address2}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeAddress(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.address2)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.postcode ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Postcode")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="postcode"
                                        placeholder={translate(props.lang, "Postcode")}
                                        className={`form-control input-transparent py-1 px-3 `}
                                        value={formData.address.postcode}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeAddress(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.postcode)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.town ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "City")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="town"
                                        placeholder={translate(props.lang, "City")}
                                        className={`form-control input-transparent py-1 px-3 `}
                                        value={formData.address.town}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeAddress(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.town)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.state ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "State")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="state"
                                        placeholder={translate(props.lang, "State")}
                                        className={`form-control input-transparent py-1 px-3 `}
                                        value={formData.address.state}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeAddress(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.state)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                            <div className={`groupLabel-input py-1 ${errors.country ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country")}</span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: item.name.toLowerCase() })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                        }
                                        placeholder={translate(props.lang, "Select Country")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={
                                            formData.address.country ? (
                                                countries.filter(singleItem => singleItem.name == formData.address.country.toUpperCase()).length > 0 ?
                                                    (countries.filter(singleItem => singleItem.name == formData.address.country.toUpperCase()).map((item, i) => ({ value: item.name, label: item.name.toLowerCase(), key: i })))
                                                    : null
                                            ) : null
                                        }
                                        name="country"
                                        onChange={(e) => handleOptionSelectedAddress(e)}
                                    />
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.country)}</span>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.residency ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Residency")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="residency"
                                        placeholder={translate(props.lang, "Residency")}
                                        className={`form-control input-transparent py-1 px-3 `}
                                        value={formData.residency}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.residency)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.residency_type ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Residency Type")}</span>
                                <div className={`input-group px-3`}>
                                    <input
                                        type='text'
                                        name="residency_type"
                                        placeholder={translate(props.lang, "Residency Type")}
                                        className={`form-control input-transparent py-1 `}
                                        value={formData.residency_type}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.residency_type)}</span>
                            </div>
                        </Col>
                        {/* end of address  */}
                    </Row>

                    <Row className="px-0 mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                            <span className="fs-7 fw-semibold">Professional Details</span>
                            <hr />
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.employeeNumber ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Employee Number")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="employeeNumber"
                                        placeholder={translate(props.lang, "Enter Employee Number")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.employeeNumber}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.employeeNumber)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.employeeNumber ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization")} <span className="text-danger">*</span></span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            organizationList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                        }
                                        placeholder={translate(props.lang, "Select Organization")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        name="organizationId"
                                        value={
                                            formData.organizationId ? (
                                                organizationList.filter(item => item.organization_id == formData.organizationId).length > 0 ?
                                                    (organizationList.filter(item => item.organization_id == formData.organizationId).map((item, i) => ({ value: item.organization_id, label: item.name, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organization)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.departmentId ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Department")} <span className="text-danger">*</span></span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            filteredDepartmentList.map((item, i) => ({ key: i, value: item.department_id, label: item.name }))
                                        }
                                        placeholder={translate(props.lang, "Select Department")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        name="departmentId"
                                        value={
                                            formData.departmentId ? (
                                                filteredDepartmentList.filter(item => item.department_id == formData.departmentId).length > 0 ?
                                                    (filteredDepartmentList.filter(item => item.department_id == formData.departmentId).map((item, i) => ({ value: item.department_id, label: item.name, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'departmentId')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.departmentId)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.position ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Position")} <span className="text-danger">*</span></span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            positionList.map((item, i) => ({ key: i, value: item.position_id, label: item.name }))
                                        }
                                        placeholder={translate(props.lang, "Select Job Position")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        name="position"
                                        value={
                                            formData.position ? (
                                                positionList.filter(item => item.position_id == formData.position).length > 0 ?
                                                    (positionList.filter(item => item.position_id == formData.position).map((item, i) => ({ value: item.position_id, label: item.name, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'position')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.position)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.role ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Ranking")} <span className="text-danger">*</span></span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            workerRoleList.map((item, i) => ({ key: i, value: item.role_id, label: item.name }))
                                        }
                                        placeholder={translate(props.lang, "Select Ranking")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        isSearchable={true}
                                        name="role"
                                        value={
                                            formData.role ? (
                                                workerRoleList.filter(item => item.role_id == formData.role).length > 0 ?
                                                    (workerRoleList.filter(item => item.role_id == formData.role).map((item, i) => ({ value: item.role_id, label: item.name, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        onChange={(e) => handleOptionSelected(e, 'role')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.role)}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="px-0 mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                            <span className="fs-7 fw-semibold">Payroll Details</span>
                            <hr />
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.job_type ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Job Types")} <span className="text-danger">*</span></span>
                                <div className={`input-group-select px-3`}>
                                    <Select
                                        options={
                                            jobTypesList.length > 0 ? jobTypesList.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                        }
                                        placeholder={translate(props.lang, "Select Job Type")}
                                        className="input-transparent w-100 text-capitalize"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={
                                            formData.job_type ? (
                                                jobTypesList.filter(singleItem => singleItem.value == formData.job_type).length > 0 ?
                                                    (jobTypesList.filter(singleItem => singleItem.value == formData.job_type).map((item, i) => ({ value: item.value, label: item.label, key: i })))
                                                    : null
                                            ) : null
                                        }
                                        name="nationality"
                                        onChange={(e) => handleOptionSelected(e, 'job_type')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.job_type)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.contract_start ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Start Date")}</span>
                                <div className={`input-group`}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            label=""
                                            clearable
                                            readOnly={false}
                                            format="dd/MM/yyyy"
                                            views={['year', 'month', 'date']}
                                            placeholder={translate(props.lang, "Select Start Date")}
                                            className={`form-control date input-transparent py-1 px-3`}
                                            value={formData.contract_start ? new Date(formData.contract_start) : null}
                                            onChange={(e) => onChangeCalendar(e, 'contract_start')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.contract_start)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.contract_end ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "End Date")}</span>
                                <div className={`input-group`}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            label=""
                                            clearable
                                            readOnly={false}
                                            format="dd/MM/yyyy"
                                            views={['year', 'month', 'date']}
                                            placeholder={translate(props.lang, "Select End Date")}
                                            className={`form-control date input-transparent py-1 px-3`}
                                            value={formData.contract_end ? new Date(formData.contract_end) : null}
                                            onChange={(e) => onChangeCalendar(e, 'contract_end')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.contract_end)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.salary ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Salary")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="salary"
                                        placeholder={translate(props.lang, "Enter salary")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.salary}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.salary)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.working_day ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Working day")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="working_day"
                                        placeholder={translate(props.lang, "Enter working day")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.working_day}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.working_day)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.working_hour ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Working hour")} <span className="text-danger">*</span></span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="working_hour"
                                        placeholder={translate(props.lang, "Enter working hour")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.working_hour}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.working_hour)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.epf_number ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "EPF Number")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="epf_number"
                                        placeholder={translate(props.lang, "Enter EPF number")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.epf_number}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.epf_number)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.socso_number ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "SOCSO Number")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="socso_number"
                                        placeholder={translate(props.lang, "Enter SOCSO number")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.socso_number}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.socso_number)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.bank_name ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="bank_name"
                                        placeholder={translate(props.lang, "Enter Bank Name")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.bank.bank_name}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeBank(e, 'bank_name')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.bank_name)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.account_name ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Name")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="account_name"
                                        placeholder={translate(props.lang, "Enter Bank Account Name")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.bank.account_name}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeBank(e, 'account_name')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.account_name)}</span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                            <div className={`groupLabel-input py-1 ${errors.account_number ? 'errorBorder' : ''}`}>
                                <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account Number")}</span>
                                <div className={`input-group`}>
                                    <input
                                        type='text'
                                        name="account_number"
                                        placeholder={translate(props.lang, "Enter Bank Account Number")}
                                        className={`form-control input-transparent py-1  px-3`}
                                        value={formData.bank.account_number}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChangeBank(e, 'account_number')}
                                    />
                                </div>
                            </div>
                            <div>
                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.account_number)}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="px-0 mx-0 mb-5">
                        <Col lg={12} md={12} sm={12} xs={12} className="">
                            <div className="d-flex justify-content-start gap-3">
                                <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                    {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                                </Button>
                                <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/user/overview')}>
                                    {translate(props.lang, "Cancel")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewUser);
