import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import RevealDropdown, {
    RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import FileListItem from 'components/modules/project-management/todo-list/FileListItem';
import { attachments } from 'data/project-management/todoListData';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { translate } from 'utils/translate';
import ActivityTimeline from './ActivityTimelime';
import TaskCompleted from './TaskCompleted';
import TeamMembers from './TeamMembers';
import WorkLoads from './WorkLoads';
import { useEffect, useRef, useState } from 'react';
import { api } from 'utils/api';
import ProjectDetailsSummary from './ProjectDetailsSummary';

let breadcrumbData = [
    { label: 'Project', url: '/project/overview' },
    { label: 'Project Details', active: true }
]

const ProjectDetails = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [projectInfo, setProjectInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const timerRef = useRef(null);
    useEffect(()=>{
        getInfo();
    },[id])

    const getInfo = () => {
        setLoading(true);
        fetch(api.get_project_details + `/${id}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.status === "success") {
                    setProjectInfo(responseJson.data)
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <Row className="g-0">
                    <Col xs={12} xxl={8} className="px-0 bg-body">
                        <div className="pt-6 pb-9">
                            <div className="mb-5">
                                <div className="d-flex justify-content-between">
                                    <h2 className="text-body-emphasis fw-bolder mb-2">
                                        {translate(props.lang, "Project name here")}
                                    </h2>
                                    <RevealDropdownTrigger>
                                        <RevealDropdown>
                                            <Dropdown.Item>Edit</Dropdown.Item>
                                            <Dropdown.Item className="text-danger">Delete</Dropdown.Item>
                                            <Dropdown.Item>Downlaod</Dropdown.Item>
                                            <Dropdown.Item>Report abuse</Dropdown.Item>
                                        </RevealDropdown>
                                    </RevealDropdownTrigger>
                                </div>
                            </div>
                            <Row className="gx-0 gx-sm-5 gy-8 mb-4">
                                <Col xs={12} xl={3} xxl={4} className="pe-xl-0">
                                    <ProjectDetailsSummary projectInfo={projectInfo} lang={props.lang} auth={props.auth} />
                                </Col>
                                <Col xs={12} xl={9} xxl={8}>
                                    <TaskCompleted projectInfo={projectInfo} lang={props.lang} auth={props.auth}/>
                                </Col>
                                <Col xs={12} sm={5} lg={4} xl={3} xxl={4}>
                                    <WorkLoads projectInfo={projectInfo} lang={props.lang} auth={props.auth}/>
                                </Col>
                                <Col xs={12} sm={7} lg={8} xl={5}>
                                    <TeamMembers projectInfo={projectInfo} lang={props.lang} auth={props.auth}/>
                                </Col>
                            </Row>
                            <div>
                                <h3 className="text-body-emphasis mb-4">Project overview</h3>
                                {projectInfo && projectInfo.description ? (
                                    <p className="text-body-secondary mb-4" dangerouslySetInnerHTML={{ __html: projectInfo && projectInfo.description }}></p>
                                ) : null}
                                
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} xxl={4} className="px-0 border-start-xxl">
                        <div className="h-100">
                            <div className="bg-light dark__bg-gray-1100 h-100">
                                <div className="p-4">
                                    <h3 className="text-body-highlight mb-4 fw-bold">
                                        Recent activity
                                    </h3>
                                    <ActivityTimeline projectInfo={projectInfo} lang={props.lang} auth={props.auth}/>
                                </div>
                                {/* <div>
                                    <div className="px-4 px-lg-6 mb-3">
                                        <h4 className="mb-0">Files</h4>
                                    </div>
                                    <div className="mb-3">
                                        {attachments.map((attachment, index) => (
                                            <FileListItem
                                                key={attachment.name}
                                                attachment={attachment}
                                                className={classNames('px-4 px-lg-6', {
                                                    'border-top': index === 0
                                                })}
                                            />
                                        ))}
                                    </div>
                                    <div className="px-5 px-md-6 mb-9">
                                        <Button
                                            variant="link"
                                            className="text-decoration-none p-0"
                                            startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />}
                                        >
                                            Add file(s)
                                        </Button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ProjectDetails);
