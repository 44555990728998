import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faFileExport, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from "antd";
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import DataLoading from 'page/Components/DataLoading';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";
import { api } from 'utils/api';
import { exportPageSize, shownItem, timesheetStatus } from 'utils/config';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { downloadExcel } from 'utils/function';
import { translate } from 'utils/translate';
const { RangePicker } = DatePicker;

const OTList = (props) => {
    let navigate = useNavigate();
    const { config } = useAppContext();
    const [productPerpage, setProductPerpage] = useState(10)
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState([]);
    const [status, setStatus] = useState('pending')
    const [statistic, setStatistic] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const timerRef = useRef(null);
    const [sortBy, setSortBy] = useState({
        sortField: '',
        sortOrder: '',
        subSort: '',
        subOrder: '',
    });

    let breadcrumbData = [
        { label: translate(props.lang, 'Timesheet'), url: '#' },
        { label: translate(props.lang, 'Overtime'), active: true }
    ]

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
        getList(event.selected + 1)
    }

    useEffect(() => {
        if (firstLoad) {
            getList();
            setFirstLoad(false)
        }
    }, [props.auth.auth.token]);

    useEffect(() => {
        if (!firstLoad) {
            setPageNumber(1)
            getList(1);
        }
    }, [sortBy.sortField, sortBy.sortOrder, props.lang, name, type, productPerpage]);

    const getList = (page = pageNumber) => {
        setLoading(true);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            let url = api.get_announcement_groups + "?page=" + page + "&limit=" + productPerpage + '?details=1';
            let search = 0;
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (type) {
                search = 1;
                url = url + `&type=${type}`
            }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }
            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    if (responseJson.status === "success") {
                        setResult(responseJson.data.data);
                        setTotalResultCount(responseJson.data.subs.totalAudienceGroup)
                        let totalPage = Math.ceil(responseJson.data.subs.totalAudienceGroup / productPerpage);
                        setPageCount(totalPage);
                        setStatistic(responseJson.data.subs.totalAudienceGroup)
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const handleTimeChange = (e) => {
        const formattedFromDate = e ? dayjs(e[0]).format("YYYY-MM-DD") : '';
        const formattedToDate = e ? dayjs(e[1]).format("YYYY-MM-DD") : '';
        setStartDate(formattedFromDate);
        setEndDate(formattedToDate);
    }

    const formatData = (data) => {
        let newData = [];
        data.forEach(entry => {
            let formattedData = {
                "Name": entry.name ? entry.name : '-',
                "Type": entry.type ? entry.type : '-',
                "Description": entry.description ? entry.description : '-',
                "Status": entry.status ? entry.status : '-',
            };
            newData.push(formattedData)
        })
        return newData;
    }

    const exportExcel = () => {
        setLoadingExcel(true);
        const totalPagesCount = Math.ceil(totalResultCount / exportPageSize);
        let processData = [];
        let totaldata = 0;
        for (let pageCount = 1; pageCount <= totalPagesCount; pageCount++) {
            let url = api.get_announcement_groups + "?page=" + pageCount + "&limit=" + exportPageSize + '?details=1';
            let search = 0;
            if (name) {
                search = 1;
                url = url + `&name=${name}`
            }
            if (type) {
                search = 1;
                url = url + `&type=${type}`
            }
            if (sortBy.sortField && sortBy.sortOrder) {
                url = url + `&sort_by=${sortBy.sortField}&order_by=${sortBy.sortOrder}`
            }

            fetch(url, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang,
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        totaldata = responseJson.data.subs.totalAudienceGroup;
                        processData = processData.concat(formatData(responseJson.data.data));
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            console.log('error', responseJson.message)
                        }
                    }
                    if (processData.length === totaldata) {
                        downloadExcel(processData, 'user_group')
                        setLoadingExcel(false)
                    }
                }).catch(error => {
                    console.error("error", error);
                });

            setTimeout(() => { }, 500) // add some delay here to let server take rest.
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">{translate(props.lang, "Overtime")}</h2>
                <div className="mb-4">
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12} className='mb-3'>
                            <div className="tableBgContainer py-4 px-4 total">
                                <span className="font-18 font-weight-500">{translate(props.lang, "Total Overtime")}</span>
                                <div className="dataBg px-2 py-1 mt-2">
                                    <span className="font-16 font-weight-500 d-block mt-1">{statistic ? statistic : 0}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100`}>
                                <RangePicker
                                    className="w-100 input-transparent px-2 py-2 h-100"
                                    placeholder={[
                                        translate(props.lang, "From"),
                                        translate(props.lang, "To"),
                                    ]}
                                    onChange={(e) => handleTimeChange(e)}
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by User")}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Organization")}
                                onChange={e => setType(e.target.value)}
                                value={type}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Form.Control
                                type="text"
                                placeholder={translate(props.lang, "Search by Department")}
                                onChange={e => setType(e.target.value)}
                                value={type}
                            />
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <div className={`input-group-filter h-100 d-flex align-items-center w-100 px-3 py-1`}>
                                <Select
                                    options={timesheetStatus.length > 0 ? timesheetStatus.map((item, i) => ({ value: item.value, label: translate(props.lang, item.label), key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                    placeholder={translate(props.lang, "Select Status")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    isClearable={true}
                                    value={timesheetStatus.filter(option => option.value === status).length > 0 ? timesheetStatus.filter(option => option.value === status).map((item, i) => ({ key: i, value: item.value, label: item.label })) : null}
                                    name="status"
                                    onChange={(e) => setStatus(e ? e.value : '')}
                                />
                            </div>
                        </Col>
                        <Col lg={4} md={4} xs={12} sm={12}>
                            <Button variant="info" className="text-center w-100" onClick={() => exportExcel()}>
                                {loadingExcel ? (
                                    <FontAwesomeIcon icon={faSpinner} className="fs-9 fa-spin" />
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                        {translate(props.lang, "Export")}
                                    </>
                                )}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                    <Table responsive="sm" >
                        <thead>
                            <tr>
                                <th className='py-2 text-center'>Date</th>
                                <th className='py-2 text-center'>Project</th>
                                <th className='py-2 text-center'>Task</th>
                                <th className='py-2 text-center'>Worker</th>
                                <th className='py-2 text-center'>Daily Log</th>
                                <th className='py-2 text-center'>Time Period</th>
                                <th className='py-2 text-center'>Description</th>
                                <th className='py-2 text-center'>Submitted Datetime</th>
                                <th className='py-2 text-center'>Status</th>
                                <th className='py-2 text-center'>Remark</th>
                                <th className='py-2 text-center'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan={11}>
                                        <DataLoading theme='light' />
                                    </td>
                                </tr>
                            ) : (
                                result.length > 0 ? (
                                    result.map((item, i) => (
                                        <tr role="row" key={i}>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='td-text text-center py-2 fs-9'></td>
                                            <td className='td-text text-center py-2 fs-9'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='fs-9 py-2 text-center'></td>
                                            <td className='py-2 text-center' style={{ minWidth: '100px' }}>
                                                <Link to={`/user-group/edit/`}>
                                                    <FontAwesomeIcon icon={faEdit} color="#839AB4" fontSize={20} />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr role="row">
                                        <td colSpan={11}>
                                            <NoResultFound />
                                        </td>
                                    </tr>
                                )
                            )}

                        </tbody>
                    </Table>
                    <Row className='justify-content-end align-items-center my-3 px-0 mx-0'>
                        <Col lg={4} md={4} sm={6} xs={6} className='px-0'>
                            <div className='d-flex w-100 align-items-center justify-content-end'>
                                <span className='fs-9'>Items Per Page</span>
                                <div className="input-group-select-paginate ms-2 px-2">
                                    <Select
                                        options={
                                            shownItem.length > 0 ? shownItem.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No options available") }]
                                        }
                                        className="input-transparent w-100 text-capitalize px-0"
                                        styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                        value={productPerpage && shownItem.find(option => option.value === productPerpage)}
                                        name="productPerpage"
                                        isSearchable={false}
                                        onChange={(e) => setProductPerpage(e ? e.value : '')}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center my-3'>
                        <Col lg={12} md={12} sm={12} xs={12} className='px-0'>
                            {!loading && result.length > 0 ? (
                                <div className="my-3">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={pageCount}
                                        forcePage={pageNumber - 1}
                                        onPageChange={changePage}
                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-white"}
                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                        disabledClassName={"pagination__link--disabled text-muted"}
                                        activeClassName={"pagination__link--active text-white"}
                                        marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(OTList);
