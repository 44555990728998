import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';
import NoResultFound from 'page/Components/NoResultFound';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import {
    useDropzone
} from 'react-dropzone';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import Swal from 'sweetalert2';
import { api, attachmentUrl } from 'utils/api';
import { fiscalMonth } from 'utils/config';
import countries from 'utils/countries';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';
import customSelectStyles from 'utils/customSelectStyles';
import { deepCopyArray, getSweelAlertBg, getSweelAlertTextColor, isValidUrl } from 'utils/function';
import phoneCodeList from 'utils/phoneCodeList';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Organization', url: '/organization/overview' },
    { label: 'Edit Organization ', active: true }
]

const EditOrganization = (props) => {
    let errorObj = {
        name: null,
        currency: null,
        company_prefix: null,
        website_url: null,
        tax_id: null,
        ssm: null
    }
    const { config } = useAppContext();
    const navigate = useNavigate();
    const { id } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [editRow, setEditRow] = useState('');
    const [tab, setTab] = useState('general');
    const [editable, setEditable] = useState(false);
    const [fiscalDays, setFiscalDays] = useState([]);
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [removeAddressList, setRemoveAddressList] = useState([]);
    const [organizationInfo, setOrganizationInfo] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        address: [],
        currency: 'MYR',
        company_prefix: '',
        website_url: '',
        tax_id: '',
        ssm: '',
        fiscal_start_date: '',
        fiscal_month: '',
        fiscal_period: '',
        payment_method: null
    })
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [uploadedAttachment, setUploadedAttachment] = useState([]);
    const [attachmentPreview, setAttachmentPreview] = useState('');
    const [errorAddress, setErrorAddress] = useState([]);
    const addressMandatoryField = { name: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '', email: '' }
    const [paymentForm, setPaymentForm] = useState({
        name: '',
        description: ''
    })
    const timerRef = useRef(null);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(acceptedFiles);
            // Call your backend API endpoint to upload files
        },
        multiple: false
    });

    const { getRootProps: getAttachmentRootProps, getInputProps: getAttachmentInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedAttachment(uploadedAttachment.concat(acceptedFiles));
            // Call your backend API endpoint to upload files
        },
        multiple: true
    });

    useEffect(() => {
        let option = [];
        for (var i = 1; i <= 31; i++) {
            option.push({ label: i, value: i, key: i });
        }
        setFiscalDays(option)
    }, [])

    const getInfo = () => {
        setLoading(false);
        fetch(api.get_organization_details + '/' + id, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                if (responseJson.status === "success") {
                    setOrganizationInfo(responseJson.data)
                    let newFormData = { ...formData };
                    newFormData.name = responseJson.data.name;
                    newFormData.ssm = responseJson.data.ssm;
                    newFormData.company_prefix = responseJson.data.company_prefix;
                    newFormData.currency = responseJson.data.currency;
                    newFormData.tax_id = responseJson.data.tax_id;
                    newFormData.industry = responseJson.data.industry;
                    newFormData.business_type = responseJson.data.business_type;
                    newFormData.website_url = responseJson.data.website_url;
                    newFormData.fiscal_start_date = responseJson.data.fiscal_start_date;
                    newFormData.fiscal_period = responseJson.data.fiscal_period;
                    newFormData.fiscal_month = responseJson.data.fiscal_month;
                    newFormData.logo = responseJson.data.logo;
                    newFormData.ssm_attachment = responseJson.data.ssm_attachment;
                    if (responseJson.data.ssm_attachment) {
                        setAttachmentPreview(responseJson.data.ssm_attachment);
                    }

                    if (responseJson.data.logo) {
                        setUploadedFiles([responseJson.data.logo])
                    }
                    setPaymentMethod(responseJson.data.payment_method)
                    let newAddress = [];
                    let newErrorAddress = []
                    if (responseJson.data.address.length > 0) {
                        for (var i = 0; i < responseJson.data.address.length; i++) {
                            let format = { name: '', attention: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '', phone: '', email: '', fax: '', organization_address_id: '', country_code: '' };
                            format.name = responseJson.data.address[i].name;
                            format.address1 = responseJson.data.address[i].address1;
                            format.address2 = responseJson.data.address[i].address2;
                            format.town = responseJson.data.address[i].town;
                            format.postcode = responseJson.data.address[i].postcode;
                            format.state = responseJson.data.address[i].state;
                            format.country = responseJson.data.address[i].country;
                            format.attention = responseJson.data.address[i].attention;
                            format.phone = responseJson.data.address[i].phone;
                            format.email = responseJson.data.address[i].email;
                            format.fax = responseJson.data.address[i].fax;
                            format.country_code = responseJson.data.address[i].country_code;
                            format.organization_address_id = responseJson.data.address[i].organization_address_id;
                            newAddress.push(format);
                            newErrorAddress.push({ name: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '' });
                        }
                        setErrorAddress(newErrorAddress);
                        newFormData.address = newAddress;
                    }

                    setFormData(newFormData);

                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        console.log('error', responseJson.message)
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const getPaymentMethod = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_settings, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        let filter = responseJson.data.filter(item => item.category === 'setting');
                        if (filter.length > 0) {
                            setPaymentList(filter[0].value.payment_method)
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    useState(() => {
        if (firstLoad) {
            getInfo();
            getPaymentMethod();
            setFirstLoad(false)
        }
    }, [id]);

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleAddFormChangeAddress = (e, i) => {
        let capitalLetterField = ['name', 'state', 'town', 'attention', 'address1', 'address2']
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        const newFormData2 = { ...formData };
        if (capitalLetterField.includes(fieldName)) {
            newFormData2.address[i][fieldName] = fieldValue.toUpperCase();
        } else {
            newFormData2.address[i][fieldName] = fieldValue;
        }
        setFormData(newFormData2);
    }

    const addMoreAddress = () => {
        let format = { name: '', attention: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '', phone: '', email: '', fax: '', country_code: '' };
        let newFormData = { ...formData };
        newFormData.address.push(format);
        setFormData(newFormData);

        let newErrorAddress = [...errorAddress];
        newErrorAddress.push(format);
        setErrorAddress(prevState => ([...prevState, newErrorAddress]))
    }

    const removeAddress = (i) => {
        let newFormData = { ...formData };
        newFormData.address.splice(i, 1);
        setFormData(newFormData);

        let newErrorAddress = [...errorAddress];
        newErrorAddress.splice(i, 1);
        setErrorAddress(prevState => ([...prevState, newErrorAddress]))
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        if (type === 'fiscal_start_date') {
            if (newFormData.fiscal_month) {
                let filteredFiscalMonth = fiscalMonth.filter(item => item.value === newFormData.fiscal_month);
                let startDate = value + ' ' + filteredFiscalMonth[0].startMonth;
                var expireDate = new Date(startDate);
                expireDate.setFullYear(expireDate.getFullYear() + 1);
                expireDate.setDate(expireDate.getDate() - 1);
                newFormData.fiscal_period = moment(startDate).format('DD MMMM') + ' - ' + moment(expireDate).format('DD MMMM');
            } else {
                newFormData.fiscal_period = "";
            }
        } else if (type === 'fiscal_month') {
            if (newFormData.fiscal_start_date) {
                let filteredFiscalMonth = fiscalMonth.filter(item => item.value === value);
                let startDate = newFormData.fiscal_start_date + ' ' + filteredFiscalMonth[0].startMonth;
                var expireDate = new Date(startDate);
                expireDate.setFullYear(expireDate.getFullYear() + 1);
                expireDate.setDate(expireDate.getDate() - 1);
                newFormData.fiscal_period = moment(startDate).format('DD MMMM') + ' - ' + moment(expireDate).format('DD MMMM');
            } else {
                newFormData.fiscal_period = "";
            }
        }
        setFormData(newFormData);
    }

    const handleOptionSelectedAddress = async (e, j) => {
        const { value } = e;
        const newFormData = { ...formData };
        let newAddress = newFormData.address;
        newAddress[j]['country'] = value;
        if (value) {
            let phoneCode = phoneCodeList.filter(item => item.name.toUpperCase() === value.toUpperCase())[0].dial_code;
            newAddress[j]['country_code'] = phoneCode;
        }


        newFormData.address = newAddress;
        setFormData(newFormData);
    }

    const removePreviewImg = (index) => {
        let newUploadedAttachment = [...uploadedAttachment];
        newUploadedAttachment.splice(index, 1);
        setUploadedAttachment(newUploadedAttachment);
    }

    const removeExistingAttachment = (index) => {
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: translate(props.lang, "Are you sure you want to remove this image?"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(api.remove_organization_attachment + '/' + organizationInfo.organization_id, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                    body: JSON.stringify(attachmentPreview[index])
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setLoading(false)
                        if (responseJson.status === 'success') {
                            let newAttachmentPreview = [...attachmentPreview];
                            newAttachmentPreview.splice(index, 1);
                            setAttachmentPreview(newAttachmentPreview);
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Data has been deleted successfully."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: rtranslate(props.lang, "Something went wrong. Please try again."),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        });
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        let regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (formData.website_url) {

            if (formData.website_url !== 'https://' && formData.website_url !== 'http://') {
                console.log(formData.website_url)
                if (!isValidUrl(formData.website_url)) {
                    errorMsg.website_url = "Invalid URL, URL must start with https://";
                    check = false;
                }
            } else {
                newFormData.website_url = '';
            }
        }

        if (uploadedFiles.length > 0) {
            newFormData.logo = uploadedFiles[0];
        } else {
            newFormData.logo = null;
        }

        let addressError = [...errorAddress];
        for (var i = 0; i < newFormData.address.length; i++) {
            let format = { name: '', attention: '', address1: '', address2: '', town: '', postcode: '', state: '', country: '', phone: '', email: '', fax: '' };
            Object.keys(addressMandatoryField).map((item, j) => {
                if (item === 'email') {
                    if (newFormData.address[i][item]) {
                        if (!regEmail.test(newFormData.address[i][item])) {
                            check = false;
                            format[item] = "Invalid email address";
                        } else {
                            format[item] = "";
                        }
                    } else {
                        format[item] = "";
                    }
                } else {
                    if (!newFormData.address[i][item]) {
                        check = false;
                        format[item] = "This field is required.";
                    } else {
                        format[item] = "";
                    }
                }
            })
            addressError[i] = format;
        }
        newFormData.payment_method = JSON.stringify(paymentMethod)
        setErrorAddress(addressError)
        setErrors(errorMsg);
        console.log(uploadedAttachment);
        console.log(formData);

        if (check) {
            setLoading(true);
            const form = new FormData();
            for (const key in newFormData) {
                if (key !== 'address') {
                    if (newFormData[key]) {
                        form.append(key, newFormData[key]);
                    }
                }
            }
            if (uploadedAttachment.length > 0) {
                for (const key in uploadedAttachment) {
                    form.append('ssm_attachment', uploadedAttachment[key], encodeURIComponent(uploadedAttachment[key].name));
                }
            }
            fetch(api.edit_organization + '/' + id, {
                method: "PUT",
                headers: new Headers({
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: form
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        if (newFormData.address.length > 0) {
                            fetch(api.edit_organization_address + '/' + organizationInfo.organization_id, {
                                method: "PUT",
                                headers: new Headers({
                                    "Content-Type": "application/json",
                                    "Accept-Language": props.lang.toLowerCase(),
                                    "Authorization": "Bearer " + props.auth.auth.token
                                }),
                                body: JSON.stringify(newFormData.address)
                            })
                                .then(response => response.json())
                                .then(responseJson => {
                                    setLoading(false)
                                    if (responseJson.status === 'success') {
                                        if (removeAddressList.length > 0) {
                                            proceedRemoveAddress();
                                        } else {
                                            setLoading(false)
                                            setUploadedAttachment([]);
                                            Swal.fire({
                                                icon: "success",
                                                title: translate(props.lang, 'Success'),
                                                text: translate(props.lang, "Organization has been updated successfully."),
                                                background: getSweelAlertBg(), // Dark background color
                                                color: getSweelAlertTextColor(), // Text color
                                            });
                                            setEditable(false);
                                            getInfo();
                                        }
                                    } else {
                                        if (responseJson.message === 'token expired') {
                                            dispatch(logout());
                                            navigate('/')
                                        } else if (responseJson.tokenExpired) {
                                            dispatch(logout());
                                            navigate('/')
                                        } else {
                                            Swal.fire({
                                                icon: "error",
                                                title: translate(props.lang, 'Error'),
                                                text: translate(props.lang, "Something went wrong. Please try again."),
                                                background: getSweelAlertBg(), // Dark background color
                                                color: getSweelAlertTextColor(), // Text color
                                            });
                                        }
                                    }
                                }).catch(error => {
                                    console.error("error", error);
                                });
                        } else {
                            if (removeAddressList.length > 0) {
                                proceedRemoveAddress();
                            } else {
                                setLoading(false)
                                Swal.fire({
                                    icon: "success",
                                    title: translate(props.lang, 'Success'),
                                    text: translate(props.lang, "Organization has been updated successfully."),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                                setEditable(false)
                                getInfo();
                            }
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: translate(props.lang, "Something went wrong. Please try again."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const proceedRemoveAddress = () => {
        fetch(api.remove_organization_address + '/' + organizationInfo.organization_id, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(removeAddressList)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Organization has been updated successfully."),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getInfo();
                    setEditable(false);
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: translate(props.lang, "Something went wrong. Please try again."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const toggleStatus = (status) => {
        let message = "";
        if (status === 'active') {
            message = translate(props.lang, "Are you sure you want to deactivate this data?")
        } else {
            message = translate(props.lang, "Are you sure you want to activate this data?")
        }
        Swal.fire({
            title: translate(props.lang, "Confirmation"),
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: translate(props.lang, "Confirm"),
            cancelButtonText: translate(props.lang, "No"),
            background: getSweelAlertBg(), // Dark background color
            color: getSweelAlertTextColor(), // Text color
        }).then((result) => {
            if (result.isConfirmed) {
                let url = api.edit_organization_status + `/${id}`
                fetch(url, {
                    method: "PUT",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.token
                    }),
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                        if (responseJson.status === "success") {
                            Swal.fire({
                                icon: "success",
                                title: translate(props.lang, 'Success'),
                                text: translate(props.lang, "Organization has been update successfully."),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                            getInfo();
                        } else {
                            if (responseJson.message === 'token expired') {
                                dispatch(logout());
                                navigate('/')
                            } else if (responseJson.tokenExpired) {
                                dispatch(logout());
                                navigate('/')
                            } else {
                                if (Array.isArray(responseJson.message)) {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message.join('\n'),
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: translate(props.lang, 'Error'),
                                        text: responseJson.message,
                                        background: getSweelAlertBg(), // Dark background color
                                        color: getSweelAlertTextColor(), // Text color
                                    });
                                }
                            }
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }
        })
    }

    const closeModal = () => {
        resetPaymentForm();
        setModalOpen(false)
    }

    const resetPaymentForm = () => {
        setPaymentForm({
            name: '',
            description: ''
        })
    }

    const addItem = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let data = { ...paymentForm }
        Object.keys(paymentForm).map((item) => {
            if (!paymentForm[item]) {
                check = false
                errorMsg[item] = "This field is required.";
            }
        })
        console.log(data)
        console.log(paymentMethod)
        setErrors(errorMsg)
        if (check) {
            let newFormData = []
            if (paymentMethod && paymentMethod.length > 0) {
                newFormData = deepCopyArray(paymentMethod)
            }
            if (editRow && editRow) {
                newFormData[Number(editRow)] = data;
            } else {
                newFormData.push(data);
            }
            console.log(newFormData)
            setPaymentMethod(newFormData);
            closeModal();
            setEditRow(null)
        }
    }

    const editData = (i) => {
        setEditRow(i);
        let newFormData = { ...paymentForm };
        newFormData.name = paymentMethod[i].name;
        newFormData.description = paymentMethod[i].description;
        setPaymentForm(newFormData);
        setModalOpen(true);
    }

    const removeData = (index) => {
        let newList = deepCopyArray(paymentMethod);
        newList.splice(index, 1);
        newList.map((item, i) => {
            item.layer = i + 1;
        })
        setPaymentMethod(newList);
    }

    const addNewRow = () => {
        setModalOpen(true)
    }

    const handlePaymentFormChange = (e) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        const newFormData = { ...paymentForm };
        newFormData[fieldName] = fieldValue;
        setPaymentForm(newFormData);
    }

    const handlePaymentOptionChange = (e, type) => {
        const fieldValue = e.value;
        const newFormData = { ...paymentForm };
        newFormData.name = fieldValue;
        let filtered = paymentList.filter(item => item.name === fieldValue);
        if (filtered.length > 0) {
            newFormData.description = filtered[0].description
        }
        setPaymentForm(newFormData);
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Edit Organization")}</h2>
            <Row className='align-items-center justify-content-end'>
                <Col lg={4} md={4} sm={12} xs={12} className="mb-3 justify-content-end d-flex align-items-center">
                    {!editable ? (
                        <Button variant="outline-primary" className='me-2' onClick={() => setEditable(true)}>
                            <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Edit")}</span>
                        </Button>
                    ) : null}
                    {organizationInfo ? (
                        organizationInfo.status === 'active' ? (
                            <Button variant="outline-danger" onClick={() => toggleStatus('active')}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Disable")}</span>
                            </Button>
                        ) : (
                            <Button variant="outline-success" onClick={() => toggleStatus('inactive')}>
                                <span className="font-weight-600 fs-9 cursor-pointer">{translate(props.lang, "Enable")}</span>
                            </Button>
                        )
                    ) : null}
                </Col>
            </Row>
            <div className='border-bottom mb-3'>
                <Row className='align-items-center'>
                    <Col lg={6} md={6} sm={6} xs={6} className='text-center py-2'>
                        <span className={`cursor-pointer fw-semibold px-3 fs-9 ${tab === 'general' ? 'text-primary' : 'text-grey-1'}`} onClick={() => setTab('general')}>{translate(props.lang, "General")}</span>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className='text-center py-2'>
                        <span className={`cursor-pointer fw-semibold px-3 fs-9 ${tab === 'payment' ? 'text-primary' : 'text-grey-1'}`} onClick={() => setTab('payment')}>{translate(props.lang, "Payment Information")}</span>
                    </Col>
                </Row>
            </div>
            {tab === 'general' ? (
                <>
                    <div className='border-bottom'>
                        <Row className='align-items-start justify-content-center'>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization Name")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="name"
                                            placeholder={translate(props.lang, "Enter organization name")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.name}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.company_prefix ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Organization Code")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="company_prefix"
                                            placeholder={translate(props.lang, "Enter organization code")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.company_prefix}
                                            autoComplete="off"
                                            // onChange={e => handleAddFormChange(e)}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.company_prefix)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.ssm ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "SSM Number")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="ssm"
                                            placeholder={translate(props.lang, "Enter SSM number")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.ssm}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger">{translate(props.lang, errors.ssm)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.tax_id ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Tax ID")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="tax_id"
                                            placeholder={translate(props.lang, "Enter tax ID")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.tax_id}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.tax_id)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.industry ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Industry")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="industry"
                                            placeholder={translate(props.lang, "Enter industry")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.industry}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.industry)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.business_type ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Business Type")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="business_type"
                                            placeholder={translate(props.lang, "Enter business type")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.business_type}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.business_type)}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='border-bottom py-2'>
                        <Row className='align-items-center justify-content-center'>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <span className={`fw-semibold fs-9 text-grey-1 d-block `}>{translate(props.lang, "Logo")} <span className="text-danger">*</span></span>
                                {editable ? (
                                    <div
                                        {...getRootProps()}
                                        className={`dropzone ${errors.logo ? 'errorBorder' : ''}`}
                                    >
                                        <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                                            Drag your files here{' '}
                                            <span className="text-body-secondary">or </span>
                                            <Button variant="link" className="p-0">
                                                Browse from device
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                                {uploadedFiles.map((file, index) => (
                                    <div key={index} className={'border-bottom d-flex align-items-center justify-content-between py-3'}>
                                        <div className='btn-icon btn-icon-lg rounded-3 flex-column me-2 position-relative text-body-quaternary'>
                                            <img
                                                src={file === formData.logo ? file.webp ? attachmentUrl + file.webp : attachmentUrl + file.path : URL.createObjectURL(file)}
                                                alt={'logo'}
                                                className="w-100 rounded"
                                            />
                                        </div>
                                        <button className="btn p-0" onClick={() => setUploadedFiles([])}>
                                            <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                        </button>
                                    </div>
                                ))}
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.logo)}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='border-bottom py-2'>
                        <Row className='align-items-center justify-content-center'>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <span className={`fw-semibold fs-8 text-grey-1 d-block mt-2`}>{translate(props.lang, "Billing Address Information")}</span>
                            </Col>
                            {formData.address.map((item, j) => (
                                <Col lg={12} md={12} sm={12} xs={12} key={j} className={`mb-2 ${j !== formData.address.length - 1 ? 'mb-2' : ''}`}>
                                    <div className='border rounded-2 px-2 py-3'>
                                        <Row className="px-0 mx-0 align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <Row className="px-0 mx-0 align-items-center">
                                                    <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                        <div className="text-start">
                                                            <div className={`groupLabel-input py-1`}>
                                                                <div className={`input-group mt-1 ${errorAddress[j].name ? 'errorBorder' : ''}`}>
                                                                    <input
                                                                        type='text'
                                                                        name="name"
                                                                        placeholder={translate(props.lang, "Label address as (Eg: HQ / Penang Branch)")}
                                                                        className={`form-control input-transparent py-1 px-3`}
                                                                        value={item.name}
                                                                        autoComplete="off"
                                                                        onChange={e => handleAddFormChangeAddress(e, j)}
                                                                        readOnly={!editable}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].name)}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2} className="text-end pe-0">
                                                        <div onClick={() => removeAddress(j)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} size={18} color="#0C62F7" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <hr className="my-1" />
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-1">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].address1 ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Street Address Line 1")} <span className='text-danger'>*</span></span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="address1"
                                                                placeholder={translate(props.lang, "Enter Street Address Line 1")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.address1}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].address1)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].address2 ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Street Address Line 2")} <span className='text-danger'>*</span></span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="address2"
                                                                placeholder={translate(props.lang, "Enter Street Address Line 2")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.address2}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].address2)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].postcode ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Postcode")} <span className="text-danger">*</span></span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="postcode"
                                                                placeholder={translate(props.lang, "Enter Postcode")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.postcode}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].postcode)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].town ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "City")} <span className='text-danger'>*</span></span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="town"
                                                                placeholder={translate(props.lang, "Enter City")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.town}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].town)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].state ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "State")} <span className='text-danger'>*</span></span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="state"
                                                                placeholder={translate(props.lang, "Enter State")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.state}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].state)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].country ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country")} <span className='text-danger'>*</span></span>
                                                        <div className="input-group-select px-3">
                                                            <Select
                                                                options={
                                                                    countries.length > 0 ? countries.map((item, i) => ({ key: i, value: item.name, label: item.name.toLowerCase() })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                                                }
                                                                placeholder={translate(props.lang, "Select Country")}
                                                                className="input-transparent w-100 text-capitalize"
                                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                                value={
                                                                    item.country ? (
                                                                        countries.filter(singleItem => singleItem.name == item.country.toUpperCase()).length > 0 ?
                                                                            (countries.filter(singleItem => singleItem.name == item.country.toUpperCase()).map((item, i) => ({ value: item.name, label: item.name.toLowerCase(), key: i })))
                                                                            : null
                                                                    ) : null
                                                                }
                                                                name="country"
                                                                onChange={(e) => handleOptionSelectedAddress(e, j)}
                                                                isDisabled={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].country)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].attention ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attention To")}</span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="attention"
                                                                placeholder={translate(props.lang, "Enter Attention To")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.attention}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].attention)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].email ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Address")}</span>
                                                        <div className={`input-group`}>
                                                            <input
                                                                type='text'
                                                                name="email"
                                                                placeholder={translate(props.lang, "Enter Email Address")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.email}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].email)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].phone ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Phone Number")}</span>
                                                        <div className={`input-group`}>
                                                            <div className={`d-flex align-items-center h-100 ${item.country_code ? 'ps-3' : ''}`}>
                                                                <span className="form-control input-transparent d-flex align-items-center">{item.country_code}</span>
                                                            </div>
                                                            <input
                                                                type='text'
                                                                name="phone"
                                                                placeholder={translate(props.lang, "Enter Phone Number")}
                                                                className={`form-control input-transparent py-1 px-3 `}
                                                                value={item.phone}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].phone)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="mb-2">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 ${errorAddress[j].fax ? 'errorBorder' : ''}`}>
                                                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Fax Number")}</span>
                                                        <div className={`input-group`}>
                                                            <div className={`d-flex align-items-center h-100 ${item.country_code ? 'ps-3' : ''}`}>
                                                                <span className="form-control input-transparent d-flex align-items-center">{item.country_code}</span>
                                                            </div>
                                                            <input
                                                                type='text'
                                                                name="fax"
                                                                placeholder={translate(props.lang, "Enter Fax Number")}
                                                                className={`form-control input-transparent py-1 px-3`}
                                                                value={item.fax}
                                                                autoComplete="off"
                                                                onChange={e => handleAddFormChangeAddress(e, j)}
                                                                readOnly={!editable}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errorAddress[j].fax)}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))}
                            {editable ? (
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <Button variant='phoenix-primary' className='w-100' onClick={() => addMoreAddress()}>{translate(props.lang, "Add new address")}</Button>
                                </Col>
                            ) : null}
                        </Row>
                    </div>
                    <div className='mt-2 border-bottom'>
                        <Row className='align-items-center justify-content-start'>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.website_url ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Website URL")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="website_url"
                                            placeholder={translate(props.lang, "Enter website url")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.website_url}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.website_url)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.currency ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Currency")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="currency"
                                            placeholder={translate(props.lang, "Enter currency")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.currency}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.currency)}</span>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.currency ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Fiscal Year")} {formData.fiscal_period ? `(${formData.fiscal_period})` : ''}</span>
                                    <div className='d-flex w-100 justify-content-between align-items-center'>
                                        <div className="input-group-select px-3 w-50">
                                            <Select
                                                options={
                                                    fiscalMonth.length > 0 ? fiscalMonth.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                }
                                                placeholder={translate(props.lang, "Select Month")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                value={
                                                    formData.fiscal_month ? (
                                                        fiscalMonth.filter(singleItem => singleItem.value == formData.fiscal_month).length > 0 ?
                                                            (fiscalMonth.filter(singleItem => singleItem.value == formData.fiscal_month).map((item, i) => ({ value: item.value, label: item.label, key: i })))
                                                            : null
                                                    ) : null
                                                }
                                                name="country"
                                                onChange={(e) => handleOptionSelected(e, 'fiscal_month')}
                                                isDisabled={!editable}
                                            />
                                        </div>
                                        <div className="input-group-select px-3 w-50">
                                            <Select
                                                options={
                                                    fiscalDays.length > 0 ? fiscalDays.map((item, i) => ({ key: i, value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No option available") }]
                                                }
                                                placeholder={translate(props.lang, "Select Start Date")}
                                                className="input-transparent w-100 text-capitalize"
                                                styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                                value={
                                                    formData.fiscal_start_date ? (
                                                        fiscalDays.filter(singleItem => singleItem.value == formData.fiscal_start_date).length > 0 ?
                                                            (fiscalDays.filter(singleItem => singleItem.value == formData.fiscal_start_date).map((item, i) => ({ value: item.value, label: item.label, key: i })))
                                                            : null
                                                    ) : null
                                                }
                                                name="country"
                                                onChange={(e) => handleOptionSelected(e, 'fiscal_start_date')}
                                                isDisabled={!editable}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.currency)}</span>
                                </div>
                            </Col>
                        </Row>
                        <div className='mt-2'>
                            <Row className='align-items-center justify-content-start'>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <span className={`fw-semibold fs-9 text-grey-1 d-block mb-2`}>{translate(props.lang, "Attachment")}</span>
                                    {editable ? (
                                        <div {...getAttachmentRootProps()} className={`dropzone`}>
                                            <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                                                Drag your files here{' '}
                                                <span className="text-body-secondary">or </span>
                                                <Button variant="link" className="p-0">
                                                    Browse from device
                                                </Button>
                                            </div>
                                        </div>
                                    ) : null}
                                    {
                                        uploadedAttachment.length > 0 || attachmentPreview.length > 0 ? (
                                            <Row className={`px-0 mx-0 ${editable ? 'dropzonearea' : 'dropzonearea-disabled'} mt-3`}>
                                                {attachmentPreview.map((item, index) => (
                                                    // <div key={index} className={'border-bottom d-flex align-items-center justify-content-between py-3'}>
                                                    //     <div className='btn-icon btn-icon-lg rounded-3 flex-column me-2 position-relative text-body-quaternary'>
                                                    //         <img
                                                    //             src={attachmentUrl + item?.webp}
                                                    //             alt={'logo'}
                                                    //             className="w-100 rounded"
                                                    //         />
                                                    //     </div>
                                                    //     <button className="btn p-0" onClick={() => removeExistingAttachment(index)}>
                                                    //         <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                                    //     </button>
                                                    // </div>

                                                    <div key={index} className={`${index !== uploadedAttachment.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                                                        {/* <div className='btn-icon btn-icon-lg rounded-3 flex-column me-2 position-relative text-body-quaternary'>
                                                            <img
                                                                src={attachmentUrl + item?.webp}
                                                                alt={'logo'}
                                                                className="w-100 rounded"
                                                            />
                                                        </div> */}
                                                        <a href={attachmentUrl + item?.webp} target="_blank">
                                                            <span className="fs-9 font-weight-400">{attachmentUrl + item?.webp}</span>
                                                        </a>
                                                        <button className="btn p-0" onClick={() => removeExistingAttachment(index)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                                        </button>
                                                    </div>
                                                ))}
                                                {uploadedAttachment.map((item, index) => (
                                                    <div key={index} className={`${index !== uploadedAttachment.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                                                        <span className="fs-9 font-weight-400">{item.name}</span>
                                                        <button className="btn p-0" onClick={() => removePreviewImg(index)}>
                                                            <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                                        </button>
                                                    </div>
                                                    // <div key={index} className={'border-bottom d-flex align-items-center justify-content-between py-3'}>
                                                    //     <div className='btn-icon btn-icon-lg rounded-3 flex-column me-2 position-relative text-body-quaternary'>
                                                    //         <img
                                                    //             src={URL.createObjectURL(item)}
                                                    //             alt={'logo'}
                                                    //             className="w-100 rounded"
                                                    //         />
                                                    //     </div>
                                                    //     <button className="btn p-0" onClick={() => removePreviewImg(index)}>
                                                    //         <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                                    //     </button>
                                                    // </div>
                                                ))}
                                            </Row>
                                        ) : (
                                            <div className='border'>
                                                <NoResultFound />
                                            </div>
                                        )}
                                    <div>
                                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.attachment)}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <Row className='align-items-stretch justify-content-start'>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className='table-responsive'>
                                    <Table responsive className="w-100 table-list position-relative">
                                        <thead>
                                            <tr>
                                                <th className={`fw-bold fs-8`}>{translate(props.lang, "Name")}</th>
                                                <th className={`fw-bold fs-8`}>{translate(props.lang, "Description")}</th>
                                                <th className={`fw-bold fs-8`}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentMethod && paymentMethod.map((item, i) => (
                                                <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                    <td className={`fw-normal fs-9 lh-base text-capitalize`}>{item.name}</td>
                                                    <td className={`fw-normal fs-9 lh-base break-word break-white-space`}>{item.description}</td>
                                                    <td className={`fw-normal fs-8 lh-base`}>
                                                        {editable ? (
                                                            <div className="d-flex">
                                                                <div className="px-2" onClick={() => editData(i.toString())}>
                                                                    <FontAwesomeIcon icon={faEdit} color="#839AB4" />
                                                                </div>
                                                                <div className="px-2" onClick={() => removeData(i)}>
                                                                    <FontAwesomeIcon icon={faTrashAlt} color="red" />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                            {editable ? (
                                <Col lg={12} sm={12} xs={12} md={12} className="mb-3">
                                    <Button variant="secondary" className='w-100 text-center h-100' onClick={() => addNewRow()}>
                                        <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                                        {translate(props.lang, "Add New Data")}
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                    </div>
                </>
            )}
            {editable ? (
                <Row className='px-0 mx-0'>
                    <Col lg={12} md={12} sm={12} xs={12} className="my-4 px-0">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/organization/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            ) : null}
            <Modal className="fade" show={modalOpen} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-3">
                    <div className="d-flex w-100 align-items-center px-2">
                        <div className="w-75 text-start">
                            <span className="fw-bold fs-7">{editRow ? translate(props.lang, "Edit Payment Method") : translate(props.lang, "Add Payment Method")}</span>
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3">
                    <div className='px-2'>
                        <Row className="justify-content-start px-0 mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Type")}</span>
                                    <div className="input-group-select px-3">
                                        <Select
                                            options={
                                                paymentList.map((item, i) => ({ key: i, value: item.name, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select Payment Type")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles : customSelectStyles}
                                            isSearchable={true}
                                            value={paymentList.filter(option => option.name === paymentForm.name).length > 0 ? paymentList.filter(option => option.name === paymentForm.name).map((item, i) => ({ key: i, value: item.name, label: item.name })) : null}
                                            name="name"
                                            onChange={(e) => handlePaymentOptionChange(e, 'name')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                                    <div className={`input-group`}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            className="form-control input-transparent py-1 px-3"
                                            rows={3}
                                            value={paymentForm.description}
                                            placeholder={translate(props.lang, "Enter Description")}
                                            onChange={e => handlePaymentFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                                <Row className="px-0 mx-0">
                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                        <Button variant="primary" className="px-3 px-sm-10 w-100" onClick={() => addItem()}>
                                            {translate(props.lang, "Update")}
                                        </Button>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10 w-100" onClick={() => closeModal()}>
                                            {translate(props.lang, "Close")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(EditOrganization);
