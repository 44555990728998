import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import enLocale from 'date-fns/locale/en-US';
import zhCNLocale from 'date-fns/locale/zh-CN';
import { dateButtonTextMap, regexDecimalPoint } from 'utils/config';
import { deepCopyArray } from 'utils/function';
import NoResultFound from 'page/Components/NoResultFound';
import Swal from 'sweetalert2';

const localeMap = {
    en: enLocale,
    cn: zhCNLocale,
};

let breadcrumbData = [
    { label: 'Claim', url: '/claim/overview' },
    { label: 'New Claim ', active: true }
]

const data = [
    { name: 'task 1', quantity: 1, price: 10, tax: 1, discount: 0, amount: 100 },
]

const NewClaim = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        claim_start_period: '',
        claim_end_period: '',
        bank_name: '',
        bank_account: '',
        items: '',
        attachments: '',

        claim_purchase_date: '',
        description: '',
        quantity: '',
        category: '',
        amount: '',
    }
    const [locale, setLocale] = useState('en');
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [total, setTotal] = useState('')
    const [editRow, setEditRow] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [itemForm, setItemForm] = useState({
        claim_purchase_date: '',
        description: '',
        quantity: '',
        category: '',
        amount: '',
    })
    const [formData, setFormData] = useState({
        claim_start_period: '',
        claim_end_period: '',
        bank_name: '',
        bank_account: '',
        items: [],
        attachments: [],
    })
    const currentDate = new Date();
    const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const { getRootProps: getAttachmentRootProps, getInputProps: getAttachmentInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(uploadedFiles.concat(acceptedFiles));
            // Call your backend API endpoint to upload files
        },
        multiple: true
    })

    useEffect(() => {
        setLocale(props.lang.toLowerCase())
    }, [props.lang])

    useEffect(() => {
        getOrganization();
    }, [props.auth.auth.token])

    const getOrganization = () => {
        fetch(api.get_organizations, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data);
                } else if (responseJson.status === 401) {
                    dispatch(logout());
                    navigate('/')
                } else {
                    console.log('error company', responseJson.message)
                }

            }).catch(error => {
                console.error("error", error);
            });
    }
    const removePreviewFile = (index) => {
        let newUploadedAttachment = [...uploadedFiles];
        newUploadedAttachment.splice(index, 1);
        setUploadedFiles(newUploadedAttachment);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleItemAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...itemForm };
        if (fieldName === 'amount') {
            if (regexDecimalPoint.test(fieldValue)) {
                newFormData[fieldName] = fieldValue;
            }
        } else {
            newFormData[fieldName] = fieldValue;
        }

        setItemForm(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        let newFormData = { ...formData };
        if (!formData.bank_name) {
            errorMsg.bank_name = "This field is required.";
            check = false;
        }
        if (!formData.bank_account) {
            errorMsg.bank_account = "This field is required.";
            check = false;
        }
        if (!formData.claim_start_period) {
            errorMsg.claim_start_period = "This field is required.";
            check = false;
        }
        if (!formData.claim_end_period) {
            errorMsg.claim_end_period = "This field is required.";
            check = false;
        }
        if (formData.claim_start_period && formData.claim_end_period) {
            if (formData.claim_end_period < formData.claim_start_period) {
                errorMsg.claim_end_period = "Period end date cannot smaller than period start date";
                check = false;
            }
        }
        if (uploadedFiles.length === 0) {
            errorMsg.attachments = "This field is required";
            check = false;
        }

        setErrors(errorMsg);
        if (check) {
            setLoading(true)
            let uploadPromises = [];
            let popUpMsg = [];
            let fileArr = formData.attachment;

            if (uploadedFiles.length > 0) {
                uploadedFiles.map((item) => {
                    let uploadPromise = new Promise((resolve, reject) => {

                        let form = new FormData();
                        form.append("image", item);
                        fetch(api.upload_image + '/claim', {
                            method: 'POST',
                            headers: {
                                "Accept-Language": props.lang.toLowerCase(),
                                "Authorization": "Bearer " + props.auth.auth.token
                            },
                            body: form,
                        }).then(response => response.json())
                            .then(responseJson => {
                                if (responseJson.status === 'success') {
                                    resolve('done')
                                    fileArr.push(responseJson.data[0])
                                } else {
                                    reject('reject');
                                    popUpMsg.push(item + ' upload fail.');
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    })
                    uploadPromises.push(uploadPromise);
                })
            }

            Promise.all(uploadPromises).then(() => {
                newFormData.attachment = fileArr;
                proceedSubmitServer(newFormData)
            }, (error) => {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    title: translate(props.lang, 'Error'),
                    text: popUpMsg.join('\n'),
                    background: getSweelAlertBg(), // Dark background color
                    color: getSweelAlertTextColor(), // Text color
                });
            })
        }
    }

    const proceedSubmitServer = (data) => {
        setLoading(true);
        fetch(api.add_claim, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Claim created successful"),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    resetForm();
                    navigate('/claim/overview')
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/')
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/')
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const editData = (i) => {
        setEditRow(i);
        let newFormData = { ...formData.items[i] }
        setItemForm(newFormData);
        setModalOpen(true);
    }

    const removeData = (index) => {
        let newFormData = { ...formData }
        let newList = deepCopyArray(newFormData.items);
        newList.splice(index, 1);
        newList.map((item, i) => {
            item.layer = i + 1;
        })
        newFormData.items = newList;
        setFormData(newFormData);
    }

    const addNewRow = () => {
        setModalOpen(true)
    }

    const resetForm = () => {
        setFormData({
            claim_start_period: '',
            claim_end_period: '',
            bank_name: '',
            bank_account: '',
            items: [],
            attachments: [],
        })

        setItemForm({
            claim_purchase_date: '',
            description: '',
            quantity: '',
            category: '',
            amount: '',
        })
    }

    const closeModal = () => {
        setModalOpen(false)
        setItemForm({
            claim_purchase_date: '',
            description: '',
            quantity: '',
            category: '',
            amount: '',
        })
    }

    const addItem = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let data = { ...itemForm }
        Object.keys(itemForm).forEach(item => {
            if (!itemForm[item]) {
                check = false;
                errorMsg[item] = "This field is required.";
            }
        });
        setErrors(errorMsg)
        if (check) {
            let newFormData = { ...formData };
            let newList = deepCopyArray(formData.items);
            if (editRow && editRow.toString()) {
                newList[Number(editRow)] = data;
            } else {
                newList.push(data);
            }
            newFormData.items = newList;
            setFormData(newFormData);

            let total = 0;
            newList.forEach(item => {
                total += Number(item.amount);
            })
            setTotal(total)

            closeModal();
            setEditRow(null)
        }
    }

    const onChangeCalendar = (e, type) => {
        if (type === 'claim_start_period' || type === 'claim_end_period') {
            let newFormData = { ...formData };
            newFormData[type] = e ? moment(e).format('YYYY-MM-DD') : '';
            setFormData(newFormData);

        } else {
            let newFormData = { ...itemForm };
            newFormData[type] = e ? moment(e).format('YYYY-MM-DD') : '';
            setItemForm(newFormData);
        }
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "New Claim")}</h2>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.bank_name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Name")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="bank_name"
                                    placeholder={translate(props.lang, "Enter Bank Name")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.bank_name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.bank_name)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.bank_account ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Bank Account No.")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="bank_account"
                                    placeholder={translate(props.lang, "Enter Bank Account No.")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.bank_account}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.bank_account)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.claim_start_period ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Period Start From")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        format="yyyy-MM-dd"
                                        className="form-control input-transparent py-1 px-3"
                                        placeholder={translate(props.lang, "Select Start Date")}
                                        value={formData.claim_start_period ? formData.claim_start_period : null}
                                        onChange={(date) => onChangeCalendar(date, 'claim_start_period')}
                                        clearLabel={dateButtonTextMap[locale].clear}
                                        cancelLabel={dateButtonTextMap[locale].cancel}
                                        okLabel={dateButtonTextMap[locale].ok}
                                        minDate={firstDayOfLastMonth}
                                        maxDate={lastDayOfCurrentMonth}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.claim_start_period)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.claim_end_period ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Period End From")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <DatePicker
                                        autoOk
                                        label=""
                                        clearable
                                        format="yyyy-MM-dd"
                                        className="form-control input-transparent py-1 px-3"
                                        placeholder={translate(props.lang, "Select Start Date")}
                                        value={formData.claim_end_period ? formData.claim_end_period : null}
                                        onChange={(date) => onChangeCalendar(date, 'claim_end_period')}
                                        clearLabel={dateButtonTextMap[locale].clear}
                                        cancelLabel={dateButtonTextMap[locale].cancel}
                                        okLabel={dateButtonTextMap[locale].ok}
                                        minDate={firstDayOfLastMonth}
                                        maxDate={lastDayOfCurrentMonth}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.claim_end_period)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className='table-responsive'>
                            <Table responsive className="w-100 table-list position-relative">
                                <thead>
                                    <tr>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "No.")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Date")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Description")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Quantity")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Category")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Cost")}</th>
                                        <th className={`fw-bold fs-8`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formData.items.length > 0 ? (
                                            <>
                                                {formData.items.map((item, i) => (
                                                    <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                        <td className={`fw-normal fs-9 lh-base text-capitalize`}>{i + 1}.</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.claim_purchase_date}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.description}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.quantity}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.category}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>{item.amount}</td>
                                                        <td className={`fw-normal fs-9 lh-base`}>
                                                            <div className="d-flex">
                                                                <div className="px-2" onClick={() => editData(i.toString())}>
                                                                    <FontAwesomeIcon icon={faEdit} color="#194BFB" size={22} />
                                                                </div>
                                                                <div className="px-2" onClick={() => removeData(i)}>
                                                                    <FontAwesomeIcon icon={faTrashAlt} color="red" size={22} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr role="row">
                                                    <td colSpan={5} className='text-end'>
                                                        <span className='fs-9 fw-bold'>{translate(props.lang, "Total")}</span>
                                                    </td>
                                                    <td colSpan={2} className={`text-start`}>
                                                        <span className='fw-normal fs-9'>{total}</span>
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr role="row">
                                                <td colSpan={7}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} className="mb-3">
                        <Button variant="secondary" className='w-100 text-center h-100' onClick={() => addNewRow()}>
                            <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                            {translate(props.lang, "Add New Data")}
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.attachments ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Attachment")}</span>
                            <div className="px-3">
                                <div {...getAttachmentRootProps()} className={`dropzone ${uploadedFiles.length === 0 ? 'mb-3' : ''}`}>
                                    <input {...getAttachmentInputProps()} />
                                    <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                                        Drag your files here{' '}
                                        <span className="text-body-secondary">or </span>
                                        <Button variant="link" className="p-0">
                                            Browse from device
                                        </Button>
                                    </div>
                                </div>
                                {uploadedFiles.map((file, index) => (
                                    <div key={index} className={`${index !== uploadedFiles.length - 1 ? 'border-bottom' : ''} d-flex align-items-center justify-content-between py-2`}>
                                        <span className="fs-9 font-weight-400">{index + 1}. {file.name}</span>
                                        <button className="btn p-0" onClick={() => removePreviewFile(index)}>
                                            <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.attachments)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <Spinner animation="border" variant="light" size="sm" /> : translate(props.lang, "Submit")}
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/claim/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade" size='lg' show={modalOpen} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-3">
                    <div className="d-flex w-100 align-items-center px-2">
                        <div className="w-75">
                            <div className="text-start">
                                <span className="fw-bold fs-7">{editRow ? translate(props.lang, "Edit Claim Item") : translate(props.lang, "Add Claim Item")}</span>
                            </div>
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3">
                    <div className='px-2'>
                        <Row className="justify-content-start px-0 mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.claim_purchase_date ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Date")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                            <DatePicker
                                                autoOk
                                                label=""
                                                clearable
                                                format="yyyy-MM-dd"
                                                className="form-control input-transparent py-1 px-3"
                                                placeholder={translate(props.lang, "Select Start Date")}
                                                value={itemForm.claim_purchase_date ? itemForm.claim_purchase_date : null}
                                                onChange={(date) => onChangeCalendar(date, 'claim_purchase_date')}
                                                clearLabel={dateButtonTextMap[locale].clear}
                                                cancelLabel={dateButtonTextMap[locale].cancel}
                                                okLabel={dateButtonTextMap[locale].ok}
                                                minDate={firstDayOfLastMonth}
                                                maxDate={lastDayOfCurrentMonth}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.claim_purchase_date)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            className="form-control input-transparent py-1 px-3"
                                            rows={3}
                                            value={itemForm.description}
                                            placeholder={translate(props.lang, "Enter Description")}
                                            onChange={e => handleItemAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.quantity ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quantity")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'number'}
                                            name="quantity"
                                            placeholder={translate(props.lang, "Enter Quantity")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={itemForm.quantity}
                                            autoComplete="off"
                                            onChange={e => handleItemAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.quantity)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.category ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Category")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="category"
                                            placeholder={translate(props.lang, "Enter Category")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.category}
                                            autoComplete="off"
                                            onChange={e => handleItemAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.category)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Cost")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'number'}
                                            name="amount"
                                            placeholder={translate(props.lang, "Enter Cost")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={itemForm.amount}
                                            autoComplete="off"
                                            onChange={e => handleItemAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.amount)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                                <Row className="px-0 mx-0">
                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                        <Button variant="primary" className="px-3 px-sm-10 w-100" onClick={() => addItem()}>
                                            {translate(props.lang, "Update")}
                                        </Button>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10 w-100" onClick={() => closeModal()}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewClaim);
