import { faEdit, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { deepCopyArray, getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Payment ', active: true }
]

const PaymentSettingList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        name: '',
        description: '',
        invoice: {
            customer_note: '',
            payment_tnc: '',
        },
        quotation: {
            customer_note: '',
            payment_tnc: '',
        },
        payment_method: '',
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [settingInfo, setSettingInfo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const [editRow, setEditRow] = useState('');
    const [paymentForm, setPaymentForm] = useState({
        name: '',
        description: ''
    });
    const [formData, setFormData] = useState({
        invoice: {
            customer_note: '',
            payment_tnc: '',
        },
        quotation: {
            customer_note: '',
            payment_tnc: '',
        },
        payment_method: [],
    })
    const timerRef = useRef(null);

    useEffect(() => {
        if (firstLoad) {
            getInfo();
            setFirstLoad(false);
        }
    }, [props.auth.auth.token])

    const getInfo = () => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            fetch(api.get_settings, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        let filter = responseJson.data.filter(item => item.category === 'setting');
                        if (filter.length > 0) {
                            setSettingInfo(filter[0].value)
                            setFormData(filter[0].value)
                        }
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/');
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/');
                        } else {
                            console.log('error', responseJson)
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }, 1000);
    }

    const handleAddFormChange = (event, type) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[type][fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        setLoading(true);
        fetch(api.edit_settings, {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false)
                if (responseJson.status === 'success') {
                    Swal.fire({
                        icon: "success",
                        title: translate(props.lang, 'Success'),
                        text: translate(props.lang, "Setting updated successful"),
                        background: getSweelAlertBg(), // Dark background color
                        color: getSweelAlertTextColor(), // Text color
                    });
                    getInfo()
                } else {
                    if (responseJson.message === 'token expired') {
                        dispatch(logout());
                        navigate('/');
                    } else if (responseJson.tokenExpired) {
                        dispatch(logout());
                        navigate('/');
                    } else {
                        if (Array.isArray(responseJson.message)) {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message.join('\n'),
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: translate(props.lang, 'Error'),
                                text: responseJson.message,
                                background: getSweelAlertBg(), // Dark background color
                                color: getSweelAlertTextColor(), // Text color
                            });
                        }
                    }
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const editData = (i) => {
        setEditRow(i);
        let newFormData = { ...paymentForm };
        newFormData.name = formData.payment_method[i].name;
        newFormData.description = formData.payment_method[i].description;
        setPaymentForm(newFormData);
        setModalOpen(true);
    }

    const removeData = (index) => {
        let newFormData = { ...formData };
        let newList = deepCopyArray(formData.payment_method);
        newList.splice(index, 1);
        newFormData.payment_method = newList;
        setFormData(newFormData);
    }

    const addNewRow = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        resetPaymentForm();
        setModalOpen(false)
    }

    const handlePaymentFormChange = (e) => {
        e.preventDefault();
        const fieldName = e.target.getAttribute('name');
        const fieldValue = e.target.value;
        const newFormData = { ...paymentForm };
        newFormData[fieldName] = fieldValue;
        setPaymentForm(newFormData);
    }

    const addItem = () => {
        let check = true;
        let errorMsg = { ...errorObj }
        let data = { ...paymentForm }
        Object.keys(paymentForm).map((item) => {
            if (!paymentForm[item]) {
                check = false
                errorMsg[item] = "This field is required.";
            }
        })
        setErrors(errorMsg)
        if (check) {
            let newFormData = { ...formData };
            if (editRow && editRow) {
                newFormData.payment_method[Number(editRow)] = data;
            } else {
                newFormData.payment_method.push(data);
            }
            setFormData(newFormData);
            closeModal();
            setEditRow(null)
        }
    }

    const resetPaymentForm = () => {
        setPaymentForm({
            name: '',
            description: ''
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Payment Setting")}</h2>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Quotation")}</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.quotation.customer_note ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer Note")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="customer_note"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.quotation.customer_note}
                                    placeholder={translate(props.lang, "Enter Customer Note")}
                                    onChange={e => handleAddFormChange(e, 'quotation')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.quotation.customer_note)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.quotation.payment_tnc ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Terms and Condition")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="payment_tnc"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.quotation.payment_tnc}
                                    placeholder={translate(props.lang, "Enter Payment Terms and Condition")}
                                    onChange={e => handleAddFormChange(e, 'quotation')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.quotation.payment_tnc)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Invoice")}</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.invoice.customer_note ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer Note")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="customer_note"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.invoice.customer_note}
                                    placeholder={translate(props.lang, "Enter Customer Note")}
                                    onChange={e => handleAddFormChange(e, 'invoice')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.invoice.customer_note)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.invoice.payment_tnc ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Terms and Condition")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="payment_tnc"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.invoice.payment_tnc}
                                    placeholder={translate(props.lang, "Enter Description")}
                                    onChange={e => handleAddFormChange(e, 'invoice')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.invoice.payment_tnc)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Payment Method")}</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className='table-responsive'>
                            <Table responsive className="w-100 table-list position-relative">
                                <thead>
                                    <tr>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Name")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Description")}</th>
                                        <th className={`fw-bold fs-8`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.payment_method.map((item, i) => (
                                        <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                            <td className={`fw-normal fs-9 lh-base text-capitalize`}>{item.name}</td>
                                            <td className={`fw-normal fs-9 lh-base break-word break-white-space`}>{item.description}</td>
                                            <td className={`fw-normal fs-8 lh-base`}>
                                                <div className="d-flex">
                                                    <div className="px-2" onClick={() => editData(i.toString())}>
                                                        <FontAwesomeIcon icon={faEdit} color="#839AB4"/>
                                                    </div>
                                                    <div className="px-2" onClick={() => removeData(i)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} color="red" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} className="mb-3">
                        <Button variant="secondary" className='w-100 text-center h-100' onClick={() => addNewRow()}>
                            <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                            {translate(props.lang, "Add New Data")}
                        </Button>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/department/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade" show={modalOpen} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-3">
                    <div className="d-flex w-100 align-items-center px-2">
                        <div className="w-75 text-start">
                            <span className="fw-bold fs-7">{editRow ? translate(props.lang, "Edit Payment Method") : translate(props.lang, "Add Payment Method")}</span>
                        </div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3">
                    <div className='px-2'>
                        <Row className="justify-content-start px-0 mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Name")}</span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'text'}
                                            name="name"
                                            placeholder={translate(props.lang, "Enter Payment Name")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={paymentForm.name}
                                            autoComplete="off"
                                            onChange={e => handlePaymentFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2 px-0">
                                <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                                    <div className={`input-group`}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            className="form-control input-transparent py-1 px-3"
                                            rows={3}
                                            value={paymentForm.description}
                                            placeholder={translate(props.lang, "Enter Description")}
                                            onChange={e => handlePaymentFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3 px-0">
                                <Row className="px-0 mx-0">
                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                        <Button variant="primary" className="px-3 px-sm-10 w-100" onClick={() => addItem()}>
                                            {translate(props.lang, "Update")}
                                        </Button>
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10 w-100" onClick={() => closeModal()}>
                                            {translate(props.lang, "Close")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(PaymentSettingList);
