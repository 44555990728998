import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { api } from 'utils/api';
import { getSweelAlertBg, getSweelAlertTextColor } from 'utils/function';
import { translate } from 'utils/translate';

let breadcrumbData = [
    { label: 'User', url: '#' },
    { label: 'Change Password', active: true },
]

const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.oldPassword) {
            errorMsg.oldPassword = "This field is required.";
            check = false;
        }
        if (!formData.newPassword) {
            errorMsg.newPassword = "This field is required.";
            check = false;
        } else {
            if (formData.newPassword.length < 6) {
                errorMsg.newPassword = "New password must at least 6 characters";
                check = false;
            }
        }
        if (!formData.confirmPassword) {
            errorMsg.confirmPassword = "This field is required.";
            check = false;
        }
        if (formData.newPassword && formData.confirmPassword) {
            if (formData.newPassword && formData.confirmPassword) {
                if (formData.confirmPassword !== formData.newPassword) {
                    errorMsg.confirmPassword = "Confirm password need to same with new password";
                    check = false;
                }
            }
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.change_my_password, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        Swal.fire({
                            icon: "success",
                            title: translate(props.lang, 'Success'),
                            text: translate(props.lang, "Password has been updated successfully."),
                            background: getSweelAlertBg(), // Dark background color
                            color: getSweelAlertTextColor(), // Text color
                        });
                        resetForm()
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout());
                            navigate('/')
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout());
                            navigate('/')
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message.join('\n'),
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: translate(props.lang, 'Error'),
                                    text: responseJson.message,
                                    background: getSweelAlertBg(), // Dark background color
                                    color: getSweelAlertTextColor(), // Text color
                                });
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setErrors(errorObj);
        setFormData({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "Change Password")}</h2>
            <Row className='align-items-start justify-content-center'>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.oldPassword ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Current Password")} <span className="text-danger">*</span></span>
                        <Row className="align-items-center justify-content-start px-0 mx-0">
                            <Col lg={11} md={11} sm={11} xs={11} className='px-0'>
                                <div className={`input-group`}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        name="oldPassword"
                                        placeholder={translate(props.lang, "Enter Current Password")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={formData.oldPassword}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1} className='pe-3 ps-0 text-end' onClick={() => setShowPassword(!showPassword)}>
                                <FontAwesomeIcon icon={faEye} className="text-body fs-9 form-icon" />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.oldPassword)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.newPassword ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "New Password")} <span className="text-danger">*</span></span>
                        <Row className="align-items-center justify-content-start px-0 mx-0">
                            <Col lg={11} md={11} sm={11} xs={11} className='px-0'>
                                <div className={`input-group`}>
                                    <input
                                        type={showNewPassword ? 'text' : 'password'}
                                        name="newPassword"
                                        placeholder={translate(props.lang, "Enter New Password")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={formData.newPassword}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1} className='pe-3 ps-0 text-end' onClick={() => setShowNewPassword(!showNewPassword)}>
                                <FontAwesomeIcon icon={faEye} className="text-body fs-9 form-icon" />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.newPassword)}</span>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                    <div className={`groupLabel-input py-1 ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                        <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Confirm Password")} <span className="text-danger">*</span></span>
                        <Row className="align-items-center justify-content-start px-0 mx-0">
                            <Col lg={11} md={11} sm={11} xs={11} className='px-0'>
                                <div className={`input-group`}>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name="confirmPassword"
                                        placeholder={translate(props.lang, "Enter Confirm Password")}
                                        className="form-control input-transparent py-1 px-3"
                                        value={formData.confirmPassword}
                                        autoComplete="off"
                                        onChange={e => handleAddFormChange(e)}
                                    />
                                </div>
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1} className='pe-3 ps-0 text-end' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                <FontAwesomeIcon icon={faEye} className="text-body fs-9 form-icon" />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.confirmPassword)}</span>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                    <div className="d-flex justify-content-start gap-3">
                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                            {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                        </Button>
                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => resetForm()}>
                            {translate(props.lang, "Cancel")}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ChangePassword);
