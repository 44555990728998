import AppProvider from 'providers/AppProvider';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import {Provider} from 'react-redux';
import { store } from 'store/store';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import Index from './page/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <Provider store={store}>
    <AppProvider>
      <SettingsPanelProvider>
        <ChatWidgetProvider>
          <BreakpointsProvider>
          <Index />
            {/* <RouterProvider router={router} /> */}
          </BreakpointsProvider>
        </ChatWidgetProvider>
      </SettingsPanelProvider>
    </AppProvider>
    </Provider>
  </StrictMode>
);
