import App from 'App';
import PhoenixLoader from 'components/common/PhoenixLoader';
import MainLayout from 'layouts/MainLayout';
import AnnouncementList from 'page/Announcement/AnnouncementList';
import EditAnnouncement from 'page/Announcement/EditAnnouncement';
import NewAnnouncement from 'page/Announcement/NewAnnouncement';
import ClaimList from 'page/Claim/ClaimList';
import NewClaim from 'page/Claim/NewClaim';
import ClaimRequestList from 'page/ClaimRequest/ClaimRequestList';
import ClaimSettingList from 'page/ClaimSetting/ClaimSettingList';
import CustomerList from 'page/Customer/CustomerList';
import EditCustomer from 'page/Customer/EditCustomer';
import NewCustomer from 'page/Customer/NewCustomer';
import DashboardOverview from 'page/Dashboard/DashboardOverview';
import DepartmentList from 'page/Department/DepartmentList';
import EditDepartment from 'page/Department/EditDepartment';
import NewDepartment from 'page/Department/NewDepartment';
import ForumList from 'page/Forum/ForumList';
import EditInvoice from 'page/Invoice/EditInvoice';
import InvoiceList from 'page/Invoice/InvoiceList';
import NewInvoice from 'page/Invoice/NewInvoice';
import EditJobPosition from 'page/JobPosition/EditJobPosition';
import JobPositionList from 'page/JobPosition/JobPositionList';
import NewJobPosition from 'page/JobPosition/NewJobPosition';
import EditOrganization from 'page/Organization/EditOrganization';
import NewOrganization from 'page/Organization/NewOrganization';
import OrganizationList from 'page/Organization/OrganizationList';
import PaymentList from 'page/Payment/PaymentList';
import PaymentSettingList from 'page/PaymentSetting/PaymentSettingList';
import EditProject from 'page/Project/EditProject';
import NewProject from 'page/Project/NewProject';
import ProjectList from 'page/Project/ProjectList';
import EditQuotation from 'page/Quotation/EditQuotation';
import NewQuotation from 'page/Quotation/NewQuotation';
import QuotationList from 'page/Quotation/QuotationList';
import EditRanking from 'page/Ranking/EditRanking';
import NewRanking from 'page/Ranking/NewRanking';
import RankingList from 'page/Ranking/RankingList';
import Report from 'page/Report/Report';
import NewTask from 'page/Task/NewTask';
import TaskList from 'page/Task/TaskList';
import EditTaskType from 'page/TaskType/EditTaskType';
import NewTaskType from 'page/TaskType/NewTaskType';
import TaskTypeList from 'page/TaskType/TaskTypeList';
import OTList from 'page/Timesheet/OTList';
import TimesheetList from 'page/Timesheet/TimesheetList';
import EditUser from 'page/User/EditUser';
import NewUser from 'page/User/NewUser';
import UserList from 'page/User/UserList';
import UserAnnoucementDetails from 'page/UserAnnouncement/UserAnnoucementDetails';
import UserAnnouncementList from 'page/UserAnnouncement/UserAnnouncementList';
import EditUserGroup from 'page/UserGroup/EditUserGroup';
import NewUserGroup from 'page/UserGroup/NewUserGroup';
import UserGroupList from 'page/UserGroup/UserGroupList';
import AddContact from 'pages/apps/crm/AddContact';
import Analytics from 'pages/apps/crm/Analytics';
import DealDetails from 'pages/apps/crm/DealDetails';
import Deals from 'pages/apps/crm/Deals';
import LeadDetails from 'pages/apps/crm/LeadDetails';
import Leads from 'pages/apps/crm/Leads';
import ReportDetails from 'pages/apps/crm/ReportDetails';
import Reports from 'pages/apps/crm/Reports';
import AddProduct from 'pages/apps/e-commerce/admin/AddProduct';
import CustomerDetails from 'pages/apps/e-commerce/admin/CustomerDetails';
import Customers from 'pages/apps/e-commerce/admin/Customers';
import OrderDetails from 'pages/apps/e-commerce/admin/OrderDetails';
import Orders from 'pages/apps/e-commerce/admin/Orders';
import Products from 'pages/apps/e-commerce/admin/Products';
import Refund from 'pages/apps/e-commerce/admin/Refund';
import CreateNew from 'pages/apps/project-management/CreateNew';
import ProjectBoardView from 'pages/apps/project-management/ProjectBoardView';
import ProjectCardView from 'pages/apps/project-management/ProjectCardView';
// import ProjectDetails from 'pages/apps/project-management/ProjectDetails';
import ProjectListView from 'pages/apps/project-management/ProjectListView';
import ProjectTodoList from 'pages/apps/project-management/ProjectTodoList';
import ComingSoon from 'pages/ComingSoon';
import Crm from 'pages/dashboard/Crm';
import ChangeLog from 'pages/documentation/ChangeLog';
import Configuration from 'pages/documentation/customization/Configuration';
import DarkMode from 'pages/documentation/customization/DarkMode';
import Styling from 'pages/documentation/customization/Styling';
import DesignFile from 'pages/documentation/DesignFile';
import GettingStarted from 'pages/documentation/GettingStarted';
import Migrations from 'pages/documentation/Migrations';
import Error404 from 'pages/error/Error404';
import FaqAccordion from 'pages/faq/FaqAccordion';
import FaqTab from 'pages/faq/FaqTab';
import Members from 'pages/Members';
import ECharts from 'pages/modules/charts/ECharts';
import GanttChart from 'pages/modules/charts/GanttChart';
import AccordionExample from 'pages/modules/components/AccordionExample';
import AlertsExample from 'pages/modules/components/AlertsExample';
import AvatarExample from 'pages/modules/components/AvatarExample';
import BadgeExample from 'pages/modules/components/BadgeExample';
import BootstrapCarousel from 'pages/modules/components/BootstrapCarousel';
import BreadcrumbExample from 'pages/modules/components/BreadcrumbExample';
import ButtonExample from 'pages/modules/components/ButtonExample';
import CalendarExample from 'pages/modules/components/CalendarExample';
import CardExample from 'pages/modules/components/CardExample';
import ChecksExample from 'pages/modules/components/ChecksExample';
import CollapseExample from 'pages/modules/components/CollapseExample';
import CountupExample from 'pages/modules/components/CountupExample';
import DraggableExample from 'pages/modules/components/DraggableExample';
import DropdownExample from 'pages/modules/components/DropdownExample';
import FloatingLabelExample from 'pages/modules/components/FloatingLabelExample';
import FormLayoutExample from 'pages/modules/components/FormLayoutExample';
import FormValidationExample from 'pages/modules/components/FormValidationExample';
import LightboxExample from 'pages/modules/components/LightboxExample';
import ListGroupExample from 'pages/modules/components/ListGroupExample';
import ModalExample from 'pages/modules/components/ModalExample';
import NavbarExample from 'pages/modules/components/NavbarExample';
import NavsExample from 'pages/modules/components/NavsExample';
import OffcanvasExample from 'pages/modules/components/OffcanvasExample';
import PaginationExample from 'pages/modules/components/PaginationExample';
import PlaceholderExample from 'pages/modules/components/PlaceholderExample';
import PopoversExample from 'pages/modules/components/PopoversExample';
import ProgressbarExample from 'pages/modules/components/ProgressbarExample';
import RangeExample from 'pages/modules/components/RangeExample';
import SelectExample from 'pages/modules/components/SelectExample';
import SpinnerExample from 'pages/modules/components/SpinnerExample';
import SwiperCarousel from 'pages/modules/components/SwiperCarousel';
import TabsExample from 'pages/modules/components/TabsExample';
import ToastsExample from 'pages/modules/components/ToastsExample';
import TooltipExample from 'pages/modules/components/TooltipExample';
import AdvanceSelectExample from 'pages/modules/forms/advance/AdvanceSelectExample';
import DatePickerExample from 'pages/modules/forms/advance/DatePickerExample';
import EditorExample from 'pages/modules/forms/advance/EditorExample';
import EmojiButtonExample from 'pages/modules/forms/advance/EmojiButtonExample';
import FileUploaderExample from 'pages/modules/forms/advance/FileUploaderExample';
import RatingExample from 'pages/modules/forms/advance/RatingExample';
import FormControlExample from 'pages/modules/forms/basic/FormControlExample';
import InputGroupExample from 'pages/modules/forms/basic/InputGroupExample';
import WizardExample from 'pages/modules/forms/WizardExample';
import AdvanceTableExample from 'pages/modules/tables/AdvanceTableExample';
import BasicTableExample from 'pages/modules/tables/BasicTableExample';
import BackgroundExample from 'pages/modules/utilities/BackgroundExample';
import BorderExample from 'pages/modules/utilities/BorderExample';
import ColorsExample from 'pages/modules/utilities/ColorsExample';
import DisplayExample from 'pages/modules/utilities/DisplayExample';
import FlexExample from 'pages/modules/utilities/FlexExample';
import FloatExample from 'pages/modules/utilities/FloatExample';
import GridExample from 'pages/modules/utilities/GridExample';
import InteractionsExample from 'pages/modules/utilities/InteractionsExample';
import OpacityExample from 'pages/modules/utilities/OpacityExample';
import OverflowExample from 'pages/modules/utilities/OverflowExample';
import PositionExample from 'pages/modules/utilities/PositionExample';
import ShadowsExample from 'pages/modules/utilities/ShadowsExample';
import SizingExample from 'pages/modules/utilities/SizingExample';
import SpacingExample from 'pages/modules/utilities/SpacingExample';
import StackExample from 'pages/modules/utilities/StackExample';
import TypographyExample from 'pages/modules/utilities/TypographyExample';
import VerticalAlignExample from 'pages/modules/utilities/VerticalAlignExample';
import VisibilityExample from 'pages/modules/utilities/VisibilityExample';
import Widgets from 'pages/modules/Widgets';
import Notification from 'pages/notifications/Notifications';
import ComboNav from 'pages/pages/layouts/ComboNav';
import ComboNavSlim from 'pages/pages/layouts/ComboNavSlim';
import DarkModeDemo from 'pages/pages/layouts/DarkModeDemo';
import Darknav from 'pages/pages/layouts/Darknav';
import DualNav from 'pages/pages/layouts/DualNav';
import NavbarHorizontalSlim from 'pages/pages/layouts/NavbarHorizontalSlim';
import NavbarTop from 'pages/pages/layouts/NavbarTop';
import NavbarTopSlim from 'pages/pages/layouts/NavbarTopSlim';
import SidenavCollapse from 'pages/pages/layouts/SidenavCollapse';
import TopnavSlim from 'pages/pages/layouts/TopnavSlim';
import VerticalSidenav from 'pages/pages/layouts/VerticalSidenav';
import PricingColumn from 'pages/pages/pricing/PricingColumn';
import PricingGrid from 'pages/pages/pricing/PricingGrid';
import Starter from 'pages/pages/Starter';
import Timeline from 'pages/pages/Timeline';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Color from './pages/documentation/customization/Color';
import NewTimesheet from 'page/Timesheet/NewTimesheet';
import EditTimesheet from 'page/Timesheet/EditTimesheet';
import NewClaimSetting from 'page/ClaimSetting/NewClaimSetting';
import EditClaimSetting from 'page/ClaimSetting/EditClaimSetting';
import ChangePassword from 'page/Profile/ChangePassword';
import Profile from 'page/Profile/Profile';
import ProjectDetails from 'page/Project/ProjectDetails';

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);

const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./page/Login')), 1500);
  });
});
const routes = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <DashboardOverview />
          },
          {
            path: '/dashboard',
            children: [
              {
                index: true,
                element: <DashboardOverview />
              },
              {
                path: 'crm',
                element: <Crm />
              }
            ]
          },
          {
            path: '/change-password',
            children: [
              {
                index: true,
                element: <ChangePassword />
              },
            ]
          },
          {
            path: '/profile',
            children: [
              {
                index: true,
                element: <Profile />
              },
            ]
          },
          {
            path: '/user-announcement',
            children: [
              {
                index: true,
                element: <UserAnnouncementList />
              },
              {
                path: ':id',
                element: <UserAnnoucementDetails />
              },
            ]
          },
          {
            path: '/project',
            children: [
              {
                path: 'new',
                element: <NewProject />
              },
              {
                path: 'edit/:id',
                element: <EditProject />
              },
              {
                path: 'details/:id',
                element: <ProjectDetails />
              },
            ]
          },
          {
            path: '/forum',
            children: [
              {
                path: 'overview',
                element: <ForumList />
              },
            ]
          },
          {
            path: '/claim',
            children: [
              {
                path: 'overview',
                element: <ClaimList />
              },
              {
                path: 'new',
                element: <NewClaim />
              },
            ]
          },
          {
            path: '/claim-setting',
            children: [
              {
                path: 'overview',
                element: <ClaimSettingList />
              },
              {
                path: 'new',
                element: <NewClaimSetting />
              },
              {
                path: 'edit/:id',
                element: <EditClaimSetting />
              },
            ]
          },
          {
            path: '/customer',
            children: [
              {
                path: 'overview',
                element: <CustomerList />
              },
              {
                path: 'new',
                element: <NewCustomer />
              },
              { 
                path: 'edit/:id',
                element: <EditCustomer />
              },
            ]
          },
          {
            path: '/organization',
            children: [
              {
                path: 'overview',
                element: <OrganizationList />
              },
              {
                path: 'new',
                element: <NewOrganization />
              },
              {
                path: 'edit/:id',
                element: <EditOrganization />
              },
            ]
          },
          {
            path: '/payment',
            children: [
              {
                path: 'setting',
                element: <PaymentSettingList/>
              },
              {
                path: 'overview',
                element: <PaymentList/>
              },
            ]
          },
          {
            path: '/department',
            children: [
              {
                path: 'overview',
                element: <DepartmentList />
              },
              {
                path: 'new',
                element: <NewDepartment />
              },
              {
                path: 'edit/:id',
                element: <EditDepartment />
              },
            ]
          },
          {
            path: '/job-position',
            children: [
              {
                path: 'overview',
                element: <JobPositionList />
              },
              {
                path: 'new',
                element: <NewJobPosition />
              },
              {
                path: 'edit/:id',
                element: <EditJobPosition />
              },
            ]
          },
          {
            path: '/ranking',
            children: [
              {
                path: 'overview',
                element: <RankingList />
              },
              {
                path: 'new',
                element: <NewRanking />
              },
              {
                path: 'edit/:id',
                element: <EditRanking />
              },
            ]
          },
          {
            path: '/task-type',
            children: [
              {
                path: 'overview',
                element: <TaskTypeList />
              },
              {
                path: 'new',
                element: <NewTaskType />
              },
              {
                path: 'edit/:id',
                element: <EditTaskType />
              },
            ]
          },
          {
            path: '/user',
            children: [
              {
                path: 'overview',
                element: <UserList />
              },
              {
                path: 'new',
                element: <NewUser />
              },
              {
                path: 'edit/:id',
                element: <EditUser />
              },
            ]
          },
          {
            path: '/task',
            children: [
              {
                path: 'overview',
                element: <TaskList />
              },
              {
                path: 'new',
                element: <NewTask />
              },
            ]
          },
          {
            path: '/quotation',
            children: [
              {
                path: 'overview',
                element: <QuotationList />
              },
              {
                path: 'new',
                element: <NewQuotation />
              },
              {
                path: 'edit/:id',
                element: <EditQuotation />
              },
            ]
          },
          {
            path: '/invoice',
            children: [
              {
                path: 'overview',
                element: <InvoiceList />
              },
              {
                path: 'new',
                element: <NewInvoice />
              },
              {
                path: 'edit/:id',
                element: <EditInvoice />
              },
            ]
          },
          {
            path: '/claim-request',
            children: [
              {
                path: 'overview',
                element: <ClaimRequestList />
              },
            ]
          },
          {
            path: '/user-group',
            children: [
              {
                path: 'overview',
                element: <UserGroupList />
              },
              {
                path: 'new',
                element: <NewUserGroup />
              },
              {
                path: 'edit/:id',
                element: <EditUserGroup />
              },
            ]
          },
          {
            path: '/announcement',
            children: [
              {
                path: 'overview',
                element: <AnnouncementList />
              },
              {
                path: 'new',
                element: <NewAnnouncement />
              },
              {
                path: 'edit/:id',
                element: <EditAnnouncement />
              },
            ]
          },
          {
            path: '/project',
            children: [
              {
                path: 'overview',
                element: <ProjectList />
              },
            ]
          },
         
          {
            path: '/timesheet',
            children: [
              {
                path: 'daily-task',
                element: <TimesheetList />
              },
              {
                path: 'overtime',
                element: <OTList />
              },
              {
                path: 'new',
                element: <NewTimesheet />
              },
              {
                path: 'edit',
                element: <EditTimesheet />
              },
            ]
          },
          {
            path: '/report',
            children: [
              {
                path: 'overview',
                element: <Report />
              },
            ]
          },
          {
            path: '/apps',
            children: [
              {
                path: 'e-commerce/admin',
                children: [
                  {
                    path: 'add-product',
                    element: <AddProduct />
                  },
                  {
                    path: 'products',
                    element: <Products />
                  },
                  {
                    path: 'customers',
                    element: <Customers />
                  },
                  {
                    path: 'orders',
                    element: <Orders />
                  },
                  {
                    path: 'order-details',
                    element: <OrderDetails />
                  },
                  {
                    path: 'refund',
                    element: <Refund />
                  },
                  {
                    path: 'customer-details',
                    element: <CustomerDetails />
                  }
                ]
              },
              {
                path: 'crm',
                children: [
                  {
                    path: 'analytics',
                    element: <Analytics />
                  },
                  {
                    path: 'deals',
                    element: <Deals />
                  },
                  {
                    path: 'deal-details',
                    element: <DealDetails />
                  },
                  {
                    path: 'report-details',
                    element: <ReportDetails />
                  },
                  {
                    path: 'leads',
                    element: <Leads />
                  },
                  {
                    path: 'lead-details',
                    element: <LeadDetails />
                  },
                  {
                    path: 'reports',
                    element: <Reports />
                  },
                  {
                    path: 'add-contact',
                    element: <AddContact />
                  }
                ]
              },
              {
                path: 'project-management',
                children: [
                  {
                    path: 'create-new',
                    element: <CreateNew />
                  },
                  {
                    path: 'project-list-view',
                    element: <ProjectListView />
                  },
                  {
                    path: 'project-card-view',
                    element: <ProjectCardView />
                  },
                  {
                    path: 'project-board-view',
                    element: <ProjectBoardView />
                  },
                  {
                    path: 'todo-list',
                    element: <ProjectTodoList />
                  },
                  // {
                  //   path: 'project-details',
                  //   element: <ProjectDetails />
                  // }
                ]
              },
              // {
              //   path: 'events',
              //   children: [
              //     {
              //       path: 'create-an-event',
              //       element: <CreateAnEvent />
              //     },
              //     {
              //       path: 'event-detail',
              //       element: <EventDetail />
              //     }
              //   ]
              // },
              // {
              //   path: 'kanban',
              //   children: [
              //     {
              //       path: 'kanban',
              //       element: <Kanban />
              //     },
              //     {
              //       path: 'boards',
              //       element: <Boards />
              //     },
              //     {
              //       path: 'create-board',
              //       element: <CreateBoard />
              //     }
              //   ]
              // },
              // {
              //   path: 'chat',
              //   element: <Chat />,
              //   children: [
              //     {
              //       index: true,
              //       element: <ChatHomepage />
              //     },
              //     {
              //       path: ':userId/conversation',
              //       element: <ChatConversation />
              //     }
              //   ]
              // },
              // {
              //   path: 'social',
              //   children: [
              //     {
              //       path: 'profile',
              //       element: <SocialProfile />
              //     },
              //     {
              //       path: 'settings',
              //       element: <Settings />
              //     },
              //     {
              //       path: 'feed',
              //       element: <Feed />
              //     }
              //   ]
              // },
              // {
              //   path: 'calendar',
              //   element: <Calendar />
              // },
              // {
              //   path: 'email',
              //   children: [
              //     {
              //       path: 'inbox',
              //       element: <Inbox />
              //     },
              //     {
              //       path: 'email-detail',
              //       element: <EmailDetail />
              //     },
              //     {
              //       path: 'compose',
              //       element: <Compose />
              //     }
              //   ]
              // }
            ]
          },
          {
            path: '/pages',
            children: [
              {
                path: 'starter',
                element: <Starter />
              },
              {
                path: 'faq',
                children: [
                  {
                    path: 'faq-accordion',
                    element: <FaqAccordion />
                  },
                  {
                    path: 'faq-tab',
                    element: <FaqTab />
                  }
                ]
              },
              {
                path: 'pricing',
                children: [
                  {
                    path: 'pricing-column',
                    element: <PricingColumn />
                  },
                  {
                    path: 'pricing-grid',
                    element: <PricingGrid />
                  }
                ]
              },
              {
                path: 'notifications',
                element: <Notification />
              },
              {
                path: 'members',
                element: <Members />
              },
              {
                path: 'timeline',
                element: <Timeline />
              },
              {
                path: 'coming-soon',
                element: <ComingSoon />
              },
              {
                path: 'demo',
                children: [
                  {
                    path: 'vertical-sidenav',
                    element: <VerticalSidenav />
                  },
                  {
                    path: 'dark-mode',
                    element: <DarkModeDemo />
                  },
                  {
                    path: 'sidenav-collapse',
                    element: <SidenavCollapse />
                  },
                  {
                    path: 'darknav',
                    element: <Darknav />
                  },
                  {
                    path: 'topnav-slim',
                    element: <TopnavSlim />
                  },
                  {
                    path: 'navbar-top-slim',
                    element: <NavbarTopSlim />
                  },
                  {
                    path: 'navbar-top',
                    element: <NavbarTop />
                  },
                  {
                    path: 'horizontal-slim',
                    element: <NavbarHorizontalSlim />
                  },
                  {
                    path: 'combo-nav',
                    element: <ComboNav />
                  },
                  {
                    path: 'combo-nav-slim',
                    element: <ComboNavSlim />
                  },
                  {
                    path: 'dual-nav',
                    element: <DualNav />
                  }
                ]
              }
            ]
          },
          {
            path: '/modules',
            children: [
              {
                path: 'tables',
                children: [
                  {
                    path: 'basic-tables',
                    element: <BasicTableExample />
                  },
                  {
                    path: 'advance-tables',
                    element: <AdvanceTableExample />
                  }
                ]
              },
              {
                path: 'charts',
                children: [
                  {
                    path: 'e-charts',
                    element: <ECharts />
                  },
                  {
                    path: 'gantt-chart',
                    element: <GanttChart />
                  }
                ]
              },
              {
                path: 'icons',
                children: [
                  {
                    path: 'font-awesome',
                    element: (
                      <Suspense fallback={<PhoenixLoader />}>
                        <FontAwesomeExample />
                      </Suspense>
                    )
                  },
                  {
                    path: 'feather',
                    element: (
                      <Suspense fallback={<PhoenixLoader />}>
                        <FeatherIconsExample />
                      </Suspense>
                    )
                  },
                  {
                    path: 'unicons',
                    element: (
                      <Suspense fallback={<PhoenixLoader />}>
                        <UniconsExample />
                      </Suspense>
                    )
                  }
                ]
              },
              {
                path: 'components',
                children: [
                  {
                    path: 'accordion',
                    element: <AccordionExample />
                  },
                  {
                    path: 'avatar',
                    element: <AvatarExample />
                  },
                  {
                    path: 'alerts',
                    element: <AlertsExample />
                  },
                  {
                    path: 'button',
                    element: <ButtonExample />
                  },
                  {
                    path: 'badge',
                    element: <BadgeExample />
                  },
                  {
                    path: 'breadcrumb',
                    element: <BreadcrumbExample />
                  },
                  {
                    path: 'card',
                    element: <CardExample />
                  },
                  {
                    path: 'carousel/bootstrap',
                    element: <BootstrapCarousel />
                  },
                  {
                    path: 'carousel/swiper',
                    element: <SwiperCarousel />
                  },
                  {
                    path: 'collapse',
                    element: <CollapseExample />
                  },
                  {
                    path: 'dropdown',
                    element: <DropdownExample />
                  },
                  {
                    path: 'list-group',
                    element: <ListGroupExample />
                  },
                  {
                    path: 'countup',
                    element: <CountupExample />
                  },
                  {
                    path: 'draggable',
                    element: <DraggableExample />
                  },
                  {
                    path: 'modal',
                    element: <ModalExample />
                  },
                  {
                    path: 'offcanvas',
                    element: <OffcanvasExample />
                  },
                  {
                    path: 'progress-bar',
                    element: <ProgressbarExample />
                  },
                  {
                    path: 'placeholder',
                    element: <PlaceholderExample />
                  },
                  {
                    path: 'pagination',
                    element: <PaginationExample />
                  },
                  {
                    path: 'popovers',
                    element: <PopoversExample />
                  },
                  {
                    path: 'spinners',
                    element: <SpinnerExample />
                  },
                  {
                    path: 'toast',
                    element: <ToastsExample />
                  },
                  {
                    path: 'tooltips',
                    element: <TooltipExample />
                  },
                  {
                    path: 'calendar',
                    element: <CalendarExample />
                  },
                  {
                    path: 'navs-and-tabs/navs',
                    element: <NavsExample />
                  },
                  {
                    path: 'navs-and-tabs/tabs',
                    element: <TabsExample />
                  },
                  {
                    path: 'navs-and-tabs/navbar',
                    element: <NavbarExample />
                  },
                  {
                    path: 'pictures/lightbox',
                    element: <LightboxExample />
                  }
                ]
              },
              {
                path: 'forms',
                children: [
                  {
                    path: 'form-control',
                    element: <FormControlExample />
                  },
                  {
                    path: 'input-group',
                    element: <InputGroupExample />
                  },
                  {
                    path: 'select',
                    element: <SelectExample />
                  },
                  {
                    path: 'checks',
                    element: <ChecksExample />
                  },
                  {
                    path: 'range',
                    element: <RangeExample />
                  },
                  {
                    path: 'floating-labels',
                    element: <FloatingLabelExample />
                  },
                  {
                    path: 'layout',
                    element: <FormLayoutExample />
                  },
                  {
                    path: 'editor',
                    element: <EditorExample />
                  },
                  {
                    path: 'advance-select',
                    element: <AdvanceSelectExample />
                  },
                  {
                    path: 'date-picker',
                    element: <DatePickerExample />
                  },
                  {
                    path: 'emoji-button',
                    element: <EmojiButtonExample />
                  },
                  {
                    path: 'file-uploader',
                    element: <FileUploaderExample />
                  },
                  {
                    path: 'rating',
                    element: <RatingExample />
                  },
                  {
                    path: 'validation',
                    element: <FormValidationExample />
                  },
                  {
                    path: 'wizard',
                    element: <WizardExample />
                  }
                ]
              },
              {
                path: 'utilities',
                children: [
                  {
                    path: 'background',
                    element: <BackgroundExample />
                  },
                  {
                    path: 'borders',
                    element: <BorderExample />
                  },
                  {
                    path: 'colors',
                    element: <ColorsExample />
                  },
                  {
                    path: 'display',
                    element: <DisplayExample />
                  },
                  {
                    path: 'grid',
                    element: <GridExample />
                  },
                  {
                    path: 'flex',
                    element: <FlexExample />
                  },
                  {
                    path: 'stack',
                    element: <StackExample />
                  },
                  {
                    path: 'float',
                    element: <FloatExample />
                  },
                  {
                    path: 'interactions',
                    element: <InteractionsExample />
                  },
                  {
                    path: 'opacity',
                    element: <OpacityExample />
                  },
                  {
                    path: 'overflow',
                    element: <OverflowExample />
                  },
                  {
                    path: 'position',
                    element: <PositionExample />
                  },
                  {
                    path: 'shadows',
                    element: <ShadowsExample />
                  },
                  {
                    path: 'sizing',
                    element: <SizingExample />
                  },
                  {
                    path: 'spacing',
                    element: <SpacingExample />
                  },
                  {
                    path: 'typography',
                    element: <TypographyExample />
                  },
                  {
                    path: 'vertical-align',
                    element: <VerticalAlignExample />
                  },
                  {
                    path: 'visibility',
                    element: <VisibilityExample />
                  }
                ]
              }
            ]
          },
          {
            path: '/documentation',
            children: [
              {
                path: 'getting-started',
                element: <GettingStarted />
              },
              {
                path: 'design-file',
                element: <DesignFile />
              },
              {
                path: 'customization',
                children: [
                  {
                    path: 'configuration',
                    element: <Configuration />
                  },
                  {
                    path: 'color',
                    element: <Color />
                  },
                  {
                    path: 'styling',
                    element: <Styling />
                  },
                  {
                    path: 'dark-mode',
                    element: <DarkMode />
                  }
                ]
              }
            ]
          },
          {
            path: 'changelog',
            element: <ChangeLog />
          },
          {
            path: 'migrations',
            element: <Migrations />
          },
          {
            path: 'widgets',
            element: <Widgets />
          }
        ]
      },

      // {
      //   element: <EcommerceLayout />,
      //   path: '/apps/e-commerce/customer/',
      //   children: [
      //     {
      //       path: 'homepage',
      //       element: <Homepage />
      //     },
      //     {
      //       path: 'product-details',
      //       element: <ProductDetails />
      //     },
      //     {
      //       path: 'cart',
      //       element: <Cart />
      //     },
      //     {
      //       path: 'checkout',
      //       element: <Checkout />
      //     },
      //     {
      //       path: 'shipping-info',
      //       element: <ShippingInfo />
      //     },
      //     {
      //       path: 'profile',
      //       element: <Profile />
      //     },
      //     {
      //       path: 'products-filter',
      //       element: <ProductsFilter />
      //     },
      //     {
      //       path: 'wishlist',
      //       element: <Wishlist />
      //     },
      //     {
      //       path: 'favorite-stores',
      //       element: <FavoriteStores />
      //     },
      //     {
      //       path: 'order-tracking',
      //       element: <OrderTracking />
      //     },
      //     {
      //       path: 'invoice',
      //       element: <Invoice />
      //     }
      //   ]
      // },
      // {
      //   path: 'pages/landing',
      //   children: [
      //     {
      //       path: 'default',
      //       element: <Default />
      //     },
      //     {
      //       path: 'alternate',
      //       element: <Alternate />
      //     }
      //   ]
      // },
      // {
      //   path: '/pages/errors/',
      //   children: [
      //     {
      //       path: '404',
      //       element: <Error404 />
      //     },
      //     {
      //       path: '403',
      //       element: <Error403 />
      //     },
      //     {
      //       path: '500',
      //       element: <Error500 />
      //     }
      //   ]
      // },
      // {
      //   path: '/pages/authentication/simple/',
      //   children: [
      //     {
      //       path: 'sign-in',
      //       element: <SimpleSignIn />
      //     },
      //     {
      //       path: 'sign-up',
      //       element: <SimpleSignUp />
      //     },
      //     {
      //       path: 'sign-out',
      //       element: <SimpleSignOut />
      //     },
      //     {
      //       path: 'forgot-password',
      //       element: <SimpleForgotPassword />
      //     },
      //     {
      //       path: 'reset-password',
      //       element: <SimpleResetPassword />
      //     },
      //     {
      //       path: 'lock-screen',
      //       element: <SimpleLockScreen />
      //     },
      //     {
      //       path: '2FA',
      //       element: <SimpleTwoFA />
      //     }
      //   ]
      // },
      // {
      //   path: '/pages/authentication/split/',
      //   children: [
      //     {
      //       path: 'sign-in',
      //       element: <SplitSignIn />
      //     },
      //     {
      //       path: 'sign-up',
      //       element: <SplitSignUp />
      //     },
      //     {
      //       path: 'sign-out',
      //       element: <SplitSignOut />
      //     },
      //     {
      //       path: 'forgot-password',
      //       element: <SplitForgotPassword />
      //     },
      //     {
      //       path: 'reset-password',
      //       element: <SplitResetPassword />
      //     },
      //     {
      //       path: 'lock-screen',
      //       element: <SplitLockScreen />
      //     },
      //     {
      //       path: '2FA',
      //       element: <SplitTwoFA />
      //     }
      //   ]
      // },
      // {
      //   path: '/pages/authentication/card/',
      //   children: [
      //     {
      //       path: 'sign-in',
      //       element: <CardSignIn />
      //     },
      //     {
      //       path: 'sign-up',
      //       element: <CardSignUp />
      //     },
      //     {
      //       path: 'sign-out',
      //       element: <CardSignOut />
      //     },
      //     {
      //       path: 'forgot-password',
      //       element: <CardForgotPassword />
      //     },
      //     {
      //       path: 'reset-password',
      //       element: <CardResetPassword />
      //     },
      //     {
      //       path: 'lock-screen',
      //       element: <CardLockScreen />
      //     },
      //     {
      //       path: '2FA',
      //       element: <CardTwoFA />
      //     }
      //   ]
      // },
      // {
      //   path: '/',
      //   children: [
      //     {
      //       path: 'login',
      //       element: (
      //         <Suspense fallback={
      //           <PageLoader />
      //         }>
      //           <Login />
      //         </Suspense>
      //       )
      //     },
      //     {
      //       path: 'sign-up',
      //       element: <CardSignUp />
      //     },
      //     {
      //       path: 'sign-out',
      //       element: <CardSignOut />
      //     },
      //     {
      //       path: 'forgot-password',
      //       element: <CardForgotPassword />
      //     },
      //     {
      //       path: 'reset-password',
      //       element: <CardResetPassword />
      //     },
      //     {
      //       path: 'lock-screen',
      //       element: <CardLockScreen />
      //     },
      //     {
      //       path: '2FA',
      //       element: <CardTwoFA />
      //     }
      //   ]
      // },
      // {
      //   path: 'showcase',
      //   element: <Showcase />
      // },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
