import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { translate } from 'utils/translate';
import { api } from 'utils/api';
import customSelectStyles from 'utils/customSelectStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import customSelectDarkStyles from 'utils/customSelectDarkStyles';

let breadcrumbData = [
    { label: 'Payment', url: '#' },
    { label: 'Invoice', url: '/invoice/overview' },
    { label: 'New Invoice ', active: true }
]

const data = [
    { name: 'task 1', quantity: 1, price: 10, tax: 1, discount: 0, amount: 100 },
]

const NewInvoice = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let errorObj = {
        name: null,
        organizationId: null,
        code: null,
        description: null,
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false)
    const [editRow, setEditRow] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        organizationId: '',
        code: '',
        description: '',
    })
    const { config } = useAppContext();

    useEffect(() => {
        getOrganization();
    }, [props.auth.auth.token])

    const getOrganization = () => {
        fetch(api.get_organizations, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === "success") {
                    setOrganizationList(responseJson.data.data);
                } else if (responseJson.status === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    console.log('error company', responseJson.message)
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.name) {
            errorMsg.name = "This field is required.";
            check = false;
        }
        if (!formData.organizationId) {
            errorMsg.organizationId = "This field is required.";
            check = false;
        }
        if (!formData.code) {
            errorMsg.code = "This field is required.";
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            fetch(api.add_department, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false)
                    if (responseJson.status === 'success') {
                        swal(translate(props.lang, "Success"), translate(props.lang, "Department created successful"), "success");
                        navigate('/department/overview')
                    } else {
                        if (responseJson.message === 'token expired') {
                            dispatch(logout(props.history, 'login'));
                        } else if (responseJson.tokenExpired) {
                            dispatch(logout(props.history, 'login'));
                        } else {
                            if (Array.isArray(responseJson.message)) {
                                swal(translate(props.lang, "Error"), responseJson.message.join('\n'), "error");
                            } else {
                                swal(translate(props.lang, "Error"), responseJson.message, "error");
                            }
                        }
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const editData = () => {

    }

    const removeData = () => {

    }

    const addNewRow = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        // resetSettingForm();
        setModalOpen(false)
    }

    const addItem = () => {

    }

    const resetForm = () => {

    }

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <h2 className="mb-4">{translate(props.lang, "New Invoice")}</h2>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Invoice Details")}</span>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Project Name")} <span className="text-danger">*</span></span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Project")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Subject")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="name"
                                    placeholder={translate(props.lang, "Enter Subject")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.code ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Invoice Date")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="code"
                                    placeholder={translate(props.lang, "Enter Invoice Date")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.code}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.code)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Invoice Due Date")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="name"
                                    placeholder={translate(props.lang, "Enter Invoice Due Date")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Invoice Delivery Date")} <span className="text-danger">*</span></span>
                            <div className={`input-group`}>
                                <input
                                    type={'text'}
                                    name="name"
                                    placeholder={translate(props.lang, "Enter Invoice Delivery Date")}
                                    className="form-control input-transparent py-1 px-3"
                                    value={formData.name}
                                    autoComplete="off"
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                        </div>
                    </Col>

                </Row>
            </div>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block`}>{translate(props.lang, "Company Details")}</span>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Company Name")} <span className="text-danger">*</span></span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Project")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")} <span className="text-danger">*</span></span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Project")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Method")} <span className="text-danger">*</span></span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Project")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Info")} <span className="text-danger">*</span></span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Project")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block mt-2`}>{translate(props.lang, "Billing Details")}</span>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer")}</span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Customer")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Company")}</span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Company")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")}</span>
                            <div className="input-group-select px-3">
                                <Select
                                    options={
                                        projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                    }
                                    placeholder={translate(props.lang, "Select Address")}
                                    className="input-transparent w-100 text-capitalize"
                                    styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                    isSearchable={true}
                                    value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                    name="organizationId"
                                    onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='border-bottom py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <span className={`fw-semibold fs-7 text-grey-1 d-block mt-2`}>{translate(props.lang, "Invoice Items")}</span>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className='table-responsive'>
                            <Table responsive className="w-100 table-list position-relative">
                                <thead>
                                    <tr>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Item")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Quantity")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Price")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Tax")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Discount")}</th>
                                        <th className={`fw-bold fs-8`}>{translate(props.lang, "Amount")}</th>
                                        <th className={`fw-bold fs-8`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, i) => (
                                        <tr role="row" className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                                            <td className={`fw-normal fs-9 lh-base text-capitalize`}>sample</td>
                                            <td className={`fw-normal fs-9 lh-base`}>1</td>
                                            <td className={`fw-normal fs-9 lh-base`}>1</td>
                                            <td className={`fw-normal fs-9 lh-base`}>1</td>
                                            <td className={`fw-normal fs-9 lh-base`}>1</td>
                                            <td className={`fw-normal fs-9 lh-base`}>1</td>
                                            <td className={`fw-normal fs-9 lh-base`}>
                                                <div className="d-flex">
                                                    <div className="px-2" onClick={() => editData(i.toString())}>
                                                        <FontAwesomeIcon icon={faEdit} color="#194BFB" size={22} />
                                                    </div>
                                                    <div className="px-2" onClick={() => removeData(i)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} color="red" size={22} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} className="mb-3">
                        <Button variant="secondary" className='w-100 text-center h-100' onClick={() => addNewRow()}>
                            <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                            {translate(props.lang, "Add New Data")}
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className='py-3'>
                <Row className='align-items-stretch justify-content-start'>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Customer Note")}</span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.description}
                                    placeholder={translate(props.lang, "Enter Description")}
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                        <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                            <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Payment Terms and Condition")}</span>
                            <div className={`input-group`}>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    className="form-control input-transparent py-1 px-3"
                                    rows={3}
                                    value={formData.description}
                                    placeholder={translate(props.lang, "Enter Description")}
                                    onChange={e => handleAddFormChange(e)}
                                />
                            </div>
                        </div>
                        <div>
                            <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="d-flex justify-content-start gap-3">
                            <Button variant="primary" className="px-3 px-sm-10" onClick={() => submitForm()} disabled={loading}>
                                {loading ? <Spinner animation="border" variant="light" /> : translate(props.lang, "Submit")}
                            </Button>
                            <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => navigate('/department/overview')}>
                                {translate(props.lang, "Cancel")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade" show={modalOpen} centered>
                <Modal.Header className="border-0 pt-4 pb-2 px-3">
                    <div className="d-flex w-100 align-items-center">
                        <div className="w-75"></div>
                        <div className="w-25 text-end cursor-pointer" onClick={() => closeModal()} >
                            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3">
                    <div className='px-2'>
                        <Row className="justify-content-start px-0 mx-0">
                            <Col lg={12} md={12} sm={12} xs={12} className="px-0 mb-4">
                                <div className="text-center">
                                    <span className="font-weight-600 font-30 text-dark-blue">{editRow ? translate(props.lang, "Edit Invoice Item") : translate(props.lang, "Add Invoice Item")}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.organizationId ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Task")}</span>
                                    <div className="input-group-select px-3">
                                        <Select
                                            options={
                                                projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select Task")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                            isSearchable={true}
                                            value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                            name="organizationId"
                                            onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.organizationId)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.description ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Description")}</span>
                                    <div className={`input-group`}>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            className="form-control input-transparent py-1 px-3"
                                            rows={3}
                                            value={formData.description}
                                            placeholder={translate(props.lang, "Enter Description")}
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.description)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Quantity")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'number'}
                                            name="name"
                                            placeholder={translate(props.lang, "Enter Quantity")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.name}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Price")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'number'}
                                            name="price"
                                            placeholder={translate(props.lang, "Enter Price")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.name}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Tax")} <span className="text-danger">*</span></span>
                                    <div className="input-group-select px-3">
                                        <Select
                                            options={
                                                projectList.map((item, i) => ({ key: i, value: item.organization_id, label: item.name }))
                                            }
                                            placeholder={translate(props.lang, "Select Tax")}
                                            className="input-transparent w-100 text-capitalize"
                                            styles={config.theme === 'dark' ? customSelectDarkStyles: customSelectStyles}
                                            isSearchable={true}
                                            value={projectList.filter(option => option.organization_id === formData.organizationId).length > 0 ? organizationList.filter(option => option.organization_id === formData.organizationId).map((item, i) => ({ key: i, value: item.organization_id, label: item.name })) : null}
                                            name="organizationId"
                                            onChange={(e) => handleOptionSelected(e, 'organizationId')}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Discount")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'number'}
                                            name="discount"
                                            placeholder={translate(props.lang, "Enter Discount")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.name}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                <div className={`groupLabel-input py-1 ${errors.name ? 'errorBorder' : ''}`}>
                                    <span className={`fw-semibold px-3 fs-9 text-grey-1 d-block mt-2`}>{translate(props.lang, "Amount")} <span className="text-danger">*</span></span>
                                    <div className={`input-group`}>
                                        <input
                                            type={'number'}
                                            name="amount"
                                            placeholder={translate(props.lang, "Enter Amount")}
                                            className="form-control input-transparent py-1 px-3"
                                            value={formData.name}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                            onWheel={event => event.currentTarget.blur()}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger fs-9 lh-base">{translate(props.lang, errors.name)}</span>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                                <Row className="px-0 mx-0">
                                    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
                                        <Button variant="primary" className="px-3 px-sm-10" onClick={() => addItem()}>
                                            {translate(props.lang, "Update")}
                                        </Button>


                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
                                        <Button variant="phoenix-primary" className="px-3 px-sm-10" onClick={() => resetForm()}>
                                            {translate(props.lang, "Cancel")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal >
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NewInvoice);
