import App from 'App';
import PageLoader from 'page/Components/PageLoader';
import Error404 from 'pages/error/Error404';
import { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./page/Login')), 1500);
    });
});
const AuthRoutes = [
    {
        element: <App />,
        children: [
            {
                path: '/',
                element: (
                    <Suspense fallback={
                        <PageLoader />
                    }>
                        <Login />
                    </Suspense>
                )
            },
            {
                path: '*',
                element: <Error404 />
            }
        ]
    }
];

export const AuthRouter = createBrowserRouter(AuthRoutes);

export default AuthRoutes;
