import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { faKey, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import AuthCardLayout from 'layout/AuthCardLayout';
import { translate } from 'utils/translate';
import { useDispatch } from 'react-redux';
import { loadingToggleAction, loginAction } from 'store/actions/AuthActions';
import { RouterProvider, useNavigate } from 'react-router-dom';
import { router } from 'Routes';
import { checkAutoLogin } from 'services/AuthService';
import { isAuthenticated } from 'store/selectors/AuthSelectors';
import { AuthRouter } from 'AuthRoutes';
import { api } from 'utils/api';

const Index = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('pms-lang')) {
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: localStorage.getItem('pms-lang'),
            });
        } else {
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: 'EN',
            });
        }
        checkAutoLogin(dispatch, props.history, location.pathname);

    }, [dispatch, props.history, location.pathname]);

    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('pms-crm');
        let tokenDetails = '';
        tokenDetails = JSON.parse(tokenDetailsString);
        if (tokenDetailsString) {
            fetch(api.get_my_permission, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + tokenDetails.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        dispatch({
                            type: 'UPDATE_PERMISSION_ACCESS',
                            permission: responseJson.data,
                        });
                    } else {
                        console.log('error')
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }, [props.auth.auth.token]);

    useEffect(() => {
        const tokenDetailsString = localStorage.getItem('pms-crm');
        let tokenDetails = '';
        tokenDetails = JSON.parse(tokenDetailsString);
        if (tokenDetailsString) {
            fetch(api.get_my_details, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + tokenDetails.token
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.status === "success") {
                        dispatch({
                            type: 'UPDATE_PROFILE',
                            profile: responseJson.data
                        });
                    } else {
                        console.log('error', responseJson.message)
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }, [props.auth.auth.token])

    return (
        <>
            {props.isAuthenticated ? (
                <RouterProvider router={router} />
            ) : (
                <RouterProvider router={AuthRouter} />
            )}
        </>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        isAuthenticated: isAuthenticated(state),
        lang: i18n.lang,
        auth: auth,
        showLoading: auth.showLoading,
    }
}

export default connect(mapStateToProps)(Index);
